import React, { useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import Form from "components/Form";
import { GLOBAL_SEARCH } from "constants/index";
import { H3 } from "components/Text";
import { Header } from "components/DisplayBox";
import { PrimaryLink } from "components/Links";
import { FormattedMessage } from "react-intl";
import { ManageFilters } from "components/Search";
import { useAuth } from "hooks";
import GLOBAL_FORM_FILTERS, { MEMBER_SORT, GLOBAL_VALUE_TYPES } from "constants/globalFormFilters";
import { camelToSnake, getArrayFromObject } from "utils";
import { margins } from "style";
import { Row } from "components/Containers";

/**
 * FilterMenu
 *
 * @param {Object}    data
 * @param {String}    marginSize        The margin size
 * @param {String}    keyName           The key name to use for the preferences
 * @param {Function}  onSubmit          Callback function to handle form submission
 * @param {Function}  setFilterMetaData Callback function to set the filter metadata
 * @param {Object}    allowedFilters    Complete list of available filters
 * @param {Array}     defaultFilters    The default filters to use if there are no preferences
 * @param {Object}    values            The current form values
 * @param {Object}    initialValues     The initial values to use for the form
 * @param {Object}    defaultValues     The default values (if any) used for reset functionality
 * @param {Boolean}   loading           Disables the form while loading
 * @param {Boolean}   hideSearch        Hide the default search input
 * @param {Boolean}   hideSort          Hide the default sort fields
 * @param {Function}  handlePageChange  Handle the current page
 */
const FilterMenu = ({
  data,
  marginSize,
  keyName,
  onSubmit,
  allowedFilters,
  defaultFilters,
  values,
  initialValues,
  defaultValues,
  setFilterMetaData,
  loading,
  hideSearch,
  hideSort,
  handlePageChange,
  ...props
}) => {
  const expandedItemsKeyName = `${keyName}-expandedItems`;
  const defaultExpandedItems = ["keyword"];
  const [openManageWindow, toggleManageWindow] = useState(false);
  const { preferences, updatePreferences, isClient, isShowcase } = useAuth();
  const [filters, setFilters] = useState((preferences && preferences[keyName]) || defaultFilters);
  const [formItems, setFormItems] = useState([]);
  const hideManageButton = isClient || isShowcase;
  const [stateValues, setStateValues] = useState(values);
  const [initialExpandedItems, setInitialExpandedItems] = useState(
    (preferences && getArrayFromObject(preferences[expandedItemsKeyName])) || defaultExpandedItems
  );

  /**
   * @description Debounce the preferences update for performance
   */
  const handleUpdatedPreferences = useMemo(() => debounce(updatePreferences, 2000), []);

  /**
   * @description When filters change, update the preferences
   */
  const handleUpdateFilters = (data) => {
    const parentKeys = data.filters?.map((key) => key.id);

    // We need to include all the children keys of the parent filter
    const childrenKeys = Object.keys(allowedFilters)
      ?.filter(
        (key) =>
          parentKeys.indexOf(allowedFilters[key].parentKey) !== -1 && parentKeys.indexOf(allowedFilters[key].id) === -1
      )
      ?.map((key) => ({
        filterMenuLabel: allowedFilters[key].filterMenuLabel,
        id: allowedFilters[key].id,
      }));

    const joinedKeys = [...data?.filters, ...childrenKeys];

    setFilters(joinedKeys);
    updatePreferences({ keyName, keyValue: joinedKeys });
    toggleManageWindow(false);
  };

  /**
   * @description When form items are expanded/collapsed, update state
   */
  const handleUpdateExpandedItems = (data) => {
    setInitialExpandedItems(data);
    handleUpdatedPreferences({ keyName: expandedItemsKeyName, keyValue: data });
  };

  const handleChange = (payload) => {
    // We want to submit the form on any change event except for the search field
    // If the search value is the same (has not changed), submit the form
    if (payload?.search === values?.search) {
      handlePageChange(1);
      onSubmit(payload);
    }
  };

  /**
   * @description Reset the form to the default values
   */
  const handleReset = () => {
    const defaultProps = {};

    // Get defaults defined in form items
    formItems.forEach((item) => {
      if (item?.properties?.defaultValue) {
        defaultProps[item.properties.name] = item.properties.defaultValue;
      }
    });

    // Append sort values if not hidden
    if (!hideSort) {
      defaultProps.sort = MEMBER_SORT.sort.properties.defaultValue;
      defaultProps.sortDirection = MEMBER_SORT.sortDirection.properties.defaultValue;
    }

    defaultProps.search = "";

    // Append static defaults (if any)
    onSubmit({ ...defaultProps, ...defaultValues });
  };

  useEffect(() => {
    const formItems = filters ? filters.map((item) => allowedFilters[item.id]).filter((item) => item) : [];

    setFormItems(updateFormItems(data, formItems));
  }, [filters, allowedFilters, data]);

  useEffect(() => {
    setFilterMetaData(filters?.map(({ id }) => id));
  }, [filters]);

  useEffect(() => {
    setStateValues(values);
  }, [values]);

  return (
    <>
      <Header marginSize={marginSize}>
        <H3>
          <FormattedMessage id="Search.FilterMenu.Title" />
        </H3>
        <Row>
          <PrimaryLink onClick={handleReset}>
            <FormattedMessage id="Search.FilterMenu.ResetLink" />
          </PrimaryLink>
          {!hideManageButton && (
            <>
              <span>|</span>
              <PrimaryLink onClick={() => toggleManageWindow(!openManageWindow)}>
                <FormattedMessage id="Search.FilterMenu.ManageLink" />
              </PrimaryLink>
            </>
          )}
        </Row>
      </Header>
      <Form
        data={COLUMNS(formItems, hideSearch, hideSort)}
        values={stateValues}
        marginSize={marginSize}
        onSubmit={onSubmit}
        onChange={handleChange}
        onExpand={handleUpdateExpandedItems}
        initialValues={initialValues}
        disabled={loading}
        initialExpandedItems={initialExpandedItems}
        isExpandable
        hideOptionalLabel
        saveButton={{
          labelId: "Search.FilterMenu.ButtonTitle",
          show: false,
          props: {
            css: styles.button,
            disabled: loading,
          },
        }}
        {...props}
      />
      <ManageFilters
        show={openManageWindow}
        onClose={() => toggleManageWindow(false)}
        onSubmit={handleUpdateFilters}
        initialValues={{ filters }}
        allowedFilters={allowedFilters}
      />
    </>
  );
};

/**
 * This function updates the form option values based on the data returned from the query
 *
 * @param {Object}  data
 * @param {Array}   items
 * @returns Object
 */
const updateFormItems = (data, items) => {
  if (!data) return items;

  const updatedFormItems = Object.assign([], items);
  const hasParentFilter = (parentFilterId) => updatedFormItems.find(({ id }) => id === parentFilterId);

  Object.keys(data).forEach((key) => {
    updatedFormItems.forEach((item) => {
      if (item.metaDataKey === camelToSnake(key)) {
        /**
         * Remove or add the item label based on the parent filter.
         */
        if (item.parentFilterId) {
          item.label = hasParentFilter(item.parentFilterId) ? "" : GLOBAL_FORM_FILTERS()[item.id].label;
        }

        /**
         * Update the form option values based on type
         */
        if (item.valuesType === GLOBAL_VALUE_TYPES.categoricalValues) {
          item.properties.options = data[key];
        }
      }
    });
  });

  return updatedFormItems;
};

const COLUMNS = (formItems, hideSearch, hideSort) => [
  {
    style: css`
      width: 100%;
    `,
    items: [
      ...(hideSearch ? [] : [GLOBAL_SEARCH]),
      ...(hideSort ? [] : Object.keys(MEMBER_SORT).map((key) => MEMBER_SORT[key])),
      ...formItems,
    ].sort((a, b) => a.order - b.order),
  },
];

const styles = {
  button: css`
    width: 100%;
  `,
};

FilterMenu.propTypes = {
  data: PropTypes.object,
  marginSize: PropTypes.string,
  keyName: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  allowedFilters: PropTypes.object,
  defaultFilters: PropTypes.array,
  values: PropTypes.object,
  initialValues: PropTypes.object,
  defaultValues: PropTypes.object,
  setFilterMetaData: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  hideSearch: PropTypes.bool,
  hideSort: PropTypes.bool,
  handlePageChange: PropTypes.func,
};

FilterMenu.defaultProps = {
  marginSize: margins.none,
  keyName: "networkProfiles",
  allowedFilters: {},
  defaultFilters: [],
  defaultValues: {},
  loading: true,
  hideSearch: false,
  hideSort: true,
};

export default FilterMenu;
