/** @jsx jsx */
import { colors, fontSize } from "style";
import styled from "@emotion/styled";

/**
 * Icon
 */
const Icon = styled.i`
  font-size: ${fontSize.normal};
  color: ${(props) => (props.color ? props.color : colors.grayAnatomyLight1)};
  font-family: "mission-icon";
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  position: relative;
  top: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ${(props) => props.size && `font-size: ${props.size}`};

  ${(props) =>
    props.type === "more" &&
    `
    font-family: inherit;
    font-size: 1rem;
    white-space: nowrap;
    color: ${colors.purpleRainDark2};

    &:before {
      content: "• • •"
    }
  `}

  &:before {
    ${(props) => props.type === "training" && `content: "\\e959";`}
    ${(props) => props.type === "home" && `content: "\\e92d";`}
    ${(props) => props.type === "logout" && `content: "\\e998";`} 
    ${(props) => props.type === "settings" && `content: "\\e935";`}
    ${(props) => props.type === "squads" && `content: "\\e93b";`}
    ${(props) => props.type === "dollarBill" && `content: "\\e95a";`}
    ${(props) => props.type === "notes" && `content: "\\e930";`}
    ${(props) => props.type === "flag" && `content: "\\e92f";`}
    ${(props) => props.type === "network" && `content: "\\e92e";`}
    ${(props) => props.type === "organization" && `content: "\\e92d";`}
    ${(props) => props.type === "status" && `content: "\\e92c";`}
    ${(props) => props.type === "bookmarkEmpty" && `content: "\\e92b";`}
    ${(props) => props.type === "bookmarkFilled" && `content: "\\e91d";`}
    ${(props) => props.type === "dottedCircle" && `content: "\\e929";`}
    ${(props) => props.type === "checkDotted" && `content: "\\e92a";`}
    ${(props) => props.type === "clock" && `content: "\\e917";`}
    ${(props) => props.type === "chevronLeft" && `content: "\\e918";`}
    ${(props) => props.type === "coin" && `content: "\\e919";`}
    ${(props) => props.type === "engagementRing" && `content: "\\e91a";`}
    ${(props) => props.type === "hourglass" && `content: "\\e91b";`}
    ${(props) => props.type === "pin" && `content: "\\e91c";`}
    ${(props) => props.type === "dot" && `content: "\\e91e";`}
    ${(props) => props.type === "bolt" && `content: "\\e91f";`}
    ${(props) => props.type === "unavailable" && `content: "\\e920";`}
    ${(props) => props.type === "email" && `content: "\\e922";`}
    ${(props) => props.type === "emailUpdate" && `content: "\\e921";`}
    ${(props) => props.type === "arrowRight" && `content: "\\e923";`}
    ${(props) => props.type === "arrowLeft" && `content: "\\e924";`}
    ${(props) => props.type === "arrowDown" && `content: "\\e925";`}
    ${(props) => props.type === "arrowUp" && `content: "\\e926";`}
    ${(props) => props.type === "eye" && `content: "\\e927";`}
    ${(props) => props.type === "user" && `content: "\\e928";`}
    ${(props) => props.type === "checkFilled" && `content: "\\e916";`}
    ${(props) => props.type === "magnify" && `content: "\\e915";`}
    ${(props) => props.type === "file" && `content: "\\e914";`}
    ${(props) => props.type === "line" && `content: "\\e913";`}
    ${(props) => props.type === "checkmark" && `content: "\\e912";`}
    ${(props) => props.type === "chevronUp" && `content: "\\e991";`}
    ${(props) => props.type === "chevronDown" && `content: "\\e911";`}
    ${(props) => props.type === "video" && `content: "\\e900";`}
    ${(props) => props.type === "upload" && `content: "\\e901";`}
    ${(props) => props.type === "plus" && `content: "\\e902";`}
    ${(props) => props.type === "photo" && `content: "\\e903";`}
    ${(props) => props.type === "message" && `content: "\\e904";`}
    ${(props) => props.type === "listNumeric" && `content: "\\e905";`}
    ${(props) => props.type === "listBullet" && `content: "\\e906";`}
    ${(props) => props.type === "link" && `content: "\\e907";`}
    ${(props) => props.type === "listLines" && `content: "\\e96e";`}
    ${(props) => props.type === "export" && `content: "\\e908";`}
    ${(props) => props.type === "emoji" && `content: "\\e909";`}
    ${(props) => props.type === "edit" && `content: "\\e90a";`}
    ${(props) => props.type === "code" && `content: "\\e90b";`}
    ${(props) => props.type === "close" && `content: "\\e90c";`}
    ${(props) => props.type === "calendar" && `content: "\\e90d";`}
    ${(props) => props.type === "bold" && `content: "\\e90e";`}
    ${(props) => props.type === "toolSlack" && `content: "\\e90f";`}
    ${(props) => props.type === "analytics" && `content: "\\e910";`}
    ${(props) => props.type === "trash" && `content: "\\e939";`}
    ${(props) => props.type === "info" && `content: "\\e97a";`}
    ${(props) => props.type === "filters" && `content: "\\e97c";`}
    ${(props) => props.type === "analytic" && `content: "\\e97e";`}
    ${(props) => props.type === "eyes" && `content: "\\e97f";`}
    ${(props) => props.type === "warning" && `content: "!";`}
    ${(props) => props.type === "archive" && `content: "\\e980";`}
    ${(props) => props.type === "duplicate" && `content: "\\e981";`}
    ${(props) => props.type === "arrowExpand" && `content: "\\e982";`}
    ${(props) => props.type === "arrowCollapse" && `content: "\\e983";`}
    ${(props) => props.type === "lightBulb" && `content: "\\e984";`}
    ${(props) => props.type === "performance" && `content: "\\e985";`}
    ${(props) => props.type === "mentorship" && `content: "\\e986";`}
    ${(props) => props.type === "plan" && `content: "\\e987";`}
    ${(props) => props.type === "mic" && `content: "\\e988";`}
    ${(props) => props.type === "townHall" && `content: "\\e989";`}
    ${(props) => props.type === "gridCard" && `content: "\\e97b";`}
    ${(props) => props.type === "businessCard" && `content: "\\e99d";`}
    ${(props) => props.type === "copy" && `content: "\\e98b";`}
    ${(props) => props.type === "rotate" && `content: "\\e98c";`}
    ${(props) => props.type === "share" && `content: "\\e97d";`}
    ${(props) => props.type === "dots" && `content: "\\e98a";`}
    ${(props) => props.type === "vue" && `content: "\\e95b";`}
    ${(props) => props.type === "typeScript" && `content: "\\e95c";`}
    ${(props) => props.type === "swift" && `content: "\\e95d";`}
    ${(props) => props.type === "selenium" && `content: "\\e95e";`}
    ${(props) => props.type === "rubyOnRails" && `content: "\\e95f";`}
    ${(props) => props.type === "react" && `content: "\\e960";`}
    ${(props) => props.type === "python" && `content: "\\e961";`}
    ${(props) => props.type === "postman" && `content: "\\e962";`}
    ${(props) => props.type === "pearl" && `content: "\\e963";`}
    ${(props) => props.type === "php" && `content: "\\e964";`}
    ${(props) => props.type === "node" && `content: "\\e965";`}
    ${(props) => props.type === "nest" && `content: "\\e966";`}
    ${(props) => props.type === "mySql" && `content: "\\e967";`}
    ${(props) => props.type === "linux" && `content: "\\e968";`}
    ${(props) => props.type === "kubernetes" && `content: "\\e969";`}
    ${(props) => props.type === "jenkins" && `content: "\\e96a";`}
    ${(props) => props.type === "javaScript" && `content: "\\e96b";`}
    ${(props) => props.type === "heroku" && `content: "\\e96c";`}
    ${(props) => props.type === "graphQl" && `content: "\\e96d";`}
    ${(props) => props.type === "flux" && `content: "\\e96f";`}
    ${(props) => props.type === "cypress" && `content: "\\e970";`}
    ${(props) => props.type === "css" && `content: "\\e971";`}
    ${(props) => props.type === "cSharp" && `content: "\\e972";`}
    ${(props) => props.type === "bootstrap" && `content: "\\e973";`}
    ${(props) => props.type === "azure" && `content: "\\e974";`}
    ${(props) => props.type === "apple" && `content: "\\e975";`}
    ${(props) => props.type === "angular" && `content: "\\e976";`}
    ${(props) => props.type === "android" && `content: "\\e977";`}
    ${(props) => props.type === "ajax" && `content: "\\e978";`}
    ${(props) => props.type === "aws" && `content: "\\e979";`}
    ${(props) => props.type === "question" && `content: "\\e969";`}
    ${(props) => props.type === "send" && `content: "\\e98c";`}
    ${(props) => props.type === "java" && `content: "\\e98d";`}
    ${(props) => props.type === "go" && `content: "\\e98e";`}
    ${(props) => props.type === "flutter" && `content: "\\e98f";`}
    ${(props) => props.type === "dotnet" && `content: "\\e990";`}
    ${(props) => props.type === "expanded" && `content: "\\e911";`}
    ${(props) => props.type === "timerStop" && `content: "\\e992";`}
    ${(props) => props.type === "timerStart" && `content: "\\e993";`}
    ${(props) => props.type === "end" && `content: "\\e994";`}
    ${(props) => props.type === "stop" && `content: "\\e995";`}
    ${(props) => props.type === "handShake" && `content: "\\e996";`}
    ${(props) => props.type === "eyeBlocked" && `content: "\\e9d1";`}
    ${(props) => props.type === "dollar" && `content: "$";`} 
    ${(props) => props.type === "percent" && `content: "%";`}
    ${(props) => props.type === "USD" && `content: "USD";`}
    ${(props) => props.type === "CAD" && `content: "CAD";`}
    ${(props) => props.type === "EUR" && `content: "EUR";`}
    ${(props) => props.type === "users" && `content: "\\e997";`}
    ${(props) => props.type === "flame" && `content: "\\1F525";`}
    ${(props) => props.type === "thumbsUp" && `content: "\\1F44D";`}
    ${(props) => props.type === "stairs" && `content: "\\e99c";`}
    ${(props) => props.type === "thumbsDown" && `content: "\\1F44E";`}
    ${(props) => props.type === "exclamation" && `content: "\\2757";`}
    ${(props) => props.type === "prohibited" && `content: "\\1F6AB";`}
    ${(props) => props.type === "attention" && `content: "\\26A0";`}
    ${(props) => props.type === "trendDown" && `content: "\\21A7";`}
    ${(props) => props.type === "star" && `content: "\\e99b";`}
    ${(props) => props.type === "heart" && `content: "\\e99a";`}
    ${(props) => props.type === "gear" && `content: "\\e9a1";`}
    ${(props) => props.type === "equalizer" && `content: "\\e99f";`}
    ${(props) => props.type === "board" && `content: "\\e9a0";`}
    ${(props) => props.type === "video_list" && `content: "\\e9a3";`}
    ${(props) => props.type === "video_list_outlined" && `content: "\\e9a2";`}
    ${(props) => props.type === "artificialIntelligence" && `content: "\\e9a4";`}
    ${(props) => props.type === "dataScience" && `content: "\\e9a5";`}
    ${(props) => props.type === "refresh" && `content: "\\ea2e";`}
    ${(props) => props.type === "list-numbered" && `content: "\\e9b9";`}
    ${(props) => props.type === "list2" && `content: "\\e9b9";`}
    ${(props) => props.type === "underline" && `content: "\\ea63";`}
    ${(props) => props.type === "italic" && `content: "\\ea64";`}
    ${(props) => props.type === "strikethrough" && `content: "\\ea65";`}
    ${(props) => props.type === "paragraph-left" && `content: "\\ea77";`}
    ${(props) => props.type === "paragraph-center" && `content: "\\ea78";`}
    ${(props) => props.type === "paragraph-right" && `content: "\\ea79";`}
  }
`;

const ICON_SIZE = {
  xsmall: "1rem",
  small: "1.2rem",
  normal: "1.4rem",
  medium: "1.6rem",
  large: "2rem",
  xlarge: "2.4rem",
  xxlarge: "3rem",
  xxxlarge: "4rem",
  xxxxlarge: "8rem",
};

const ICON_POSITION = {
  overlay: "overlay",
  iconRight: "icon-right",
};

const ICON_TYPE = {
  home: "home",
  logout: "logout",
  settings: "settings",
  squads: "squads",
  more: "more",
  trash: "trash",
  training: "training",
  dollarBill: "dollarBill",
  notes: "notes",
  flag: "flag",
  network: "network",
  organization: "organization",
  status: "status",
  bookmarkEmpty: "bookmarkEmpty",
  bookmarkFilled: "bookmarkFilled",
  dottedCircle: "dottedCircle",
  checkDotted: "checkDotted",
  clock: "clock",
  chevronLeft: "chevronLeft",
  coin: "coin",
  engagementRing: "engagementRing",
  hourglass: "hourglass",
  pin: "pin",
  dot: "dot",
  bolt: "bolt",
  unavailable: "unavailable",
  email: "email",
  emailUpdate: "emailUpdate",
  arrowRight: "arrowRight",
  arrowLeft: "arrowLeft",
  arrowDown: "arrowDown",
  arrowUp: "arrowUp",
  eye: "eye",
  user: "user",
  checkFilled: "checkFilled",
  search: "magnify", // Can't be "search" because HTML reserved type
  file: "file",
  line: "line",
  checkmark: "checkmark",
  chevronDown: "chevronDown",
  chevronUp: "chevronUp",
  video: "video",
  upload: "upload",
  plus: "plus",
  photo: "photo",
  message: "message",
  listNumeric: "listNumeric",
  listBullet: "listBullet",
  listLines: "listLines",
  link: "link",
  export: "export",
  emoji: "emoji",
  edit: "edit",
  code: "code",
  close: "close",
  calendar: "calendar",
  bold: "bold",
  toolSlack: "toolSlack",
  analytics: "analytics",
  mentorship: "mentorship",
  performance: "performance",
  lightBulb: "lightBulb",
  warning: "warning",
  info: "info",
  filters: "filters",
  analytic: "analytic",
  gridCard: "gridCard",
  copy: "copy",
  rotate: "rotate",
  share: "share",
  eyes: "eyes",
  archive: "archive",
  duplicate: "duplicate",
  arrowExpand: "arrowExpand",
  arrowCollapse: "arrowCollapse",
  plan: "plan",
  mic: "mic",
  townHall: "townHall",
  dots: "dots",
  vue: "vue",
  typescript: "typeScript",
  swift: "swift",
  selenium: "selenium",
  rubyonrails: "rubyOnRails",
  react: "react",
  python: "python",
  postman: "postman",
  pearl: "pearl",
  php: "php",
  node: "node",
  nest: "nest",
  mysql: "mySql",
  linux: "linux",
  kubernetes: "kubernetes",
  jenkins: "jenkins",
  javascript: "javaScript",
  heroku: "heroku",
  graphql: "graphQl",
  flux: "flux",
  cypress: "cypress",
  css: "css",
  csharp: "cSharp",
  bootstrap: "bootstrap",
  azure: "azure",
  apple: "apple",
  angular: "angular",
  android: "android",
  ajax: "ajax",
  aws: "aws",
  question: "question",
  send: "send",
  qa: "code",
  data: "code",
  ios: "apple",
  java: "java",
  flutter: "flutter",
  go: "go",
  dotnet: "dotnet",
  sql: "code",
  stairs: "stairs",
  expanded: "expanded",
  timerStop: "timerStop",
  timerStart: "timerStart",
  end: "end",
  stop: "stop",
  handShake: "handShake",
  eyeBlocked: "eyeBlocked",
  dollar: "dollar",
  percent: "percent",
  users: "users",
  attention: "attention",
  trendDown: "trendDown",
  flame: "flame",
  thumbsUp: "thumbsUp",
  thumbsDown: "thumbsDown",
  exclamation: "exclamation",
  prohibited: "prohibited",
  star: "star",
  heart: "heart",
  businessCard: "businessCard",
  gear: "gear",
  equalizer: "equalizer",
  board: "board",
  videoList: "video_list",
  videoListOutlined: "video_list_outlined",
  artificialintelligence: "artificialIntelligence",
  datascience: "dataScience",
  refresh: "refresh",
  listNumbered: "list-numbered",
  list2: "list2",
  underline: "underline",
  italic: "italic",
  strikethrough: "strikethrough",
  paragraphLeft: "paragraph-left",
  paragraphCenter: "paragraph-center",
  paragraphRight: "paragraph-right",
};

export { ICON_TYPE, ICON_SIZE, ICON_POSITION };

export default Icon;
