import { GET_TEAM_INSIGHTS } from "graphql/queries";
import { useEffect, useState } from "react";
import { useQueryData } from "hooks";
import { SQUAD_INSIGHTS_PER_PAGE } from "constants/index";
import { PAGINATION_TYPE } from "components/Pagination";

export default function useSquadInsights({
  teamId,
  resultsPerPage = SQUAD_INSIGHTS_PER_PAGE,
  readUrlPagination = false,
  paginationType = PAGINATION_TYPE.classic,
  types,
  metrics,
  profileIds,
}) {
  const [data, setData] = useState([]);

  const {
    hasNextPage,
    handleNextPage,
    hasPreviousPage,
    handlePreviousPage,
    handlePageChange,
    loading,
    loadingMore,
    data: response,
    error,
  } = useQueryData({
    queryName: GET_TEAM_INSIGHTS,
    keyName: "teamInsights",
    resultsPerPage,
    skip: !teamId,
    variables: {
      teamId,
      filters: {
        ...(types?.length > 0 && { types: types.map((type) => type.value) }),
        ...(metrics?.length > 0 && { metrics: metrics.map((metric) => metric.value) }),
        ...(profileIds?.length > 0 && { profileIds: profileIds.map((profile) => profile.id) }),
      },
    },
    paginationType,
    readUrlPagination,
  });

  useEffect(() => {
    if (response?.teamInsights) {
      setData(response?.teamInsights);
    }
  }, [response]);

  return {
    loading,
    loadingMore,
    data: data?.nodes,
    error,
    hasNextPage,
    hasPreviousPage,
    handleNextPage,
    handlePreviousPage,
    handlePageChange,
    pageInfo: data?.pageInfo,
    totalCount: data?.totalCount,
    currentPage: data?.currentPage,
    resultsPerPage: data?.perPage,
  };
}
