import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { Row } from "components/Containers";
import { H1 } from "components/Text";
import { breakpoints, colors, fontSize, margins } from "style";
import { TextLoader } from "components/Loader";
import { PAGE_WRAPPER_MAX_WIDTH, PAGE_WRAPPER_SIZE } from "components/Containers/PageWrapper";

/**
 * Formats a page title
 *
 * @param {Element}  title
 * @param {Element}  subtitle
 * @param {Element}  actionBar
 * @param {Element}  backLink
 * @param {Element}  loading
 * @param {Element}  marginSize
 * @param {Element}  asHeader
 * @param {Element}  layout
 * @param {Element}  children
 */
const PageTitle = ({
  title,
  subtitle,
  actionBar,
  backLink,
  loading,
  marginSize,
  asHeader,
  layout,
  children,
  ...props
}) => {
  const Header = () => (
    <div css={styles.container(marginSize)} {...props}>
      <Row css={styles.inner_container}>
        <div css={styles.title_container}>
          <div css={styles.back_link_container}>{backLink}</div>
          {loading ? <TextLoader css={styles.loader} /> : title ? <H1>{title}</H1> : null}
          {subtitle && (
            <div css={styles.subtitle}>
              {!loading ? subtitle : <TextLoader css={[styles.loader, styles.loader_subtitle]} />}
            </div>
          )}
        </div>
        <div>{layout === LAYOUT.horizontal && <div css={styles.action_container}>{actionBar}</div>}</div>
      </Row>
      {layout === LAYOUT.vertical && (
        <div css={[styles.action_container, styles.action_container_stacked]}>{actionBar}</div>
      )}
    </div>
  );

  if (asHeader) {
    return (
      <HeaderContainer>
        <div css={styles.inner_wrapper}>
          {children}
          <Header />
        </div>
      </HeaderContainer>
    );
  } else {
    return (
      <>
        {children}
        <Header />
      </>
    );
  }
};

/**
 * HeaderContainer
 *
 * @param {Element}   children
 */
const HeaderContainer = ({ children, ...props }) => (
  <div css={styles.header_container} {...props}>
    {children}
  </div>
);

const styles = {
  container: (marginSize) => css`
    padding: 4rem ${marginSize} 0rem ${marginSize};
    flex: 1;

    @media (max-width: ${breakpoints.portrait}) {
      padding: 2rem 2.5rem 0rem 2.5rem;
    }

    @media (max-width: ${breakpoints.mobile}) {
      padding: 1.5rem 2rem 0rem 2rem !important;
    }
  `,
  subtitle: css`
    color: ${colors.purpleRainDark2};
    font-size: ${fontSize.medium};
    font-weight: 600;

    @media (max-width: ${breakpoints.tablet}) {
      font-size: ${fontSize.small};
    }
  `,
  inner_wrapper: css`
    max-width: ${PAGE_WRAPPER_MAX_WIDTH};
    margin: 0 auto;
    padding: 0 ${PAGE_WRAPPER_SIZE} 2rem ${PAGE_WRAPPER_SIZE};

    @media (max-width: ${breakpoints.mobile}) {
      padding: 0.5rem 2rem 0.5rem 2rem;
    }
  `,
  header_container: css`
    background: #fff;
    border-bottom: 1px ${colors.grayAnatomyLight4} solid;
  `,
  inner_container: css`
    align-items: flex-end;
    justify-content: space-between;
    min-height: 4rem;

    @media (max-width: ${breakpoints.mobile}) {
      align-items: center;
    }
  `,
  title_container: css`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    position: relative;
    flex: 1;
  `,
  action_container: css`
    display: flex;
    position: relative;
    gap: 1.6rem;
    align-items: center;
  `,
  action_container_stacked: css`
    margin-top: 2rem;
  `,
  loader: css`
    width: 35rem;
    height: 3.5rem;
    background: ${colors.grayAnatomyLight4};

    @media (max-width: ${breakpoints.tablet}) {
      height: 2.5rem;
    }
  `,
  loader_subtitle: css`
    width: 25rem;
    height: 1.6rem;

    @media (max-width: ${breakpoints.tablet}) {
      height: 1.4rem;
    }
  `,
  back_link_container: css`
    position: absolute;
    top: -2.25rem;

    @media (max-width: ${breakpoints.mobile}) {
      top: -1.5rem;
    }
  `,
};

const LAYOUT = {
  vertical: "vertical",
  horizontal: "horizontal",
};

HeaderContainer.propTypes = {
  children: PropTypes.any,
};

PageTitle.defaultProps = {
  marginSize: margins.normal,
  asHeader: false,
  layout: LAYOUT.horizontal,
};

PageTitle.propTypes = {
  title: PropTypes.any,
  actionBar: PropTypes.any,
  subtitle: PropTypes.any,
  backLink: PropTypes.any,
  loading: PropTypes.bool,
  marginSize: PropTypes.string,
  asHeader: PropTypes.bool,
  layout: PropTypes.string,
  children: PropTypes.any,
};

export { LAYOUT };

export default PageTitle;
