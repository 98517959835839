import { gql } from "@apollo/client";

const metadataFields = `
  value
  count
  formattedValue
`;
const numericMetadataFields = `
  min
  max
`;
const RESULT_FILTER_METADATA = gql`
  fragment ResultFilterMetadata on ResultFilterMetadata {
    skills {
      ${metadataFields}
    }
    disciplines {
      ${metadataFields}
    }
    interests {
      ${metadataFields}
    }
    role {
      ${metadataFields}
    }
    activeTeams {
      ${metadataFields}
    }
    country {
      ${metadataFields}
    }
    partner {
      ${metadataFields}
    }
    hasExperienceData {
      ${metadataFields}
    }
    daysSinceAcceptingPrivacyPolicy {
      ${numericMetadataFields}
    }
    lifetimeHoursLoggedReview {
      ${numericMetadataFields}
    }
    continent {
      ${metadataFields}
    }
    recentSkills {
      ${metadataFields}
    }
    isAvailablePartTime {
      ${metadataFields}
    }
    termsOfServiceDate {
      ${numericMetadataFields}
    }
    hourlyRateUsd {
      ${numericMetadataFields}
    }
    worldRegion {
      ${metadataFields}
    }
    wantsPartTimeWork {
      ${metadataFields}
    }
    experienceYears {
      ${numericMetadataFields}
    }
    lifetimeTeams {
      ${metadataFields}
    }
    activeMissionCount {
      ${numericMetadataFields}
    }
    hasEducationData {
      ${metadataFields}
    }
    lifetimeEffortPercentPlanning {
      ${numericMetadataFields}
    }
    hasTakenSkillAssessment {
      ${metadataFields}
    }
    hasBeenMember {
      ${metadataFields}
    }
    hasVideoInterviews {
      ${metadataFields}
    }
    lifetimeEffortPercentCommunication {
      ${numericMetadataFields}
    }
    lastOnboardAt {
      ${numericMetadataFields}
    }
    engineeringLevels {
      ${metadataFields}
    }
    isAvailableFullTime {
      ${metadataFields}
    }
    lifetimeEffortPercentProduction {
      ${numericMetadataFields}
    }
    daysAsMember {
      ${numericMetadataFields}
    }
    languages {
      ${metadataFields}
    }
    hasAppliedOnMissionBoard {
      ${metadataFields}
    }
    lists {
      ${metadataFields}
    }
    daysAsCandidate {
      ${numericMetadataFields}
    }
    hasBeenCandidate {
      ${metadataFields}
    }
    lifetimeHourlyRateAverageUsd {
      ${numericMetadataFields}
    }
    privacyPolicyDate {
      ${numericMetadataFields}
    }
    daysAsRejectedCandidate {
      ${numericMetadataFields}
    }
    archivedDate {
      ${numericMetadataFields}
    }
    candidateDate {
      ${numericMetadataFields}
    }
    missionBoardInvitationInviters {
      ${metadataFields}
    }
    memberDate {
      ${numericMetadataFields}
    }
    acquisitionChannel {
      ${metadataFields}
    }
    daysSinceLastRequest {
      ${numericMetadataFields}
    }
    skillAssessmentsTaken {
      ${metadataFields}
    }
    hasAcceptedPrivacyPolicy {
      ${metadataFields}
    }
    timeZone {
      ${metadataFields}
    }
    lifetimeEffortPercentReview {
      ${numericMetadataFields}
    }
    lifetimeMissionCount {
      ${numericMetadataFields}
    }
    lifetimeHoursLogged {
      ${numericMetadataFields}
    }
    hasBeenInvitedOnMissionBoard {
      ${metadataFields}
    }
    partnerType {
      ${metadataFields}
    }
    isAssigned {
      ${metadataFields}
    }
    lifetimeHoursLoggedPlanning {
      ${numericMetadataFields}
    }
    createdBy {
      ${metadataFields}
    }
    missionBoardInvitationCount {
      ${numericMetadataFields}
    }
    hasPassedSkillAssessment {
      ${metadataFields}
    }
    lastOffboardAt {
      ${numericMetadataFields}
    }
    experienceJobCount {
      ${numericMetadataFields}
    }
    daysSinceAcceptingTermsOfService {
      ${numericMetadataFields}
    }
    missionBoardApplicationCustomers {
      ${metadataFields}
    }
    hasExternalLinkData {
      ${metadataFields}
    }
    externalLinkCount {
      ${numericMetadataFields}
    }
    lifetimeHoursLoggedProduction {
      ${numericMetadataFields}
    }
    wantsFullTimeWork {
      ${metadataFields}
    }
    activeHourlyRateAverageUsd {
      ${numericMetadataFields}
    }
    skillAssessmentsTakenCount {
      ${numericMetadataFields}
    }
    isBooked {
      ${metadataFields}
    }
    wasCreatedByStaff {
      ${metadataFields}
    }
    rejectedCandidateDate {
      ${numericMetadataFields}
    }
    hasBeenAssigned {
      ${metadataFields}
    }
    isAvailable {
      ${metadataFields}
    }
    archiveReason {
      ${metadataFields}
    }
    kickedOutMemberDate {
      ${numericMetadataFields}
    }
    isFromPartner {
      ${metadataFields}
    }
    hasTakenDaysOff {
      ${metadataFields}
    }
    daysOffCount {
      ${numericMetadataFields}
    }
    daysAsKickedOutMember {
      ${numericMetadataFields}
    }
    acquisitionSource {
      ${metadataFields}
    }
    missionBoardInvitationCustomers {
      ${metadataFields}
    }
    skillAssessmentsPassed {
      ${metadataFields}
    }
    activeCustomers {
      ${metadataFields}
    }
    archetype {
      ${metadataFields}
    }
    hasProfilePicture {
      ${metadataFields}
    }
    isProfileComplete {
      ${metadataFields}
    }
    externalLinkTypes {
      ${metadataFields}
    }
    skillAssessmentsPassedCount {
      ${numericMetadataFields}
    }
    hasBeenArchived {
      ${metadataFields}
    }
    lifetimeCustomers {
      ${metadataFields}
    }
    availableHours {
      ${numericMetadataFields}
    }
    missionBoardApplicationCount {
      ${numericMetadataFields}
    }
    lifetimeHoursLoggedCommunication {
      ${numericMetadataFields}
    }
    daysAsArchived {
      ${numericMetadataFields}
    }
    hasAcceptedTermsOfService {
      ${metadataFields}
    }
    hasResume {
      ${metadataFields}
    }
    hasNeverSignedIn {
      ${metadataFields}
    }
    industries {
      ${metadataFields}
    }
    status {
      ${metadataFields}
    }
    isAvailable {
      ${metadataFields}
    }
    isCandidateReadyForReview {
      ${metadataFields}
    }
  }
`;

export { RESULT_FILTER_METADATA };
