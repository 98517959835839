import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { Grid, PageWrapper } from "components/Containers";
import PageTitle from "components/PageTitle";
import { ProfileAddButton, ProfileViewButton } from "components/Profile";
import { SearchWithFilters } from "components/Search";
import { NetworkList } from "components/Network";
import { useNetworkSearch } from "hooks";
import { ListsAddForm } from "components/Lists";
import { MissionInviteForm } from "components/Missions";

/**
 * Network
 */
const Network = () => {
  const intl = useIntl();
  const resultsPerPage = 50;
  const {
    advancedOptions,
    selectedProfile,
    showAddToListsForm,
    showInviteMissionForm,
    setShowAddToListsForm,
    setShowInviteMissionForm,
    staticFilters,
  } = useNetworkSearch({});

  return (
    <PageWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Network.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle title={<FormattedMessage id="Network.H1" />} actionBar={<ProfileAddButton isModal />} />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <SearchWithFilters
            staticFilters={staticFilters()}
            search={false}
            hideSearch={false}
            hideSort={false}
            isCardView={false}
            renderer={NetworkList}
            rendererProps={{ rowOptions: advancedOptions }}
            resultsPerPage={resultsPerPage}
            additionalQueryVariables={{
              includePartner: true,
              includeWorkExperiences: false,
              includeConfirmation: true,
            }}
            profileModalActions={[ProfileViewButton]}
          />
        </Grid.col>
      </Grid>
      {showAddToListsForm && (
        <ListsAddForm
          show={showAddToListsForm}
          profileId={selectedProfile?.id}
          onClose={() => setShowAddToListsForm(false)}
        />
      )}
      {showInviteMissionForm && (
        <MissionInviteForm
          show={showInviteMissionForm}
          profile={selectedProfile}
          onClose={() => setShowInviteMissionForm(false)}
        />
      )}
    </PageWrapper>
  );
};

export default Network;
