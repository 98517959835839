import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { formatCurrency } from "utils";
import { colors, fontSize } from "style";
import { Banner } from "components/Banner";
import { BANNER_TYPE } from "constants";

/**
 * RoleEarningPotential
 *
 * @param {Number}    applicantsCount
 * @param {Number}    averageExperience
 * @param {String}    currency
 * @param {Number}    rate
 * @param {Number}    targetHours
 */
const RoleEarningPotential = ({ applicantsCount, averageExperience, currency, rate, targetHours }) => (
  <Banner isClosable={false} type={BANNER_TYPE.warning}>
    <span css={styles.text}>
      <FormattedMessage
        id="Missions.MissionRole.ApplyForm.Disclaimer"
        values={{
          applicantsCount: (
            <b>
              <FormattedMessage
                id="Missions.MissionRole.ApplyForm.Disclaimer.ApplicantsCount"
                values={{ applicantsCount }}
              />
            </b>
          ),
          experience: (
            <b>
              <FormattedMessage
                id="Missions.MissionRole.ApplyForm.Disclaimer.ExperienceYears"
                values={{ averageExperience }}
              />
            </b>
          ),
          title: (
            <b css={styles.recommendationTitle}>
              <FormattedMessage id="Missions.MissionRole.ApplyForm.Disclaimer.Title" />
            </b>
          ),
          rate: (
            <FormattedMessage
              id="Missions.MissionRole.ApplyForm.Disclaimer.HourlyRate"
              values={{ rate: formatCurrency(Math.round(rate), currency, "code", false, 0) }}
            />
          ),
          // Multiplying by 52 to convert the weekly amount to yearly (1 year ~ 52 weeks)
          estimatedEarnings: formatCurrency(Math.round(rate * targetHours * 52), currency, "code", false, 0),
        }}
      />
    </span>
    <p css={styles.recommendationDescription}>
      <FormattedMessage id="Missions.MissionRole.ApplyForm.Disclaimer.Description" />
    </p>
  </Banner>
);

const styles = {
  text: css`
    color: ${colors.green};
  `,
  recommendationTitle: css`
    color: ${colors.black};
  `,
  recommendationDescription: css`
    font-size: ${fontSize.xsmall};
  `,
};

RoleEarningPotential.propTypes = {
  applicantsCount: PropTypes.number,
  averageExperience: PropTypes.number,
  currency: PropTypes.string,
  rate: PropTypes.number,
  targetHours: PropTypes.number,
};

export default RoleEarningPotential;
