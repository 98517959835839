import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { colors } from "style";

/**
 * SquadIcon
 *
 * @param {String}    name
 * @param {String}    organizationName
 */
const SquadIcon = ({ name, organizationName, ...props }) => (
  <Icon {...props} color={getColor(organizationName)}>
    {name
      .replace(/[^\p{L}\s]/gu, "") // Remove special characters
      .split(" ")
      .slice(0, 2)
      .map((item) => item[0])
      .join("")
      .toUpperCase()}
  </Icon>
);

const Icon = styled.div`
  width: 3rem;
  height: 3rem;
  background: ${({ color }) => color};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
`;

function getColor(name) {
  const firstChar = name?.charAt(0);

  if (!firstChar) {
    return colors.purpleRainBase;
  }

  if (firstChar.match(/[a-d]/i)) {
    return colors.pinkPantherBase;
  } else if (firstChar.match(/[e-h]/i)) {
    return colors.orangeCountyBase;
  } else if (firstChar.match(/[i-l]/i)) {
    return colors.blueVelvetBase;
  } else if (firstChar.match(/[m-p]/i)) {
    return colors.green;
  } else {
    return colors.purpleRainBase;
  }
}

SquadIcon.propTypes = {
  name: PropTypes.string,
  organizationName: PropTypes.string,
};

export default SquadIcon;
