import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAuth } from "hooks";
import { LINKS } from "constants";
import Overlay from "components/Overlay";
import { UserFullName } from "components/User";
import { PrimaryButton } from "components/Buttons";
import { ProfileCard, ProfileModal } from "components/Profile";

/**
 * ProfileLink
 *
 * @param {Object}  profile
 * @param {Boolean} withOverlay
 */
const ProfileLink = ({ profile, withOverlay, ...props }) => {
  const { isAdmin } = useAuth();
  const [showProfileModal, setShowProfileModal] = useState(false);
  const handleShowProfile = () => {
    if (!isAdmin) {
      window.open(LINKS.public_profile(profile?.uuid), "_blank");
      return;
    }

    setShowProfileModal(true);
  };
  const Link = () => <UserFullName data={profile} {...props} />;

  if (!withOverlay) {
    return <Link />;
  }

  return (
    <>
      <Overlay
        asCardOverlay
        placement="top"
        width={`30rem`}
        overlay={
          <ProfileCard
            data={{ id: profile?.slug || profile?.id }}
            onClick={handleShowProfile}
            primaryAction={<PrimaryButton isExpand>View Profile</PrimaryButton>}
            shouldRefetch
          />
        }
        trigger={<Link />}
        delay={{ show: 1000 }}
        {...props}
      />
      <ProfileModal
        id={profile?.slug}
        show={showProfileModal}
        handleClose={() => setShowProfileModal(false)}
        handlePrimary={() => {
          window.open(isAdmin ? LINKS.private_profile(profile?.slug) : LINKS.public_profile(profile?.uuid), "_blank");
          setShowProfileModal(false);
        }}
      />
    </>
  );
};

ProfileLink.propTypes = {
  profile: PropTypes.object.isRequired,
  withOverlay: PropTypes.bool,
};

export default ProfileLink;
