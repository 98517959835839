/** @jsx jsx */
import styled from "@emotion/styled";
import { colors, fontSize } from "style";

/**
 * Label
 */
const Label = styled.label`
  font-size: ${fontSize.small};
  font-weight: 500;
  line-height: 1.33;
  margin-bottom: 0.5rem;
  order: 0;
  display: flex;
  justify-content: space-between;
  transition: color 0.3s ease;
  align-items: center;

  &:first-letter {
    text-transform: uppercase;
  }

  ${(props) =>
    props.hasError &&
    `
    color: ${colors.red};
  `}
`;

export default Label;
