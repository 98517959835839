/**
 * Returns an array from provided object
 *
 * @param {Object} obj
 *
 * @returns {Array}
 */
export default function getArrayFromObject(obj) {
  if (!obj) return [];
  if (Array.isArray(obj)) return obj;

  return Object.keys(obj);
}
