import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { formatHours, getMinsFromClock, getSecondsFromClock, restrictTimeSlipInput } from "utils";
import { TextInput } from "components/Form";

/**
 * TimeSlipInput
 *
 * @param {Function}    onUpdate
 * @param {Object}      payload
 */
const TimeSlipInput = ({ onUpdate, payload, ...props }) => {
  const [value, setValue] = useState();
  const [focus, setFocus] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    if (typeof payload?.timeLogged !== "undefined") {
      return setValue(formatHours(parseInt(payload?.timeLogged) / 60)); // Divide by 60 cause we're retrieving value in seconds
    }
  }, [payload]);

  useEffect(() => {
    window.onbeforeunload = focus ? confirmExit : null;

    function confirmExit() {
      return "";
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [focus]);

  const handleUpdate = (eventValue) => {
    const isEmpty = eventValue === "";
    const valueInSeconds = getSecondsFromClock(eventValue);
    const formattedValue = formatHours(getMinsFromClock(eventValue));

    setFocus(false);

    // Format only if a value was inputted
    if (!isEmpty) {
      setValue(formattedValue);
    }

    if (payload?.timeLogged !== valueInSeconds) {
      if (!payload?.timeLogged && isEmpty) return;

      onUpdate(formattedValue, payload);
    }
  };

  return (
    <StyledInput
      value={value}
      placeholder="--:--"
      maxLength="5"
      onFocus={() => setFocus(true)}
      onChange={(name, val) => setValue(restrictTimeSlipInput(val))}
      onBlur={(e) => handleUpdate(e.target.value || 0)}
      onKeyUp={(e) => {
        if (e.key === "Enter") {
          inputRef?.current?.handleBlur();
        }
      }}
      isInvisible
      {...props}
      ref={inputRef}
    />
  );
};

const StyledInput = styled(TextInput)`
  padding: 0 0.5rem;
  height: 3.25rem;
  line-height: normal;
  width: 6rem;
  background: #fff;
`;

TimeSlipInput.propTypes = {
  payload: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default TimeSlipInput;
