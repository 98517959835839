import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { GET_MISSION_ROLES } from "graphql/queries";
import { REMOVE_MISSION_ROLES } from "graphql/mutations";
import { PAGINATION_TYPE } from "components/Pagination";
import { useQueryData, useToast } from "hooks";
import { ICON_TYPE } from "components/Icon";

/**
 * useMissionRoles
 *
 * @param {String}  missionId
 * @param {Number}  resultsPerPage
 */
export default function useMissionRoles({ missionId, filters = {}, resultsPerPage, skipQuery = false }) {
  const { toast } = useToast();
  const variables = missionId ? { id: missionId } : {};
  const [showForm, setShowForm] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [selectedRole, setSelectedRole] = useState({});

  const { loading, loadingMore, data, error, hasNextPage, handleNextPage } = useQueryData({
    queryName: GET_MISSION_ROLES,
    keyName: "missionRoles",
    resultsPerPage,
    paginationType: PAGINATION_TYPE.append,
    skip: skipQuery,
    variables: {
      ...variables,
      ...{
        filters: filters,
      },
    },
  });

  const [removeMissionRoles, { loading: deleting }] = useMutation(REMOVE_MISSION_ROLES, {
    refetchQueries: [GET_MISSION_ROLES],
  });

  const handleRemoveRoles = (ids) => {
    removeMissionRoles({
      variables: {
        input: { ids },
      },
      onCompleted: () =>
        toast.success(
          <FormattedMessage
            id={"useMissionRoles.RemoveSuccess"}
            values={{
              roleCount: ids.length,
            }}
          />
        ),
      onError: () =>
        toast.error(
          <FormattedMessage
            id={"useMissionRoles.RemoveError"}
            values={{
              roleCount: ids.length,
            }}
          />
        ),
    });
  };

  const getAdvancedOptions = (data, selection) => {
    const editOption = {
      label: <FormattedMessage id={`Missions.Roles.RoleAdvancedOptions.Edit`} />,
      icon: ICON_TYPE.edit,
      onClick: () => {
        setIsCopying(false);
        setSelectedRole(data);
        setShowForm(true);
      },
    };

    const removeOption = {
      label: (
        <FormattedMessage
          id={`Missions.Roles.RoleAdvancedOptions.${data ? `Remove` : `BatchRemove`}`}
          values={{
            roleCount: selection?.length || 0,
          }}
        />
      ),
      icon: ICON_TYPE.trash,
      onClick: () => handleRemoveRoles(data ? [data?.id] : selection),
    };

    const copyOption = {
      label: <FormattedMessage id="Global.Copy" />,
      icon: ICON_TYPE.copy,
      onClick: () => {
        setIsCopying(true);
        setSelectedRole(data);
        setShowForm(true);
      },
    };

    return data ? [editOption, removeOption, copyOption] : [removeOption];
  };

  return {
    loading,
    loadingMore,
    deleting,
    handleRemoveRoles,
    getAdvancedOptions,
    isCopying,
    showForm,
    setShowForm,
    selectedRole,
    data: data?.missionRoles?.nodes,
    error,
    hasNextPage,
    handleNextPage,
  };
}
