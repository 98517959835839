import React, { useState, useEffect, useMemo } from "react";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";
import styled from "@emotion/styled";
import Tabs from "components/Tabs";
import { ApplicantList, ApplicantShareButton } from "components/Missions/Applicants";
import Card from "components/Card";
import { Select, CheckBox } from "components/Form";
import { GET_ORGANIZATIONS } from "graphql/queries";
import { useApplicants, useAuth, useMissionRoles, useMissions, useParamFunctions } from "hooks";
import { APPLICANT_TYPES, SORT } from "constants/index";
import { Grid } from "components/Containers";
import { TOGGLE_SIZE } from "components/Form/Toggle";
import ProgressMenu from "components/ProgressMenu";
import NoResults from "components/Messages/NoResults";
import { toUcFirst } from "utils";

/**
 * ApplicantListLayout
 */
const ApplicantListLayout = () => {
  const { paramParse, setParams } = useParamFunctions();
  const filters = paramParse(PARAM_KEYS.filters);
  const location = useLocation();
  const isPublishedFilter = filters?.[PARAM_KEYS.isPublished];

  const getInitialTab = () => {
    if (typeof filters?.[PARAM_KEYS.tab] === "number") {
      return filters[PARAM_KEYS.tab];
    }

    if (location.state?.tab) {
      return parseInt(location.state.tab);
    }

    return 1; // Default to tab 1 if no value is found
  };

  const [tabCounts, setTabCounts] = useState({
    appliedCount: 0,
    shortlistedCount: 0,
    interviewingCount: 0,
    selectedCount: 0,
    invitedCount: 0,
  });

  const [selectedOrganizationId, setSelectedOrganizationId] = useState(
    filters?.[PARAM_KEYS.organizationId] || location.state?.organizationId
  );
  const [selectedMissionId, setSelectedMissionId] = useState(
    filters?.[PARAM_KEYS.missionId] || location.state?.missionId
  );
  const [selectedMissionRoleId, setSelectedMissionRoleId] = useState(
    filters?.[PARAM_KEYS.missionRoleId] || location.state?.missionRoleId
  );
  const [selectedTab, setSelectedTab] = useState(getInitialTab());
  const [selectedPage, setSelectedPage] = useState(filters?.[PARAM_KEYS.page] || 0);
  const [selectedApplicantType, setSelectedApplicantType] = useState(filters?.[PARAM_KEYS.applicantType] || null);
  const [selection, setSelection] = useState([]);
  const { isClient, isShowcase, isAdmin, canViewUnpublishedRoles } = useAuth();
  const [isPublished, setIsPublished] = useState(
    isPublishedFilter === undefined ? !canViewUnpublishedRoles : !!isPublishedFilter
  );
  const [showTable, setShowTable] = useState(true);
  const isShowcaseOrClient = isClient || isShowcase;
  const pageTabs = PAGES.filter((item) => (isShowcaseOrClient && !item.adminOnly) || !isShowcaseOrClient);
  const applicantTabs = TABS.filter((item) => (isShowcaseOrClient && !item.adminOnly) || !isShowcaseOrClient);
  const selectedPageName = toUcFirst(pageTabs[selectedPage]?.type?.toLowerCase());

  useEffect(() => {
    if (selectedPage === 0) {
      setSelectedApplicantType(applicantTabs[selectedTab]?.type);
    } else {
      setSelectedApplicantType(pageTabs[selectedPage]?.type);
    }
  }, [selectedPage, selectedTab, pageTabs, applicantTabs]);

  useEffect(() => {
    setShowTable(pageTabs[selectedPage].type !== APPLICANT_TYPES.recommended || selectedMissionRoleId);
  }, [selectedMissionRoleId, selectedPage]);

  const {
    data,
    loading,
    loadingMore,
    error,
    hasNextPage,
    handleNextPage,
    hasPreviousPage,
    handlePreviousPage,
    handlePageChange,
    resultsPerPage,
    currentPage,
    pageInfo,
    totalCount,
    appliedCount,
    shortlistedCount,
    interviewingCount,
    selectedCount,
    invitedCount,
    paginationType,
  } = useApplicants({
    missionId: selectedMissionId,
    missionRoleId: selectedMissionRoleId,
    organizationId: selectedOrganizationId,
    applicantState: selectedApplicantType,
    published: isShowcaseOrClient ? false : isPublished, // Show unpublished roles for showcase and client
    resultsPerPage,
  });

  const { data: missionRoles, loading: loadingRoles } = useMissionRoles({
    missionId: selectedMissionId,
    filters: { published: isPublished },
    skipQuery: !selectedMissionId && !isShowcaseOrClient,
  });

  const { data: missions, loading: loadingMissions } = useMissions({
    filters: { organizationId: selectedOrganizationId },
    skip: !selectedOrganizationId,
  });

  const missionOptions = useMemo(
    () =>
      (missions || []).map((mission) => ({
        name: mission.name,
        id: mission.id,
      })),
    [missions]
  );

  const roleOptions = useMemo(
    () =>
      (missionRoles || []).map((role) => ({
        name: role.name,
        id: role.id,
        missionId: role.mission?.id,
        organizationId: role.mission?.organization?.id,
      })),
    [missionRoles]
  );

  useEffect(() => {
    if (isShowcaseOrClient && roleOptions?.length) {
      setSelectedMissionId(roleOptions[0]?.missionId);
      setSelectedMissionRoleId(roleOptions[0]?.id);
      setSelectedOrganizationId(roleOptions[0]?.organizationId);
    }
  }, [isShowcaseOrClient, roleOptions]);

  useEffect(() => {
    setParams(PARAM_KEYS.filters, {
      [PARAM_KEYS.missionId]: selectedMissionId,
      [PARAM_KEYS.missionRoleId]: selectedMissionRoleId,
      [PARAM_KEYS.organizationId]: selectedOrganizationId,
      [PARAM_KEYS.isPublished]: isPublished,
      [PARAM_KEYS.tab]: selectedTab,
      [PARAM_KEYS.applicantType]: selectedApplicantType,
      [PARAM_KEYS.page]: selectedPage,
    });

    // Reset to page 1 on filter
    if (totalCount) {
      handlePageChange(1, resultsPerPage);
    }
  }, [
    selectedMissionId,
    selectedMissionRoleId,
    selectedOrganizationId,
    selectedApplicantType,
    isPublished,
    selectedTab,
    selectedPage,
  ]);

  useEffect(() => {
    if (selectedMissionRoleId && !selectedMissionId) {
      setSelectedMissionId(roleOptions.find((item) => item.id === selectedMissionRoleId).missionId);
    }
  }, [selectedMissionRoleId]);

  useEffect(() => {
    const counts = { appliedCount, shortlistedCount, interviewingCount, selectedCount, invitedCount };
    Object.keys(counts).forEach((key) => {
      if (Number.isInteger(counts[key])) {
        setTabCounts((prevCounts) => ({ ...prevCounts, [key]: counts[key] }));
      }
    });
  }, [appliedCount, shortlistedCount, interviewingCount, selectedCount, invitedCount]);

  return (
    <Grid>
      {!isShowcase && (
        <Grid.col start={1} end={13}>
          <Card>
            <Grid cols={11}>
              {!isClient && (
                <>
                  <Grid.col start={1} end={4}>
                    <Select
                      value={selectedOrganizationId}
                      queryKeyName={"organizations"}
                      queryName={GET_ORGANIZATIONS}
                      placeholder="Organization"
                      labelKey="name"
                      queryVariables={{
                        first: 20,
                        orderBy: {
                          id: SORT.desc,
                        },
                      }}
                      valueKey="id"
                      onChange={(_, val) => setSelectedOrganizationId(val)}
                      querySearchFieldName="search"
                      noOptionsMessage="No organization found"
                      isSearchable
                      isClearable
                    />
                  </Grid.col>
                  <Grid.col start={4} end={7}>
                    <Select
                      options={missionOptions}
                      loading={loadingMissions}
                      disabled={!selectedOrganizationId}
                      value={selectedMissionId}
                      placeholder={!selectedOrganizationId ? "Select organization first" : "Mission"}
                      labelKey="name"
                      valueKey="id"
                      onChange={(_, val) => setSelectedMissionId(val)}
                      noOptionsMessage="No mission found"
                      isSearchable
                      isClearable
                    />
                  </Grid.col>
                </>
              )}
              <Grid.col start={!isClient ? 7 : 1} end={!isClient ? 10 : 4}>
                <Select
                  options={roleOptions}
                  disabled={!selectedMissionId && !isClient}
                  loading={loadingRoles}
                  value={selectedMissionRoleId}
                  placeholder={!selectedMissionId && !isClient ? "Select mission first" : "Role"}
                  labelKey="name"
                  valueKey="id"
                  onChange={(_, val) => setSelectedMissionRoleId(val)}
                  isClearable={!isShowcaseOrClient}
                />
              </Grid.col>
              <Grid.col start={!isClient ? 10 : 4} end={!isClient ? 13 : 7}>
                <CheckBoxContainer>
                  <CheckBox
                    value={isPublished}
                    onChange={(_, val) => setIsPublished(val)}
                    size={TOGGLE_SIZE.small}
                    label={<FormattedMessage id={"Missions.Applicants.ToggleOpenRoles"} />}
                    disabled={!canViewUnpublishedRoles}
                  />
                </CheckBoxContainer>
              </Grid.col>
            </Grid>
          </Card>
        </Grid.col>
      )}
      <Grid.col start={1} end={13}>
        <Card>
          <Grid>
            <Grid.col start={1} end={13}>
              <Tabs
                data={pageTabs.map((tab, index) => (
                  <FormattedMessage key={index} id={tab.title} />
                ))}
                selected={selectedPage}
                onSelect={setSelectedPage}
                withUrlParams
              />
            </Grid.col>
            {selectedPage === 0 && (
              <Grid.col start={1} end={13}>
                <ProgressMenu
                  items={applicantTabs.map((tab, index) => (
                    <FormattedMessage
                      key={index}
                      id={tab.title}
                      values={{
                        count: <div css={styles.count}>({tabCounts[tab.countField]})</div>,
                      }}
                    />
                  ))}
                  selected={selectedTab}
                  onSelect={setSelectedTab}
                />
              </Grid.col>
            )}
            <Grid.col start={1} end={13}>
              <hr />
            </Grid.col>
            <Grid.col start={1} end={13}>
              {showTable ? (
                <ApplicantList
                  isClient={isClient}
                  onSelect={setSelection}
                  data={data}
                  loading={loading || loadingMore}
                  error={error}
                  applicantType={selectedApplicantType}
                  resultsPerPage={resultsPerPage}
                  currentPage={currentPage}
                  pageInfo={pageInfo}
                  totalCount={totalCount}
                  handleNextPage={handleNextPage}
                  handlePreviousPage={handlePreviousPage}
                  handlePageChange={handlePageChange}
                  hasNextPage={hasNextPage}
                  hasPreviousPage={hasPreviousPage}
                  selectionInitialState={selection}
                  showAdvancedOptions={isAdmin}
                  showRateColumn={isAdmin}
                  openModalOnRowClick={isShowcaseOrClient}
                  paginationType={paginationType}
                />
              ) : (
                <NoResults
                  title={`Missions.MissionCandidateList.${selectedPageName}.HideTable.Title`}
                  description={`Missions.MissionCandidateList.${selectedPageName}.HideTable.Description`}
                />
              )}
            </Grid.col>
          </Grid>
        </Card>
      </Grid.col>
      <Grid.col start={1} end={13}>
        <ApplicantShareButton
          missionRoleSlug={data && data[0]?.missionRole?.slug}
          applicantType={selectedApplicantType}
          missionId={selectedMissionId}
          missionRoleId={selectedMissionRoleId}
        />
      </Grid.col>
    </Grid>
  );
};

const styles = {
  count: css`
    font-weight: 400;
  `,
};

const PARAM_KEYS = {
  filters: "f",
  missionId: "missionId",
  missionRoleId: "missionRoleId",
  organizationId: "organizationId",
  isPublished: "isPublished",
  applicantType: "applicantType",
  page: "page",
  tab: "tab",
};

const PAGES = [
  {
    title: "Missions.Applicants.TabTitleApplicants",
  },
  {
    title: "Missions.Applicants.TabTitleRecommended",
    type: APPLICANT_TYPES.recommended,
  },
  {
    title: "Missions.Applicants.TabTitleNotSelected",
    adminOnly: true,
    type: APPLICANT_TYPES.unselected,
  },
];

const TABS = [
  {
    title: "Missions.Applicants.TabTitleInvited",
    adminOnly: true,
    countField: "invitedCount",
    type: null,
  },
  {
    title: "Missions.Applicants.TabTitleApplied",
    type: APPLICANT_TYPES.applied,
    countField: "appliedCount",
  },
  {
    title: "Missions.Applicants.TabTitleShortlist",
    type: APPLICANT_TYPES.shortlisted,
    countField: "shortlistedCount",
  },
  {
    title: "Missions.Applicants.TabTitleInterviewing",
    type: APPLICANT_TYPES.interviewed,
    countField: "interviewingCount",
  },
  {
    title: "Missions.Applicants.TabTitleSelected",
    type: APPLICANT_TYPES.selected,
    countField: "selectedCount",
  },
];

const CheckBoxContainer = styled.div`
  margin-top: 0.5rem;
`;

export default ApplicantListLayout;
