import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useInterviewResponses } from "hooks";
import { INTERVIEW_STATUSES } from "constants";
import { AdvancedButton } from "components/Buttons";
import { ICON_TYPE } from "components/Icon";

/**
 * ProfileInterviewsAdvancedOptions
 * @param {String}    profileId
 * @param {String}    interview
 * @param {Boolean}   hasPermission
 */
const ProfileInterviewsAdvancedOptions = ({ profileId, interview, hasPermission }) => {
  const { handleDeleteInterviewResponse, handleRestoreInterviewResponse } = useInterviewResponses(
    { filters: { profileId, status: INTERVIEW_STATUSES.completed } },
    true
  );

  if (!hasPermission) return null;

  const restoreOption = {
    label: <FormattedMessage id="Profile.ProfileInterviews.RestoreInterviewButton.Title" />,
    icon: ICON_TYPE.plus,
    onClick: () => handleRestoreInterviewResponse(interview.id),
  };

  const deleteOption = {
    label: <FormattedMessage id="Profile.ProfileInterviews.DeleteInterviewButton.Title" />,
    icon: ICON_TYPE.trash,
    onClick: () => handleDeleteInterviewResponse(interview.id),
  };

  const options = interview ? [interview.deletedAt ? restoreOption : deleteOption] : [];

  return <AdvancedButton options={options} disabled={!interview} />;
};

ProfileInterviewsAdvancedOptions.propTypes = {
  profileId: PropTypes.string,
  interview: PropTypes.string,
  hasPermission: PropTypes.bool,
};

export default ProfileInterviewsAdvancedOptions;
