import React from "react";
import { useModalConfirmation, useQueryData, useToast } from "hooks";
import { GET_CHALLENGE_RESPONSES } from "graphql/queries";
import { PAGINATION_TYPE } from "components/Pagination";
import { useMutation } from "@apollo/client";
import { ADD_CHALLENGE_RESPONSE } from "graphql/mutations";
import { FormattedMessage } from "react-intl";

/**
 * useProfileCodeChallenge
 *
 * @params {String}   profileId
 * @params {Number}   resultsPerPage
 */
export default function useProfileCodeChallenge({ profileId, resultsPerPage = 2, variables }) {
  const { toast } = useToast();
  const { show } = useModalConfirmation();

  const { hasNextPage, handleNextPage, loading, data, error, refetch } = useQueryData({
    queryName: GET_CHALLENGE_RESPONSES,
    keyName: "challengeResponses",
    resultsPerPage,
    paginationType: PAGINATION_TYPE.append,
    variables: {
      profileId,
      ...variables,
    },
  });

  const [addChallengeResponse, { loading: adding }] = useMutation(ADD_CHALLENGE_RESPONSE, { refetchQueries: [GET_CHALLENGE_RESPONSES] });

  const handleAddChallengeResponse = async (input, challengeName) => {
    const response = await show(
      <FormattedMessage id="useProfileCodeChallenge.ConfirmationModal.Title" />,
      <>
        <div>
          <FormattedMessage id="useProfileCodeChallenge.ConfirmationModal.Description" />
        </div>
        <div>
          <strong>{challengeName}</strong>
        </div>
      </>,
      {
        primaryButtonMessageId: "Send",
      },
    );

    if (response) {
      addChallengeResponse({
        variables: {
          input,
        },
        onCompleted: () => toast.success(<FormattedMessage id="useProfileCodeChallenge.Success" />),
        onError: () => toast.error(<FormattedMessage id="useProfileCodeChallenge.Error" />),
      });        
    };
  };

  return {
    hasNextPage: hasNextPage,
    handleNextPage,
    refetch,
    error,
    loading,
    handleAddChallengeResponse,
    adding,
    data: data?.challengeResponses?.nodes,
  };
}
