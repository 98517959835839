import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Card from "components/Card";
import { RENDER_SIZE } from "components/DisplayBox/Renderers/RenderIconWithText";
import { useAuth, useProfile } from "hooks";
import {
  CandidateProgress,
  useRenderCandidateProgress,
  CandidateArchived,
  CandidateInterviewList,
} from "components/Candidate";
import { MEMBER_STATUS } from "constants/index";
import { ProfileCodeChallenge, ProfileAdminVideoInterview } from "components/Profile";
import { Grid } from "components/Containers";

/**
 * Admin Profile Views
 *
 * @params {String}     profileId
 */
const ProfileAdmin = ({ profileId }) => {
  const { isAdmin } = useAuth();
  const { data, loading } = useProfile({ profileId });
  const shouldRenderCandidateProgress = useRenderCandidateProgress(profileId);

  if (!isAdmin || !data?.profile) {
    return null;
  }
  const isActiveMember = MEMBER_STATUS.active === data?.profile?.status;
  const isArchived = MEMBER_STATUS.archived === data?.profile?.status;

  return (
    <Grid>
      {isArchived && (
        <Grid.col start={1} end={13}>
          <Card isAdmin>
            <CandidateArchived data={data?.profile} loading={loading} />
          </Card>
        </Grid.col>
      )}
      {shouldRenderCandidateProgress && (
        <Grid.col start={1} end={13}>
          <Card isAdmin>
            <CandidateProgress
              profileId={profileId}
              isActiveMember={isActiveMember}
              isArchived={isArchived}
              title={<FormattedMessage id="Profile.ProfileAdmin.CandidateProgressTitle" />}
              isAdmin
            />
          </Card>
        </Grid.col>
      )}
      <Grid.col start={1} end={13}>
        <Card isAdmin>
          <ProfileCodeChallenge
            description={false}
            size={RENDER_SIZE.small}
            profileId={profileId}
            canView={isAdmin}
            isEditable={true}
            canAdd={true}
          />
        </Card>
      </Grid.col>
      <Grid.col start={1} end={13}>
        <Card isAdmin>
          <ProfileAdminVideoInterview description={false} size={RENDER_SIZE.small} profileId={profileId} />
        </Card>
      </Grid.col>
      <Grid.col start={1} end={13}>
        <Card isAdmin>
          <CandidateInterviewList description={false} subtitle={false} isAdmin={isAdmin} profileId={profileId} />
        </Card>
      </Grid.col>
    </Grid>
  );
};

const useRender = (profileId) => {
  const { isAdmin } = useAuth();
  const { data, loading, error } = useProfile({ profileId });
  const { profile } = data || {};

  if (loading || error) return false;

  return isAdmin && !!profile;
};

ProfileAdmin.propTypes = {
  profileId: PropTypes.string,
};

export { useRender };

export default ProfileAdmin;
