/**
 * Removes an object from a cache reference.
 *
 * @param {Object} cacheRef The cache reference to modify
 * @param {Object} object The object to remove
 * @param {Function} readField The readField function from the Apollo cache
 *
 * @returns {Object} The modified cache reference
 */
export default function removeObjectFromCacheRef(cacheRef, object, readField) {
  const newNodes = cacheRef.nodes.filter((ref) => readField("id", ref) !== object.id);

  return {
    ...cacheRef,
    nodes: newNodes,
    totalCount: newNodes.length,
  };
}
