import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import { shortenText } from "utils";
import { PrimaryButton, BUTTON_SIZE } from "components/Buttons";
import { LINKS } from "constants/index";
import { Link } from "components/Links";
import { useMissionRoles } from "hooks";
import ListTable from "components/ListTable";
import { margins } from "style";

/**
 * RelatedRoles
 *
 * @params {String}    missionId
 * @params {String}    marginSize
 * @params {Boolean}   isLoading
 * @params {Boolean}   canViewUnpublishedRoles
 */
const RelatedRoles = ({ missionId, marginSize, isLoading, canViewUnpublishedRoles }) => {
  const { roleId } = useParams();
  const { loading, data } = useMissionRoles({
    missionId,
    resultsPerPage: 20, // load all
    filters: {
      published: !canViewUnpublishedRoles,
    },
  });

  return (
    <ListTable
      data={isLoading || loading ? null : TABLE_DATA(missionId, roleId, data)}
      loading={isLoading || loading}
      title={<FormattedMessage id="Missions.MissionRole.TitleOtherRoles" />}
      marginSize={marginSize}
      resultsPerPage={3}
    />
  );
};

const TABLE_DATA = (missionId, missionRoleId, data) =>
  data?.map(({ name, slug }) => ({
    label: shortenText(name, 50),
    value: missionRoleId !== slug && (
      <Link to={LINKS.mission_role(missionId, slug)} routerEnabled>
        <PrimaryButton size={BUTTON_SIZE.small}>
          <FormattedMessage id="Global.View" />
        </PrimaryButton>
      </Link>
    ),
  }));

RelatedRoles.defaultProps = {
  marginSize: margins.normal,
};

RelatedRoles.propTypes = {
  missionId: PropTypes.object,
  marginSize: PropTypes.string,
  isLoading: PropTypes.bool,
  canViewUnpublishedRoles: PropTypes.bool,
};

export default RelatedRoles;
