/**
 * Adds an object to a cache reference.
 *
 * @param {Object} cacheRef The cache reference to modify
 * @param {String} key The key to add
 *
 * @returns {Object} The modified cache reference
 */
export default function addObjectToCacheRef(cacheRef, key) {
  return {
    ...cacheRef,
    nodes: [{ __ref: key }, ...cacheRef.nodes],
    totalCount: cacheRef.totalCount + 1,
  };
}
