import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { colors, fontSize } from "style";
import { SIZE } from "components/Form";

/**
 * TextArea
 */
const TextArea = styled.textarea`
  display: block;
  font-size: ${fontSize.small};
  background: #fff;
  font-weight: normal;
  ${({ height }) => height === null && `height: 18rem;`}
  ${({ height }) => height === SIZE.small && `height: 6rem;`}
  ${({ height }) => height === SIZE.medium && `height: 12rem;`}
  ${({ height }) => height === SIZE.large && `height: 18rem;`}
  ${({ height }) => height === SIZE.xlarge && `height: 24rem;`}
  padding: 1rem 1rem;
  line-height: 2.4rem;
  border-radius: 0.6rem;
  border: 1px solid ${colors.grayAnatomyLight3};
  width: 100%;
  color: ${colors.secondary};
  order: 1;
  resize: none;
  appearance: none;
  background-clip: padding-box;
  outline: none;
  transition: all 0.3s ease;
  ${({ maxLimit }) => maxLimit && `margin-bottom: 1rem;`}

  &::placeholder {
    color: ${colors.grayAnatomyLight2};
  }

  &:focus {
    border-color: ${colors.purpleRainBase};
    box-shadow: 0px 0px 0px 3px ${colors.purpleRainLight3};

    & + label {
      color: ${colors.purpleRainBase};
    }
  }

  &:hover {
    border-color: ${colors.purpleRainBase};
  }

  &:disabled {
    border: 1px solid ${colors.grayAnatomyLight3} !important;
    color: ${colors.grayAnatomyLight2} !important;
    background-color: ${colors.grayAnatomyLight5};

    &:placeholder {
      color: ${colors.grayAnatomyLight1};
    }
  }
`;

/**
 * TextAreaWithOnChange
 *
 * @param {Function}  onChange
 * @param {Number}    maxLength
 * @param {String}    value
 */
const TextAreaWithOnChange = ({ onChange, maxLength, value, ...props }) => {
  const [charactersCount, setCharactersCount] = useState(value?.length || 0);

  const handleChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= maxLength || !maxLength) {
      setCharactersCount(inputValue.length);

      if (onChange) {
        onChange(e.target.name, inputValue.length === 1 ? inputValue.trim() : inputValue);
      }
    }
  };

  return (
    <>
      <TextArea value={value} onChange={handleChange} maxLimit={maxLength} {...props} />
      <CharactersCount charactersCount={charactersCount} maxLength={maxLength} />
    </>
  );
};

/**
 * CharactersCount
 *
 * @param {Number}    charactersCount
 * @param {Number}    maxLength
 */
const CharactersCount = ({ charactersCount, maxLength }) => {
  if (!maxLength) return null;

  return (
    <div css={styles.characters_count}>
      <FormattedMessage id="Form.TextArea.CharactersCount" values={{ charactersCount, maxLength }} />
    </div>
  );
};

TextAreaWithOnChange.propTypes = {
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  value: PropTypes.string,
};

CharactersCount.propTypes = {
  charactersCount: PropTypes.number,
  maxLength: PropTypes.number,
};

const styles = {
  characters_count: css`
    position: absolute;
    bottom: -0.6rem;
    right: 0rem;
    font-size: ${fontSize.xxsmall};
    color: ${colors.grayAnatomyLight1};
  `,
};

export { TextArea, TextAreaWithOnChange };

export default TextAreaWithOnChange;
