import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Grid } from "components/Containers";
import { useSearch } from "hooks";
import FilterMenu from "components/Search/FilterMenu";
import Card from "components/Card";
import { breakpoints } from "style";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { BUTTON_SIZE, PrimaryButton, SecondaryButton } from "components/Buttons";

/**
 * SearchWithFilters
 *
 * @param {Number}    resultsPerPage
 * @param {String}    keyName
 * @param {String}    sort
 * @param {String}    sortDirection
 * @param {Object}    initialOperators
 * @param {Object}    initialValues
 * @param {Array}     disallowedFilters
 * @param {Array}     defaultFilters
 * @param {Array}     staticFilters
 * @param {Boolean}   isCardView
 * @param {Boolean}   hideSearch
 * @param {Boolean}   hideSort
 * @param {Function}  onItemClick
 * @param {String}    search
 * @param {Array}     actions
 * @param {Array}     profileModalActions
 * @param {Function}  renderer
 * @param {Object}    rendererProps
 * @param {String}    filterMenuKeyName
 * @param {Object}    additionalQueryVariables
 * @param {Boolean}   skip
 */
const SearchWithFilters = ({
  resultsPerPage,
  keyName,
  sort,
  sortDirection,
  initialOperators,
  initialValues,
  defaultFilters,
  staticFilters,
  isCardView,
  hideSearch,
  hideSort,
  onItemClick,
  search,
  actions,
  profileModalActions,
  renderer,
  rendererProps,
  filterMenuKeyName,
  additionalQueryVariables,
  skip,
}) => {
  const RendererComponent = renderer;
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const { filters, setFilters, allowedFilters, perPage, ...data } = useSearch({
    keyName,
    search,
    resultsPerPage,
    staticFilters,
    sort,
    sortDirection,
    initialValues,
    initialOperators,
    additionalVariables: additionalQueryVariables,
    skip,
  });

  return (
    <>
      <FilterMobileMenu>
        <SecondaryButton onClick={() => setShowFilterMenu(!showFilterMenu)} size={BUTTON_SIZE.small}>
          <FilterIcon>
            Filter
            <Icon type={ICON_TYPE.filters} size={ICON_SIZE.medium} color="inherit" />
          </FilterIcon>
        </SecondaryButton>
      </FilterMobileMenu>
      <Grid cols={24} style={{ "--show-filter-menu": showFilterMenu ? `block` : `none` }}>
        <Grid.col start={1} end={19}>
          <RendererComponent
            isCardView={isCardView}
            resultsPerPage={perPage}
            actions={actions}
            profileModalActions={profileModalActions}
            onItemClick={onItemClick}
            {...data}
            {...rendererProps}
          />
        </Grid.col>
        <Grid.col start={19} end={25}>
          <StyledCard>
            <FilterMenu
              onSubmit={setFilters}
              keyName={filterMenuKeyName || `${keyName}-search`}
              values={filters}
              data={data?.filterData}
              allowedFilters={allowedFilters}
              setFilterMetaData={data?.setFilterMetaData}
              handlePageChange={data?.handlePageChange}
              loading={data?.loading}
              payload={data}
              defaultFilters={defaultFilters}
              hideSearch={hideSearch}
              hideSort={hideSort}
              initialValues={filters}
              defaultValues={initialValues}
            />
            <ModalFooter>
              <StyledButton onClick={() => setShowFilterMenu(!showFilterMenu)}>Close</StyledButton>
            </ModalFooter>
          </StyledCard>
        </Grid.col>
      </Grid>
    </>
  );
};

const StyledCard = styled(Card)`
  padding: 2rem;

  @media (max-width: ${breakpoints.portrait}) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 6rem !important;
    width: 100%;
    z-index: 11;
    overflow-y: scroll;
    margin: 0;
    border-radius: 0;
    border: 0;
    display: var(--show-filter-menu, none);
  }
`;

const FilterMobileMenu = styled.div`
  display: none;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (max-width: ${breakpoints.portrait}) {
    display: flex;
    padding-right: 2.5rem;
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding-right: 2rem;
  }
`;

const FilterIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledButton = styled(PrimaryButton)`
  width: 100%;
`;

const ModalFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem 3rem;
  background: #fff;
  display: none;

  @media (max-width: ${breakpoints.portrait}) {
    display: block;
  }
`;

SearchWithFilters.defaultProps = {
  resultsPerPage: 18,
  keyName: "networkProfiles",
  sort: "showcase_score",
  sortDirection: "desc",
  initialOperators: { skills: false, disciplines: false },
  initialValues: {},
  defaultFilters: [
    { label: "Archetype", id: "archetype" },
    { label: "Disciplines", id: "disciplines" },
    { label: "Tech Stack", id: "skills" },
    { label: "Country", id: "country" },
    { label: "Region", id: "world_region" },
    { label: "Industries", id: "industries" },
    { label: "Years of Experience", id: "experience_years" },
    { label: "Time Zone", id: "time_zone" },
    { label: "Job Preference", id: "is_open_to_employment" },
    { label: "Video Interviews", id: "has_video_interviews" },
    { label: "Status", id: "status" },
    { label: "Max Selling Rate (USD)", id: "selling_price_hourly_high_usd" },
  ],
  hideSearch: true,
  hideSort: true,
  isCardView: true,
  additionalQueryVariables: {},
};

SearchWithFilters.propTypes = {
  resultsPerPage: PropTypes.number,
  filterMenuKeyName: PropTypes.string,
  keyName: PropTypes.string,
  sort: PropTypes.string,
  sortDirection: PropTypes.string,
  initialOperators: PropTypes.object,
  initialValues: PropTypes.object,
  disallowedFilters: PropTypes.array,
  defaultFilters: PropTypes.array,
  staticFilters: PropTypes.array,
  hideSearch: PropTypes.bool,
  hideSort: PropTypes.bool,
  isCardView: PropTypes.bool,
  search: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  actions: PropTypes.array,
  profileModalActions: PropTypes.array,
  renderer: PropTypes.any.isRequired,
  rendererProps: PropTypes.object,
  onItemClick: PropTypes.func,
  additionalQueryVariables: PropTypes.object,
  skip: PropTypes.bool,
};

export default SearchWithFilters;
