import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import Tabs from "components/Tabs";
import { Col, Grid, Row } from "components/Containers";
import Icon, { ICON_POSITION, ICON_SIZE, ICON_TYPE, withIcon } from "components/Icon";
import { Select, TextInput } from "components/Form";
import { CHALLENGES, CHALLENGES_TYPES, DEFAULT_CHALLENGE } from "constants/index";
import { DataTableProvider } from "components/DataTable";
import { GET_CHALLENGES } from "graphql/queries";
import Tags, { TAG_SIZE } from "components/Tags";
import { formatDate, shortenText } from "utils";
import { CodeChallengeForm } from "components/CodeChallenges";
import Status from "components/Status";
import { useCodeChallenges } from "hooks";
import { PrimaryLink } from "components/Links";
import { colors } from "style";
import { TAG_COLORS } from "components/Tags/Tag";

/**
 * CodeChallengesList
 *
 * @param {Function}  onItemClick
 * @param {Array}     allowedFilters
 * @param {Boolean}   disableRowOptions
 */
const CodeChallengesList = ({ onItemClick, allowedFilters, disableRowOptions, ...props }) => {
  const [keyword, setKeyword] = useState();
  const [selectedChallengeType, selSelectedChallengeType] = useState(DEFAULT_CHALLENGE);
  const [selectedMappingType, setSelectedMappingType] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [published, setPublished] = useState(true);
  const [selectedChallenge, setSelectedChallenge] = useState(false);
  const { handleUpdateCodeChallenge, keyName, paginationType, resultsPerPage } = useCodeChallenges({ skip: true });

  const handleClick = (data) => {
    setShowForm(true);
    setSelectedChallenge(data);
  };

  const rowOptions = ({ id, published, ...rest }) => [
    {
      label: (
        <FormattedMessage
          id={`CodeChallenges.CodeChallengeRowAdvancedOptions.Label${published ? `Disable` : `Enable`}`}
        />
      ),
      icon: published ? ICON_TYPE.close : ICON_TYPE.checkDotted,
      onClick: () => handleUpdateCodeChallenge(id, { published: !published }),
    },
    {
      label: <FormattedMessage id={"CodeChallenges.CodeChallengeRowAdvancedOptions.LabelUpdate"} />,
      icon: ICON_TYPE.edit,
      onClick: () => {
        setShowForm(true);
        handleClick({ id, published, ...rest });
      },
    },
  ];

  return (
    <>
      <Grid>
        {allowedFilters.includes("mappingType") && (
          <Grid.col start={1} end={4}>
            <Tabs
              data={MAPPING_TYPES.map((tab) => tab.name)}
              selected={selectedMappingType}
              onSelect={setSelectedMappingType}
              withUrlParams
            />
          </Grid.col>
        )}
        {allowedFilters.includes("status") && (
          <Grid.col start={4} end={7}>
            <Select
              value={published}
              placeholder="Status"
              options={[
                {
                  name: "Enabled",
                  value: true,
                },
                {
                  name: "Disabled",
                  value: false,
                },
              ]}
              valueKey="value"
              labelKey="name"
              onChange={(_name, val) => setPublished(val)}
              isClearable
            />
          </Grid.col>
        )}
        {allowedFilters.includes("challengeType") && (
          <Grid.col start={7} end={10}>
            <Select
              value={selectedChallengeType}
              placeholder="Challenge Type"
              options={CHALLENGES_TYPES}
              valueKey="value"
              labelKey="name"
              onChange={(_name, val) => selSelectedChallengeType(val)}
              isClearable
            />
          </Grid.col>
        )}
        {allowedFilters.includes("search") && (
          <Grid.col start={10} end={13}>
            <SearchInput
              placeholder="Type to search..."
              value={keyword}
              onChange={(_name, val) => setKeyword(val)}
              type="search"
            />
          </Grid.col>
        )}
        <Grid.col start={1} end={13}>
          <DataTableProvider
            keyName={keyName}
            queryName={GET_CHALLENGES}
            variables={{
              filters: {
                name: keyword,
                category: MAPPING_TYPES[selectedMappingType].value,
                type: selectedChallengeType,
                published: published,
              },
            }}
            paginationType={paginationType}
            resultsPerPage={resultsPerPage}
            columnData={COLUMNS(handleClick)}
            rowOptions={disableRowOptions ? null : rowOptions}
            onClick={typeof onItemClick === "function" ? (row) => onItemClick(row) : undefined}
            paginationProps={{
              countMessage: "Global.CodeChallenges.Count",
            }}
            {...props}
          />
        </Grid.col>
      </Grid>
      <CodeChallengeForm
        show={showForm}
        onClose={() => setShowForm(false)}
        challengeId={selectedChallenge?.id}
        isEditing={true}
        data={selectedChallenge}
      />
    </>
  );
};

const MAPPING_TYPES = [
  {
    name: "Mapped",
    value: "MAPPED",
  },
  {
    name: "Unmapped",
    value: "UNMAPPED",
  },
];

const SearchInput = withIcon(TextInput, {
  type: ICON_TYPE.search,
  size: ICON_SIZE.medium,
  position: ICON_POSITION.overlay,
  order: 0,
});

const COLUMNS = (onClick) => [
  {
    label: <FormattedMessage id="CodeChallenges.CodeChallengeList.ColumnTitle" />,
    cell: (row) => (
      <Row>
        <div>
          <Icon type={ICON_TYPE[row?.icon || `data`]} size={ICON_SIZE.large} color="inherit" />
        </div>
        <Col gap="0.25rem">
          <PrimaryLink onClick={() => onClick(row)}>
            <Title>{shortenText(row?.name, 40)}</Title>
          </PrimaryLink>
          <Type>{CHALLENGES[row?.type]}</Type>
          <Id>{row?.serviceId}</Id>
        </Col>
      </Row>
    ),
    width: "30%",
  },
  {
    label: <FormattedMessage id="CodeChallenges.CodeChallengeList.ColumnPrivateTitle" />,
    cell: (row) => row?.privateName,
    width: "15%",
  },
  {
    label: <FormattedMessage id="CodeChallenges.CodeChallengeList.ColumnSkills" />,
    cell: (row) => (
      <Row gap="0.4rem">
        {row?.skills?.length > 0 && (
          <Tags data={row?.skills} limit={2} unwrap size={TAG_SIZE.small} {...TAG_COLORS.pink} />
        )}
        {row?.disciplines?.length > 0 && (
          <Tags data={row?.disciplines} limit={2} unwrap size={TAG_SIZE.small} {...TAG_COLORS.purple} />
        )}
      </Row>
    ),
    width: "25%",
  },
  {
    label: <FormattedMessage id="CodeChallenges.CodeChallengeList.ColumnCategory" />,
    cell: (row) => row?.types?.length > 0 && <Tags data={row?.types} limit={2} />,
    width: "15%",
  },
  {
    label: <FormattedMessage id="CodeChallenges.CodeChallengeList.ColumnStatus" />,
    cell: ({ published }) => (
      <div>
        <Status enabled={published}>
          <FormattedMessage id={`CodeChallenges.CodeChallengeList.Label${published ? `Enabled` : `Disabled`}`} />
        </Status>
      </div>
    ),
    width: "15%",
  },
  {
    label: <FormattedMessage id="CodeChallenges.CodeChallengeList.ColumnCreationDate" />,
    cell: (row) => formatDate(row?.createdAt),
    width: "10%",
  },
];

const Title = styled.span`
  font-weight: 500;
`;

const Id = styled.span`
  font-weight: 400;
  color: ${colors.grayAnatomyLight1};
`;

const Type = styled.span`
  font-weight: 500;
`;

CodeChallengesList.defaultProps = {
  allowedFilters: ["mappingType", "challengeType", "status", "search"],
};

CodeChallengesList.propTypes = {
  onItemClick: PropTypes.func,
  allowedFilters: PropTypes.array,
  disableRowOptions: PropTypes.bool,
};

export default CodeChallengesList;
