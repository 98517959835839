import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { SearchWithFilters } from "components/Search";
import { NetworkList } from "components/Network";
import { HireButton, ProfileRateRange } from "components/Profile";
import { ListNote, RemoveFromListButton } from "components/Lists";
import { Col, Row } from "components/Containers";
import { UserArchetype, UserBadges, UserFullName, UserPhoto } from "components/User";
import { colors, fontSize, gradients } from "style";
import { getYearsExperience } from "utils";
import { ICON_TYPE } from "components/Icon";
import { useList } from "hooks";
import Tags from "components/Tags";
import { TAG_COLORS, TAG_SIZE } from "components/Tags/Tag";
import { LINKS, OPERATORS } from "constants/index";
import Card from "components/Card";
import NoResults from "components/Messages/NoResults";

/**
 * FavoriteList
 *
 * @param {Array}   data
 * @param {Boolean} loading
 * @param {Object}  selectedList
 * @param {String}  listId
 */
const FavoriteList = ({ data, loading, selectedList, listId }) => {
  const [staticFilters, setStaticFilters] = useState([]);
  const { removeFromList } = useList({ skipListQuery: true });
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.[0]?.profiles?.length > 0) {
      setStaticFilters([
        {
          key: "profile_id.keyword",
          operator: OPERATORS.or,
          categoricalValues: data[0].profiles.map((profile) => `${profile?.id}`) || [],
        },
      ]);
    }
  }, [data]);

  const advancedOptions = (row) => [
    {
      label: <FormattedMessage id={`Lists.ListFavoriteButton.Remove`} />,
      icon: ICON_TYPE.trash,
      onClick: () => removeFromList(row?.id, listId),
    },
    {
      label: <FormattedMessage id={`ClientSearch.ClientSearchProfileHire.CTA`} />,
      icon: ICON_TYPE.checkmark,
      onClick: () => navigate(LINKS.book_meeting_link),
    },
  ];

  return (
    <>
      {!loading && staticFilters.length === 0 ? (
        <Card>
          <NoResults />
        </Card>
      ) : (
        <SearchWithFilters
          staticFilters={staticFilters}
          actions={[HireButton, RemoveFromListButton]}
          renderer={NetworkList}
          isCardView={false}
          hideSearch={false}
          skip={staticFilters.length === 0}
          additionalQueryVariables={{
            includeProfileList: true,
            listId: selectedList?.id,
          }}
          rendererProps={{
            columnData: COLUMN_DATA,
            rowOptions: advancedOptions,
          }}
        />
      )}
    </>
  );
};

const COLUMN_DATA = (onNameClick) => [
  {
    width: "65%",
    label: <FormattedMessage id="Network.NetworkList.LabelMember" />,
    cell: (row) => (
      <StyledRow>
        <StyledCol gap="0.5rem">
          <UserPhoto width="5rem" height="5rem" borderRadius="100%" data={row} showStatus />
        </StyledCol>
        <Col gap="0.5rem">
          <Row>
            <Title>
              <UserFullName data={row} disableLink={true} onClick={onNameClick} />
            </Title>
            <FormattedMessage
              id="Profile.ProfileWorkExperience.TitleWithYears"
              values={{
                years: <ExperienceLabel>{getYearsExperience(row?.workExperiences)}</ExperienceLabel>,
              }}
            />
            <UserBadges badges={row?.badges} size="1.75rem" limit={6} />
          </Row>
          <Archetype>
            <UserArchetype data={row} />
          </Archetype>
          <TagContainer gap="0.25rem">
            <Tags data={row?.disciplines} limit={6} unwrap {...TAG_COLORS.purple} size={TAG_SIZE.small} />
            <Tags data={row?.skills} limit={6} unwrap {...TAG_COLORS.pink} size={TAG_SIZE.small} />
          </TagContainer>

          <ListNote note={row?.profileList?.note} />
        </Col>
      </StyledRow>
    ),
  },
  {
    width: "22%",
    cell: (row) => (
      <ProfileRateRange
        low={row?.sellingPriceEstimate?.hourly?.low}
        high={row?.sellingPriceEstimate?.hourly?.high}
        currency={row?.sellingPriceEstimate?.currency}
        negotiatedRate={row?.profileList?.negotiatedRate}
        negotiatedRateCurrency={row?.profileList?.negotiatedRateCurrency}
      />
    ),
  },
];

const ExperienceLabel = styled.span`
  color: ${colors.grayAnatomyLight1};
  background: ${gradients.redLinearGradient};
  padding: 0.3rem 0.8rem;
  border-radius: 1.2rem;
  white-space: nowrap;
  font-weight: 500;
  color: #fff;
`;

const TagContainer = styled(Row)`
  margin-left: -0.75rem;
  margin-top: 0.25rem;
`;

const StyledCol = styled(Col)`
  margin-bottom: auto;
  align-items: center;
  margin-right: 1rem;
  width: 5rem;
`;

const StyledRow = styled(Row)`
  padding: 1rem 0 1rem 0;
  flex-wrap: nowrap;
`;

const Title = styled.span`
  font-weight: 600;
  font-size: ${fontSize.small};
  color: ${colors.purpleRainBase};
  margin-bottom: -0.25rem;
`;

const Archetype = styled.span`
  font-weight: 500;
`;

FavoriteList.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  selectedList: PropTypes.object,
  listId: PropTypes.string,
};

export default FavoriteList;
