import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router";
import PageTitle from "components/PageTitle";
import { Grid, PageWrapper } from "components/Containers";
import { DatePicker } from "components/Form";
import { PULSE_MODE, PulseTable } from "components/Squads/Pulse";
import { DATE_FORMAT_TYPE } from "constants/index";

/**
 * Pulse Page
 */
const Pulse = () => {
  const intl = useIntl();
  const { id } = useParams();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const previousMonth = String(currentDate.getMonth() + 2).padStart(2, "0");
  const currentDay = String(currentDate.getDate()).padStart(2, "0");
  const startDate = `${currentYear - 1}-${previousMonth}-01`;
  const endDate = `${currentYear}-${currentMonth}-${currentDay}`;
  const [pulseDateRange, setPulseDateRange] = useState([startDate, endDate]);
  const [squadDynamicsDate, setSquadDynamicsDate] = useState(`${currentYear}-${currentMonth}-01`);

  const handleDateChange = (name, value) => (name === "pulseDate" ? setPulseDateRange : setSquadDynamicsDate)?.(value);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Squads.Pulse.Title" })}</title>
      </Helmet>
      <PageWrapper>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle
              subtitle={<FormattedMessage id="Squads.Pulse.Title" />}
              actionBar={
                <DatePicker
                  name="pulseDate"
                  value={pulseDateRange}
                  onChange={handleDateChange}
                  maxDate={new Date()}
                  enableMonthRangePicker
                  enableDateCycle
                />
              }
            />
          </Grid.col>
          <Grid.col start={1} end={13}>
            <PulseTable dateRange={pulseDateRange} teamId={id} mode={PULSE_MODE.team} />
          </Grid.col>
        </Grid>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle
              subtitle={<FormattedMessage id="Squads.Pulse.SquadDynamics" />}
              actionBar={
                <DatePicker
                  name="squadDynamicDate"
                  value={squadDynamicsDate}
                  onChange={handleDateChange}
                  maxDate={new Date()}
                  format={DATE_FORMAT_TYPE.MMyyyy}
                  enableMonthPicker
                  enableDateCycle
                  showMonthYearPicker
                />
              }
            />
          </Grid.col>
          <Grid.col start={1} end={13}>
            <PulseTable dateRange={getDateRange(squadDynamicsDate)} teamId={id} mode={PULSE_MODE.profile} />
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};

const getDateRange = (date) => {
  const [year, month] = date.split("-");
  const lastDateOfMonth = new Date(year, month, 0).getDate();
  const firstDayOfMonth = `${year}-${month}-01`;
  const lastDayOfMonth = `${year}-${month}-${lastDateOfMonth}`;

  return [firstDayOfMonth, lastDayOfMonth];
};

export default Pulse;
