import { gql } from "@apollo/client";
import {
  CORE_PROFILE_FIELDS,
  DISCIPLINE_FIELDS,
  LANGUAGE_FIELDS,
  EDUCATION_FIELDS,
  INTERESTS_FIELDS,
  BADGES_FIELDS,
  ENGINEERING_LEVEL_FIELDS,
  EXTERNAL_LINKS_FIELDS,
  OLD_LOCATION_FIELDS,
  RATE_FIELDS,
  YEARLY_RATE_FIELDS,
  SKILLS_FIELDS,
  TAGS_FIELDS,
  WORK_EXPERIENCES_FIELDS,
  WORK_SCHEDULE_FIELDS,
  VACATIONS_FIELDS,
  USER_FIELDS,
} from "graphql/fragments";

const UPDATE_PROFILE = gql`
  ${CORE_PROFILE_FIELDS}
  ${DISCIPLINE_FIELDS}
  ${LANGUAGE_FIELDS}
  ${EDUCATION_FIELDS}
  ${EXTERNAL_LINKS_FIELDS}
  ${OLD_LOCATION_FIELDS}
  ${RATE_FIELDS}
  ${YEARLY_RATE_FIELDS}
  ${SKILLS_FIELDS}
  ${TAGS_FIELDS}
  ${VACATIONS_FIELDS}
  ${WORK_EXPERIENCES_FIELDS}
  ${WORK_SCHEDULE_FIELDS}
  ${USER_FIELDS}
  ${INTERESTS_FIELDS}
  ${BADGES_FIELDS}
  ${ENGINEERING_LEVEL_FIELDS}
  mutation UpdateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      clientMutationId
      profile {
        ...ProfileFields
        ...DisciplineFields
        ...LanguageFields
        ...EducationFields
        ...ExternalLinksFields
        ...OldLocationFields
        ...RateFields
        ...YearlyRateFields
        ...SkillsFields
        ...TagsFields
        ...VacationsFields
        ...WorkExperiencesFields
        ...WorkScheduleFields
        ...UserFields
        ...InterestsFields
        ...BadgesFields
        ...EngineeringLevelFields
      }
    }
  }
`;

const UPLOAD_AVATAR = gql`
  mutation UpdateProfile($input: SetProfileAvatarInput!) {
    setProfileAvatar(input: $input) {
      clientMutationId
      profile {
        id
        avatarUrl
      }
    }
  }
`;

const UPLOAD_RESUME = gql`
  mutation setProfileResume($input: SetProfileResumeInput!) {
    setProfileResume(input: $input) {
      profile {
        id
        resumeUrl
      }
    }
  }
`;

const REMOVE_PROFILE_EDUCATION = gql`
  mutation RemoveProfileEducation($input: RemoveProfileEducationInput!) {
    removeProfileEducation(input: $input) {
      id
    }
  }
`;

const UPDATE_PROFILE_EDUCATION = gql`
  mutation UpdateProfileEducation($input: UpdateProfileEducationInput!) {
    updateProfileEducation(input: $input) {
      profileEducation {
        id
        schoolName
        degreeName
        fieldOfStudy
        startDate
        endDate
      }
    }
  }
`;

const ADD_PROFILE_EDUCATION = gql`
  mutation AddProfileEducation($input: AddProfileEducationInput!) {
    addProfileEducation(input: $input) {
      profileEducation {
        id
        schoolName
        degreeName
        fieldOfStudy
        startDate
        endDate
      }
    }
  }
`;

const REMOVE_WORK_EXPERIENCES = gql`
  mutation RemoveProfileWorkExperience($input: RemoveProfileWorkExperienceInput!) {
    removeProfileWorkExperience(input: $input) {
      id
    }
  }
`;

const UPDATE_WORK_EXPERIENCES = gql`
  mutation UpdateProfileWorkExperience($input: UpdateProfileWorkExperienceInput!) {
    updateProfileWorkExperience(input: $input) {
      profileWorkExperience {
        id
        companyName
        description
        descriptionJson
        role
        startDate
        endDate
        skills {
          id
        }
        industries {
          id
        }
        currentJob
      }
    }
  }
`;

const ADD_WORK_EXPERIENCES = gql`
  mutation AddProfileWorkExperience($input: AddProfileWorkExperienceInput!) {
    addProfileWorkExperience(input: $input) {
      profileWorkExperience {
        id
        companyName
        description
        role
        startDate
        endDate
        currentJob
      }
    }
  }
`;

const REMOVE_PROFILE_VACATION = gql`
  mutation RemoveProfileVacation($input: RemoveProfileVacationInput!) {
    removeProfileVacation(input: $input) {
      id
    }
  }
`;

const UPDATE_PROFILE_VACATION = gql`
  mutation UpdateProfileVacation($input: UpdateProfileVacationInput!) {
    updateProfileVacation(input: $input) {
      profileVacation {
        id
        startDate
        endDate
      }
    }
  }
`;

const ADD_PROFILE_VACATION = gql`
  mutation AddProfileVacation($input: AddProfileVacationInput!) {
    addProfileVacation(input: $input) {
      profileVacation {
        id
        startDate
        endDate
      }
    }
  }
`;

const SUBMIT_PROFILE = gql`
  mutation submitProfile($input: SubmitProfileInput!) {
    submitProfile(input: $input) {
      profile {
        id
        status
        submittedAt
      }
    }
  }
`;

const APPROVE_PROFILE = gql`
  mutation approveProfile($input: ApproveProfileInput!) {
    approveProfile(input: $input) {
      profile {
        id
        status
        memberSince
        eligibleForVetting
        approvedBy {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

const REJECT_PROFILE = gql`
  mutation rejectProfile($input: RejectProfileInput!) {
    rejectProfile(input: $input) {
      profile {
        id
        status
        memberSince
        eligibleForVetting
        eligibleForVetting
        rejectedDescription
        rejectedReason
        rejectedAt
        rejectedBy {
          id
          email
        }
      }
    }
  }
`;

const RESTORE_ARCHIVED_PROFILE = gql`
  mutation restoreArchivedProfile($input: RestoreArchivedProfileInput!) {
    restoreArchivedProfile(input: $input) {
      profile {
        id
      }
    }
  }
`;

export {
  UPDATE_PROFILE,
  UPLOAD_AVATAR,
  UPLOAD_RESUME,
  REMOVE_PROFILE_EDUCATION,
  UPDATE_PROFILE_EDUCATION,
  ADD_PROFILE_EDUCATION,
  REMOVE_WORK_EXPERIENCES,
  UPDATE_WORK_EXPERIENCES,
  ADD_WORK_EXPERIENCES,
  ADD_PROFILE_VACATION,
  UPDATE_PROFILE_VACATION,
  REMOVE_PROFILE_VACATION,
  SUBMIT_PROFILE,
  APPROVE_PROFILE,
  REJECT_PROFILE,
  RESTORE_ARCHIVED_PROFILE,
};
