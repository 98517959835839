import React from "react";
import PropTypes from "prop-types";
import Modal, { MODAL_SIZE } from "components/Modal";
import CodeChallengesList from "./CodeChallengesList";

/**
 * CodeChallengesPicker
 *
 * @param {Function} onItemClick
 * @param {Function} onSelect
 * @param {Boolean}  show
 * @param {Function} onClose
 * @param {Boolean}  selectable
 * @param {Boolean}  showSave
 */
const CodeChallengesPicker = ({ show, showSave, onClose, onItemClick, onSelect, selectable, ...props }) => {
  return (
    <Modal
      show={show}
      onClose={onClose}
      showSave={showSave}
      secondaryButtonMessageId="Global.Close"
      size={MODAL_SIZE.xlarge}
      showCancel
      {...props}
    >
      <CodeChallengesList
        onItemClick={onItemClick}
        onSelect={onSelect}
        allowedFilters={["search"]}
        selectable={selectable}
        disableRowOptions
      />
    </Modal>
  );
};

CodeChallengesPicker.defaultProps = {
  show: false,
  selectable: false,
  showSave: false,
};

CodeChallengesPicker.propTypes = {
  onItemClick: PropTypes.func,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  selectable: PropTypes.bool,
  showSave: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default CodeChallengesPicker;
