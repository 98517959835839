import { gql } from "@apollo/client";

const ADD_TEAM = gql`
  mutation AddTeam($input: AddTeamInput!) {
    addTeam(input: $input) {
      team {
        id
        name
        slug
        status
        teamVersionType
        versionStatus
        teamType
      }
    }
  }
`;

const REMOVE_TEAM = gql`
  mutation RemoveTeam($input: RemoveTeamInput!) {
    removeTeam(input: $input) {
      team {
        id
        name
        slug
        status
        teamVersionType
        versionStatus
        teamType
      }
    }
  }
`;

const RESTORE_TEAM = gql`
  mutation RestoreTeam($input: RestoreTeamInput!) {
    restoreTeam(input: $input) {
      team {
        id
        name
        slug
        status
        teamVersionType
        versionStatus
        teamType
      }
    }
  }
`;

export { ADD_TEAM, REMOVE_TEAM, RESTORE_TEAM };
