import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import DataTable from "components/DataTable";
import { useAuth } from "hooks";
import { formatCurrency } from "utils";
import Icon, { ICON_TYPE, ICON_SIZE } from "components/Icon";
import { ARCHETYPES_SYMBOLS, TERM_SHEET_TYPES } from "constants/index";
import { ProfileLink } from "components/Profile";

/**
 * RecommendedSquadDetails
 *
 * @params {Boolean}  collapse
 * @params {Array}    squad
 * @params {Boolean}  loading
 * @params {Function} handleToggle
 */
const RecommendedSquadDetails = ({ collapse, squad, loading, handleToggle }) => {
  const { isAuthenticated } = useAuth();
  return (
    <>
      {collapse && (
        <DataTable
          data={squad?.assignments}
          loading={loading}
          columns={COLUMN_DATA(TERM_SHEET_TYPES[squad?.type], isAuthenticated)}
        />
      )}
      <div css={styles.collapse_button} onClick={() => handleToggle(!collapse)}>
        <Icon type={collapse ? ICON_TYPE.chevronUp : ICON_TYPE.chevronDown} size={ICON_SIZE.medium} color="inherit" />
      </div>
    </>
  );
};

const styles = {
  collapse_button: css`
    display: flex;
    margin-top: 1rem;
    justify-content: right;
    padding-right: 1rem;
    cursor: pointer;
  `,
};

const COLUMN_DATA = (type, isAuthenticated) => [
  {
    label: <FormattedMessage id="Squads.RecommendedSquads.Name.Header" />,
    cell: (row) => (
      <ProfileLink
        withPhoto
        photoProps={{ width: "2rem", height: "2rem", borderRadius: "100%" }}
        profile={row?.profile}
        linkPublic={!isAuthenticated}
        withOverlay
      />
    ),
    width: 90,
  },
  {
    label: <FormattedMessage id="Squads.RecommendedSquads.Role.Header" />,
    cell: (row) => ARCHETYPES_SYMBOLS[row?.role.toLocaleLowerCase()],
    width: 40,
  },
  {
    label: (
      <FormattedMessage
        id="Squads.RecommendedSquads.Hours.Header"
        values={{
          type: <FormattedMessage id={`Global.${type === "week" ? "Weekly" : "Monthly"}`} />,
        }}
      />
    ),
    cell: (row) => row?.hours,
    width: 50,
  },
  {
    label: (
      <FormattedMessage
        id="Squads.RecommendedSquads.Rate.Header"
        values={{
          type: <FormattedMessage id={`Global.${type === "week" ? "Weekly" : "Monthly"}`} />,
        }}
      />
    ),
    cell: (row) => formatCurrency(row?.rate, row?.currency),
    width: 50,
  },
];

RecommendedSquadDetails.propTypes = {
  collapse: PropTypes.bool,
  squad: PropTypes.object,
  loading: PropTypes.bool,
  handleToggle: PropTypes.func,
};

export default RecommendedSquadDetails;
