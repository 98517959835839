import React from "react";
import { css } from "@emotion/react";
import { colors } from "style";

const withAdminStyles = (Component) => {
  const WrappedComponent = (props) => {
    const adminStyles = css`
      color: ${colors.peach} !important;

      * {
        color: ${colors.peach} !important;
      }
    `;

    return <Component {...props} css={adminStyles} />;
  };

  WrappedComponent.displayName = `WithAdminStyles(${Component.displayName || Component.name || "Component"})`;

  return WrappedComponent;
};

export default withAdminStyles;
