import React from "react";
import PropTypes from "prop-types";
import Modal, { MODAL_SIZE } from "components/Modal";
import { CodeChallengeDetails } from "components/CodeChallenges";

/**
 * CodeChallengeDetailsModal
 *
 * @param {Object} challengeResponse
 * @param {Boolean} show
 * @param {Function} onClose
 */
const CodeChallengeDetailsModal = ({ challengeResponse, show, onClose, ...props }) => (
  <Modal
    show={show}
    showSave={false}
    showCancel={true}
    onClose={onClose}
    size={MODAL_SIZE.medium}
    secondaryButtonMessageId="Global.Close"
    title={challengeResponse.challenge.name}
    {...props}
  >
    <CodeChallengeDetails challengeResponse={challengeResponse} />
  </Modal>
);

CodeChallengeDetailsModal.propTypes = {
  challengeResponse: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CodeChallengeDetailsModal;
