import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { colors, fontSize } from "style";
import PasswordRules from "./PasswordRules";
import { breakpoints } from "style";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";

// const e = React.createElement;

/**
 * TextInput
 * FIXME: There's an issue with dom warnings saying "unknown props" because `hasIcon` (for example)
 * will get attached in the dom. This is a known issue.
 * Reference: https://github.com/emotion-js/emotion/issues/183
 * The fix is to destructure like this:
 *   const TextInput = styled(({ hasIcon, hasText, isInvisible, hasError, formData, children, ...props }) =>
       e("input", props, children)
     )`
 * This blockes the ability to use `ref` in the styled component so the fix had to be removed.
 */
// eslint-disable-next-line no-unused-vars
const TextInput = styled.input`
  display: block;
  font-size: ${fontSize.xsmall};
  background: #fff;
  font-weight: normal;
  height: 4rem;
  padding-left: ${(props) => (props.hasIcon ? `3.4rem` : `1rem`)};
  padding-right: 1.4rem;
  line-height: 2.4rem;
  border-radius: ${(props) => (props.hasText ? `0 0.6rem 0.6rem 0` : `0.6rem`)};
  border: 1px solid ${colors.grayAnatomyLight3};
  width: 100%;
  color: ${colors.secondary};
  order: 1;
  resize: none;
  appearance: none;
  background-clip: padding-box;
  outline: none;
  transition: all 0.3s ease;

  ${(props) =>
    props.isInvisible &&
    `
    border-color: transparent;
  `}

  ${(props) => props.hasButton && `padding-right:6rem;`}

  ${(props) =>
    props.hasText &&
    `
    border-width:0 !important;
    height:auto;
  `};

  &::placeholder {
    color: ${colors.grayAnatomyLight2};
  }

  &:focus {
    border-color: ${colors.purpleRainBase};
    box-shadow: 0px 0px 0px 3px ${colors.purpleRainLight3};

    & + label {
      color: ${colors.purpleRainBase};
    }
  }

  &:hover:not(:disabled) {
    border-color: ${colors.purpleRainBase};
  }

  &:disabled {
    border: 1px solid ${colors.grayAnatomyLight3};
    color: ${colors.grayAnatomyLight2};
    background-color: ${colors.grayAnatomyLight4};

    &:placeholder {
      color: ${colors.grayAnatomyLigitght1};
    }
  }
`;

/**
 * Regular TextInput
 *
 * @param {Function}   onChange
 * @param {String}     name
 * @param {Boolean}    withButton
 * @param {String}     value
 */
const TextInputWithOnChange = forwardRef(({ onChange, name, withButton, value: valueProp, ...props }, ref) => {
  const [value, setValue] = useState(valueProp);
  const [hasChanges, setHasChanges] = useState(false);
  const inputRef = useRef(null);

  useImperativeHandle(ref, () => ({ handleBlur }),[inputRef]);

  const handleBlur = () => {
    if (inputRef.current) {
      inputRef.current.blur();
    } 
  }

  const handleChange = (e) => {
    if (onChange) {
      onChange(name, e.target.value.length === 1 ? e.target.value.trim() : e.target.value);
    }
  };

  const handleStateChange = (e) => {
    if (onChange) {
      setValue(e.target.value);
      setHasChanges(true);
    }
  };

  const handleButton = () => {
    onChange(name, value.length === 1 ? value.trim() : value);
    setHasChanges(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (withButton) {
        onChange(name, value?.length === 1 ? value?.trim() : value);
        setHasChanges(false);
      }
    }
  };

  if (withButton) {
    return (
      <div css={styles.input_container}>
        <TextInput
          ref={inputRef}
          {...props}
          name={name}
          value={value}
          onChange={handleStateChange}
          onKeyPress={handleKeyPress}
          hasButton
        />
        {/**
         * This can't be a real button as it will cause issues with the form submission
         */}
        <div css={styles.button(!value || !hasChanges)} onClick={handleButton}>
          <Icon type={ICON_TYPE.arrowRight} size={ICON_SIZE.large} color="inherit" />
        </div>
      </div>
    );
  } else {
    return (
      <TextInput
        ref={inputRef}
        {...props}
        value={valueProp}
        name={name}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />
    );
  }
});

/**
 * Password Input (shows Rules on right-side)
 *
 * @params {String}     value
 * @params {Function}   onChange
 */
const Password = ({ value, onChange, ...props }) => {
  const [password, setPassword] = useState(value);
  const [isFocused, setFocus] = useState(false);

  const handleChange = (e) => {
    if (onChange) {
      setPassword(e.target.value);
      onChange(e.target.name, e.target.value);
    }
  };

  return (
    <div css={styles.password_container}>
      <TextInput
        {...props}
        value={value}
        onChange={handleChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      <div css={styles.rules_container(isFocused)}>
        <PasswordRules password={password} />
      </div>
    </div>
  );
};

const styles = {
  input_container: css`
    width: 100%;
    position: relative;
    order: 1;
  `,
  button: (disabled) => css`
    position: absolute;
    right: 1px;
    top: 1px;
    bottom: 1px;
    min-height: 0;
    padding-left: 0;
    padding-right: 0;
    width: 3.8rem;
    height: calc(4rem - 2px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: ${colors.purpleRainBase};
    color: #fff;
    border: solid 2px ${colors.purpleRainBase};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
    text-decoration: none;

    ${disabled &&
    `    
      color: ${colors.grayAnatomyLight2};
      background: ${colors.grayAnatomyLight4};
      border-color: ${colors.grayAnatomyLight4};
      box-shadown: none;
      font-weight: 500;
    `}
  `,
  input: css`
    padding-right: 5rem;
  `,
  password_container: css`
    position: relative;
    order: 1;
  `,
  rules_container: (isFocused) => css`
    ${!isFocused && `display:none;`}
    position: absolute;
    left: 100%;
    margin-left: 2rem;
    top: -50%;
    padding: 1.5rem;
    border: 1px solid ${colors.grayAnatomyLight3};
    border-radius: 1rem;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    z-index: 10;
    background: ${colors.white};

    @media (max-width: ${breakpoints.portrait}) {
      bottom: 100%;
      top: auto;
      right: 0;
      left: auto;
      margin-bottom: 3rem;
      margin-left: 0;
    }
  `,
};

TextInputWithOnChange.propTypes = {
  onChange: PropTypes.func,
  inputRef: PropTypes.object,
  name: PropTypes.string,
  withButton: PropTypes.bool,
  value: PropTypes.string,
};

Password.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export { TextInput, Password };

export default TextInputWithOnChange;
