import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";

/**
 * Show a generic error message
 *
 * @params {Element}  children
 * @params {String}   width
 */
const FormFieldError = ({ children, width }) => (
  <div data-error css={style(width)}>
    {children}
  </div>
);

const style = (width) => css`
  font-size: ${fontSize.xxsmall};
  color: ${colors.red};
  font-weight: 400;
  order: 2;
  position: absolute;
  bottom: -1.75rem;
  width: ${width};

  &:nth-of-type(1) {
    margin-top: 0.8rem;
  }
`;

FormFieldError.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string.isRequired,
};

export default FormFieldError;
