import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { useInterviewResponses, useProfile, useAuth } from "hooks";
import { FormattedMessage } from "react-intl";
import { BANNER_TYPE, INTERVIEW_STATUSES, PROFILE_PREFERENCES } from "constants/index";
import { Grid } from "components/Containers";
import { MessagesNoResults } from "components/Messages";
import Card from "components/Card";
import { colors, fontSize } from "style";
import { TextLoader } from "components/Loader";
import { shortenText } from "utils";
import { Banner } from "components/Banner";
import { CandidateInterview } from "components/Candidate";

/**
 * ProfileVideoInterviewList
 *
 * @param {String}     profileId
 * @param {Function}   selectedInterview
 * @param {Function}   setInterviews
 */
const ProfileVideoInterviewList = ({ profileId, selectedInterview, setInterviews }) => {
  const { isMember } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const { data, loading } = useInterviewResponses({
    filters: { profileId, status: INTERVIEW_STATUSES.completed },
  });
  const { data: profileData, loading: profileDataLoading } = useProfile({ profileId });

  const canShowInterviews = profileData?.profile?.canShowInterviews;
  const interviewToDisplay = selectedInterview || data?.find((interview) => !interview.deletedAt);

  useEffect(() => {
    setIsLoading(loading || profileDataLoading);
  }, [loading, profileDataLoading]);

  useEffect(() => {
    setInterviews(data);
  }, [data]);

  return (
    <>
      {isLoading && (
        <StyledGrid cols={2} rowGap="3rem" gap="3rem">
          {[...Array(4).keys()].map((item) => (
            <Grid.col key={item}>
              <VideoCard loading={true} />
            </Grid.col>
          ))}
        </StyledGrid>
      )}
      {!isLoading &&
        canShowInterviews &&
        (interviewToDisplay ? (
          <StyledGrid cols={2} rowGap="3rem" gap="3rem">
            {interviewToDisplay.answers?.map((answer, index) => (
              <Grid.col key={`${index}`}>
                <VideoCard {...answer} />
              </Grid.col>
            ))}
          </StyledGrid>
        ) : (
          <>
            {isMember && data?.length === 0 ? (
              <Grid.col start={1} end={13}>
                <Card>
                  <CandidateInterview profileId={profileId} />
                </Card>
              </Grid.col>
            ) : (
              <Card noPadding>
                <MessagesNoResults />
              </Card>
            )}
          </>
        ))}
      {!isLoading && !canShowInterviews && (
        <Card>
          <Banner type={BANNER_TYPE.warning} isClosable={false}>
            <FormattedMessage
              id={`Profile.ProfilePreferences.${PROFILE_PREFERENCES.showVideoInterview}.NotAvailable`}
            />
          </Banner>
        </Card>
      )}
    </>
  );
};

/**
 * VideoCard
 *
 * @param {String} title
 * @param {String} description
 * @param {String} video_url
 * @param {String} thumbnail_url
 * @param {Boolean} loading
 */
const VideoCard = ({ title, description, video_url, thumbnail_url, loading }) => {
  const [showMore, setShowMore] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [video_url]);

  return (
    <StyledCard>
      <div css={styles.title}>{loading ? <TextLoader width="60%" height="1rem" /> : title}</div>
      <div css={styles.video_container(loading)}>
        {!loading && (
          <Video ref={videoRef} controls={true} poster={thumbnail_url}>
            <source type="video/mp4" src={video_url} />
          </Video>
        )}
      </div>
      <div css={styles.description} onClick={() => setShowMore(!showMore)}>
        {loading ? (
          <>
            <TextLoader width="100%" height="1rem" />
            <TextLoader width="100%" height="1rem" />
            <TextLoader width="60%" height="1rem" />
          </>
        ) : showMore ? (
          description
        ) : (
          shortenText(description, 180)
        )}
      </div>
    </StyledCard>
  );
};

const styles = {
  title: css`
    font-size: ${fontSize.normal};
    font-weight: 500;
    padding: 0rem 3rem;
  `,
  description: css`
    font-size: ${fontSize.small};
    font-weight: 500;
    color: ${colors.grayAnatomyBase};
    padding: 0rem 3rem;
    line-height: 1.2;
    cursor: pointer;
  `,
  video_container: (loading) => css`
    width: 100%;
    background: ${colors.grayAnatomyLight5};
    ${loading && `padding-top:75%;`}
  `,
};

const Video = styled.video`
  width: 100%;
`;

const StyledGrid = styled(Grid)``;

const StyledCard = styled(Card)`
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0;
`;

VideoCard.propTypes = {
  title: PropTypes.string,
  video_url: PropTypes.string,
  thumbnail_url: PropTypes.string,
  description: PropTypes.string,
  loading: PropTypes.bool,
};

ProfileVideoInterviewList.defaultProps = {
  setInterviews: () => {},
};

ProfileVideoInterviewList.propTypes = {
  profileId: PropTypes.string,
  setInterviews: PropTypes.func,
  selectedInterview: PropTypes.object,
};

export default ProfileVideoInterviewList;
