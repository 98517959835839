import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";
import { PrimaryLink } from "components/Links";
import { UserPhoto, UserArchetype } from "components/User";
import { useProfile } from "hooks";
import { shortenText } from "utils";
import { LINKS } from "constants/index";
import Tags from "components/Tags";
import { TextLoader } from "components/Loader";
import { ProfileLink } from "components/Profile";

/**
 * ProfileSummary
 *
 * @param {String}   id
 */
const ProfileSummary = ({ id }) => {
  const { data, loading } = useProfile({ profileId: id });

  if (loading) {
    return <PlaceHolder />;
  }

  return (
    <div css={styles.container}>
      <UserPhoto data={data?.profile} loading={loading} width={`12rem`} height={`12rem`} borderRadius="100%" />
      <div css={styles.name}>
        <ProfileLink profile={data?.profile} loading={loading} withOverlay />
      </div>
      <div css={styles.archetype}>
        <UserArchetype data={data?.profile} />
      </div>
      <div css={styles.disciplines}>
        <Tags data={data?.profile?.disciplines} color={colors.purpleRainBase} />
      </div>
      <div css={styles.bio}>{shortenText(data?.profile?.description, 100)}</div>
      <div css={styles.link}>
        <PrimaryLink to={LINKS.private_profile(data?.profile?.slug)} withMoreIcon>
          <FormattedMessage id="Profile.ProfileSummary.CTA" />
        </PrimaryLink>
      </div>
    </div>
  );
};

const PlaceHolder = () => (
  <div css={styles.container}>
    <UserPhoto loading={true} width={`12rem`} height={`12rem`} borderRadius="100%" />
    <div css={styles.name}>
      <TextLoader
        css={css`
          width: 25rem;
        `}
      />
    </div>
    <div css={styles.archetype}>
      <TextLoader
        css={css`
          width: 15rem;
        `}
      />
    </div>
    <div css={styles.bio}>
      <TextLoader
        css={css`
          width: 35rem;
        `}
      />
      <TextLoader
        css={css`
          width: 25rem;
        `}
      />
      <TextLoader
        css={css`
          width: 10rem;
        `}
      />
    </div>
  </div>
);

const styles = {
  container: css`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 3rem;
  `,
  name: css`
    font-size: ${fontSize.medium};
    margin-top: 1rem;
    font-weight: 600;
    max-width: 80%;
    text-align: center;
  `,
  archetype: css`
    font-size: ${fontSize.small};
    margin-top: 0.5rem;
    color: ${colors.grayAnatomyLight1};
    max-width: 80%;
    text-align: center;
  `,
  disciplines: css`
    margin-top: 1rem;

    > * {
      justify-content: center;
    }
  `,
  bio: css`
    font-size: ${fontSize.small};
    margin-top: 1.5rem;
    line-height: 1.3;
    font-weight: 300;
    max-width: 80%;
    text-align: center;
  `,
  link: css`
    font-size: ${fontSize.small};
    margin-top: 1.5rem;
  `,
};

ProfileSummary.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ProfileSummary;
