import { gql } from "@apollo/client";

const SET_AS_CURRENT_INTERVIEW_RESPONSE = gql`
  mutation setAsCurrentInterviewResponse($input: SetAsCurrentInterviewResponseInput!) {
    setAsCurrentInterviewResponse(input: $input) {
      id
    }
  }
`;

export { SET_AS_CURRENT_INTERVIEW_RESPONSE };
