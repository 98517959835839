import React from "react";
import { FormattedMessage } from "react-intl";
import DisplayBox, { RENDER_SIZE, RenderIconWithText } from "components/DisplayBox";
import { useApplicants } from "hooks";
import { LINKS } from "constants/index";
import { UserPhoto } from "components/User";
import { APPLICANT_TYPES } from "constants/index";
import { PrimaryLink } from "components/Links";
import { formatDate } from "utils";
import { margins } from "style";
import { PAGINATION_TYPE } from "components/Pagination";
import { RoleLink } from "components/Missions/Roles";
import { ProfileLink } from "components/Profile";

/**
 * RecentApplicants
 */
const RecentApplicants = (props) => {
  const { loading, data } = useApplicants({
    resultsPerPage: 10,
    applicantState: APPLICANT_TYPES.applied,
    paginationType: PAGINATION_TYPE.cursor,
  });
  const isEmpty = !loading && (!data || data?.length === 0);

  return (
    <>
      <DisplayBox
        id="events"
        title={<FormattedMessage id="Mission.Applicants.RecentApplicants.Title" />}
        emptyMsg={<FormattedMessage id="Mission.Contacts.NoData" />}
        loading={loading}
        isEmpty={isEmpty}
        subtitle={
          <PrimaryLink to={LINKS.mission_applicants} routerEnabled={true} state={{ tab: 1 }}>
            <FormattedMessage id="Mission.Applicants.RecentApplicants.ViewMoreLink" />
          </PrimaryLink>
        }
        numColumns={1}
        marginSize={margins.normal}
        data={data}
        itemRenderer={(eventData) => (
          <RenderIconWithText
            size={RENDER_SIZE.small}
            hasLink={false}
            key={eventData.id}
            icon={
              <UserPhoto
                width={RENDER_SIZE.small}
                height={RENDER_SIZE.small}
                data={eventData?.profile}
                borderRadius="100%"
              />
            }
            text={{
              title: <ProfileLink profile={eventData?.profile} withOverlay />,
              description: (
                <FormattedMessage
                  id="Mission.Applicants.RecentApplicants.Description"
                  values={{
                    role: <RoleLink mission={eventData?.mission} missionRole={eventData?.missionRole} withOverlay />,
                    date: formatDate(eventData?.appliedAt),
                  }}
                />
              ),
            }}
            loading={loading}
          />
        )}
        {...props}
      />
    </>
  );
};

export default RecentApplicants;
