import React, { Suspense, lazy } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { useAuth } from "hooks";
import { RENDER_SIZE, RenderIconWithText } from "components/DisplayBox";
import { LINKS } from "constants/index";
import { UserPhoto, UserArchetype, UserLocation } from "components/User";
import { Col, Row } from "components/Containers";
import { colors } from "style";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { formatCurrency, formatNumber } from "utils";
import { PrimaryLink } from "components/Links";
import { Link } from "components/Links";

// TODO - Using conventional import like above causes an error and the app to crash.
// Lazy loading the ProfileLink component fixes the issue. Investigate why this is happening. - Asad
const ProfileLink = lazy(() => import("components/Profile/ProfileLink"));

/**
 * RenderMemberList
 *
 * @param {Array}     data
 * @param {Object}    team
 * @param {Boolean}   loading
 * @param {Boolean}   hideArchetype
 * @param {Boolean}   hideAvailability
 * @param {Boolean}   hideTime
 * @param {Boolean}   hideRate
 * @param {Boolean}   hidePerformance
 * @param {Boolean}   disableLink
 * @param {Function}  onClick
 * @param {String}    userHandle
 */
const RenderMemberList = ({
  data,
  team,
  loading,
  hideArchetype,
  hideAvailability,
  hideTime,
  hideRate,
  hidePerformance,
  disableLink,
  onClick,
  userHandle,
  ...props
}) => {
  const { isAdmin } = useAuth();
  const isProfileOverlayEnabled = isAdmin || disableLink;
  const {
    isOnline,
    nextAvailability,
    currentTime,
    archetype,
    countryFlag,
    location,
    oldLocation,
    averageCapacity,
    sellingCurrency,
    sellingAmount,
    termSheetType,
    uuid,
  } = data;

  const handleClick = () => {
    if (disableLink) return;
    else if (onClick) {
      return onClick(data);
    } else {
      window.open(LINKS.public_profile(uuid, "_blank"));
    }
  };

  return (
    <RenderIconWithText
      size={RENDER_SIZE.large}
      hasLink={!disableLink}
      onClick={isProfileOverlayEnabled ? () => {} : handleClick}
      icon={
        <UserPhoto
          width={RENDER_SIZE.large}
          height={RENDER_SIZE.large}
          data={loading ? {} : data}
          borderRadius="100%"
          showStatus={nextAvailability || nextAvailability === 0}
        />
      }
      text={{
        title: (
          <Row gap={`0.5rem`}>
            <Suspense fallback={<div />}>
              <ProfileLink profile={loading ? {} : data} withOverlay={true} />
            </Suspense>
            <span>{countryFlag || location?.country_flag || oldLocation?.countryFlag}</span>
          </Row>
        ),
        description: (
          <div>
            <Col gap="0.5rem" css={styles.text_container}>
              {!hideArchetype && (
                <div css={styles.archetype}>
                  <UserArchetype data={{ archetype }} />
                </div>
              )}
              {userHandle && (
                <Link to={`${LINKS.github_base_url}/${userHandle}`} target="_blank">
                  @{userHandle}
                </Link>
              )}
              {(location || oldLocation) && <UserLocation data={{ location, oldLocation }} hideFlag />}
              {!hideAvailability && (
                <div>
                  {(nextAvailability || nextAvailability === 0) && (
                    <div css={styles.next_availability(isOnline)}>
                      <Row gap="0.5rem">
                        <Icon type={ICON_TYPE.clock} size={ICON_SIZE.normal} color="inherit" />
                        <FormattedMessage
                          id={`Missions.MissionContacts.Status${nextAvailability > 23 ? `Days` : `Hours`}`}
                          values={{
                            currentTime,
                            hours: nextAvailability,
                            days: Math.floor(nextAvailability / 24),
                            type: isOnline ? `Offline` : `Online`,
                          }}
                        />
                        {currentTime && !hideTime && (
                          <div css={styles.local}>
                            <FormattedMessage
                              id="Missions.MissionContacts.Local"
                              values={{
                                time: String(currentTime).toLowerCase(),
                              }}
                            />
                          </div>
                        )}
                      </Row>
                    </div>
                  )}
                </div>
              )}
            </Col>
          </div>
        ),
        details: (
          <>
            <Col gap={"0.25rem"}>
              {!hideRate && (
                <>
                  <StyledRow gap="0.5rem">
                    <Icon type={ICON_TYPE.hourglass} size={ICON_SIZE.normal} color="inherit" />
                    <FormattedMessage
                      id="DisplayBox.Renderers.RenderMemberList.Capacity"
                      values={{
                        capacity: formatNumber(averageCapacity),
                        type: <FormattedMessage id={`DisplayBox.Renderers.RenderMemberList.${termSheetType}`} />,
                      }}
                    />
                  </StyledRow>
                  <StyledRow gap="0.5rem">
                    <Icon type={ICON_TYPE.coin} size={ICON_SIZE.normal} color="inherit" />
                    <FormattedMessage
                      id={sellingAmount ? "Profile.ProfileRateAndAvailability.DataRate" : "Global.NotProvided"}
                      values={{
                        amount: formatCurrency(sellingAmount, sellingCurrency),
                        type: <FormattedMessage id={`DisplayBox.Renderers.RenderMemberList.${termSheetType}`} />,
                      }}
                    />
                  </StyledRow>
                </>
              )}
              {!hidePerformance && (
                <StyledRow gap="0.5rem">
                  <Icon type={ICON_TYPE.analytics} size={ICON_SIZE.normal} color="inherit" />
                  <PrimaryLink
                    to={LINKS.squad_engineering_code_telemetry_individual(team?.slug, uuid)}
                    withMoreIcon
                    routerEnabled
                  >
                    View performance
                  </PrimaryLink>
                </StyledRow>
              )}
            </Col>
          </>
        ),
      }}
      loading={loading}
      {...props}
    />
  );
};

const styles = {
  text_container: css`
    line-height: normal;
  `,
  next_availability: (isOnline) => css`
    color: ${isOnline ? colors.green : colors.warning};
    display: inline-block;
    font-weight: 500;
  `,
  archetype: css`
    color: ${colors.purpleRainDark2};
  `,
  local: css`
    color: ${colors.grayAnatomyLight1};
    font-weight: 400;
  `,
};

const StyledRow = styled(Row)`
  flex-wrap: nowrap;
  color: ${colors.grayAnatomyBase};
`;

RenderMemberList.defaultProps = {
  hideRate: true,
  hidePerformance: true,
  data: {},
};

RenderMemberList.propTypes = {
  data: PropTypes.array,
  team: PropTypes.object,
  loading: PropTypes.bool,
  title: PropTypes.any,
  hasNextPage: PropTypes.bool,
  handleNextPage: PropTypes.func,
  hideArchetype: PropTypes.bool,
  disableLink: PropTypes.bool,
  hideAvailability: PropTypes.bool,
  hideTime: PropTypes.bool,
  hideRate: PropTypes.bool,
  hidePerformance: PropTypes.bool,
  onClick: PropTypes.func,
  userHandle: PropTypes.string,
};

export default RenderMemberList;
