import React, { useState, useEffect } from "react";
import { MUTATIONS, QUERIES, KEYS } from "graphql/mappings/index";
import { TYPE, SIZE, TYPE_KEYNAMES } from "components/Form";
import { useAuth, useQueryData } from "hooks";
import { RADIO_DIRECTION } from "components/Form/Radio";
import { PrimaryLink } from "components/Links";

/**
 * useFormData
 *
 * @param {Object}  queryData
 */
export default function useFormData(queryData) {
  const [data, setData] = useState([]);
  const [parentIds, setParentIds] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const { data: apiData, refetch, loading } = useQueryData(queryData);
  const { isAdmin } = useAuth();

  useEffect(() => {
    if (apiData) {
      const { fieldsKey, valuesKey, labelKey } = apiData[queryData.keyName];
      const formFields = parseFormFields(apiData[queryData.keyName][fieldsKey], labelKey);
      setData([{ items: formFields }]);
      setInitialValues(apiData[queryData.keyName][valuesKey]);
    }
  }, [apiData, parentIds]);

  const parseFormFields = (fields, labelKey) => {
    const parsedFields = new Array();

    fields.map((item) => {
      const isLabelInline = item.properties.keepLabelInline;
      const header = item.properties.header;
      if (header) {
        let staticLabel = {
          type: TYPE.label,
          label: getLabel(header),
          size: SIZE.xlarge,
        };
        parsedFields.push(staticLabel);
      }
      if (isLabelInline) {
        let staticLabel = {
          type: TYPE.label,
          label: getLabel(item[labelKey], item?.properties?.answerablePath),
          size: SIZE.small,
        };
        parsedFields.push(staticLabel);
      }
      parsedFields.push({
        ...parseField(item, labelKey, isLabelInline && SIZE.large),
        ...(isLabelInline && { label: null }),
      });
      if (isAdmin && item.allowAddMore) {
        let staticChild = {
          parentId: item.id,
          type: TYPE_KEYNAMES.createNewInstance,
          properties: {
            ...item.properties,
            ...STATIC_CHILD_PROPERTIES[TYPE_KEYNAMES.createNewInstance][item.properties.mutationKey]?.(TYPE.input),
          },
        };
        parsedFields.push(parseField(staticChild, labelKey));
      }
      previewableChildren(item).map((child) => {
        parsedFields.push(parseField(child, labelKey));
      });
    });

    return parsedFields;
  };

  const parseField = (item, labelKey, size = SIZE.xlarge) => ({
    type: TYPE[item.type],
    label: getLabel(item[labelKey]),
    size,
    properties: {
      ...item.properties,
      ...(item.options?.length && { options: item.options }),
      ...(item.type === TYPE_KEYNAMES.radio && { direction: RADIO_DIRECTION.horizontal }),
      ...(item.type === TYPE_KEYNAMES.textarea && {
        height: SIZE.medium,
        placeholder: "Add details here...",
        maxLength: "1000",
      }),
      ...(item.type === TYPE_KEYNAMES.select && { isClearable: true }),
      ...(item.type === TYPE_KEYNAMES.tags && { minLength: 1 }),
      ...(item.type === TYPE_KEYNAMES.createNewInstance && { variables: { id: item.parentId }, refetch }),
      ...(item.type === TYPE_KEYNAMES.createNewInstance && {
        variables: { id: item.parentId, assessmentId: apiData[queryData.keyName].id },
        refetch,
      }),
      ...(item.type === TYPE_KEYNAMES.checkBoxGroup && {
        mutationData: {
          mutationName: { delete: MUTATIONS.removeAssessmentOption },
          refetchQueries: [queryData.queryName],
          refetchAfterMutate: true,
        },
      }),
      // for child which is dependent on parent should have a filter of parent as foreign key
      // and it should be re-rendered everytime parent gets changed
      ...(item.properties.queryKey === "teamVersions" && {
        parentId: parentIds["teamId"],
        searchQuery: (search) => ({ teamId: parentIds["teamId"], filters: { search, status: ["DRAFT"] } }),
      }),
      ...(item.properties.queryKey === "teams" && {
        queryVariables: {
          filters: { status: TEAM_STATUSES },
        },
      }),
      // for parents having dependent children should have this method on value change
      ...(dependentsCount(item) && { reloadData }),
      queryKeyName: item.properties.queryKey,
      queryName: QUERIES[item.properties.queryKey] || MUTATIONS[item.properties.mutationKey],
    },
  });

  // pull out the count of child whose display should depend on the parent value
  const dependentsCount = (item) => item?.children?.filter((c) => c.properties.parentDependent).length;

  // check if the component needs to be re-rendered on parent value changed
  const isNewValue = (key, value) => value != parentIds[key];

  // filter previewable children
  const previewableChildren = (parent) =>
    parent.children.filter((c) => !c.properties.parentDependent || parentIds[parentKey(parent.properties.queryKey)]);

  // check if parent value got change then reload the data for children
  const reloadData = (id, type) =>
    isNewValue(parentKey(type), id) && setParentIds({ ...parentIds, [parentKey(type)]: id });

  // add support of fromatting the label i.e hyperlinking based on the properties
  const getLabel = (text, link = null) =>
    link ? (
      <PrimaryLink to={link} target="_blank">
        {text}
      </PrimaryLink>
    ) : (
      text
    );

  const parentKey = (type) => KEYS[type];

  return {
    data,
    initialValues,
    loading,
  };
}

const TEAM_STATUSES = ["ACTIVE", "REQUIREMENTS", "ASSEMBLING", "SIGNOFF", "DRAFT", "ONBOARDING"];

// We could place properties of other static child here
// And even other elements could use the createNewInstance
// by placing a new key inside createNewInstance
const STATIC_CHILD_PROPERTIES = {
  createNewInstance: {
    addAssessmentOption: (type) => ({
      labelKey: "Profile.CreateNewRequirement",
      placeholder: "Type requirement...",
      keyName: "value",
      fields: [
        {
          type,
          name: "value",
          properties: {
            required: true,
          },
        },
      ],
    }),
  },
};
