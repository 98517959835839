/**
 * calculatePercentage - Calculate the percentage of a value in relation to a total
 *
 * @param {Number} value
 * @param {Number} total
 *
 * @returns {String} The percentage
 */
export default function calculatePercentage(value, total, decimalPlaces = 1) {
  if (typeof value !== "number" || typeof total !== "number") {
    // Should it throw an error instead?
    return "Invalid input";
  }

  if (total === 0) {
    return 0;
  }

  const percentage = (value / total) * 100;
  return percentage.toFixed(decimalPlaces);
}
