import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { DataTableProvider } from "components/DataTable";
import { PAGINATION_TYPE } from "components/Pagination";
import { useAuth } from "hooks";
import Status from "components/Status";
import { Col, Row } from "components/Containers";
import { CAPACITY_STATUS, LINKS } from "constants/index";
import { UserArchetype, UserBadges, UserCapacity, UserFullName, UserLocation, UserPhoto } from "components/User";
import { PreferredRateTag, ProfileCard, ProfileModal, ProfileRateRange } from "components/Profile";
import Tags from "components/Tags";
import { Grid } from "components/Containers";
import Card from "components/Card";
import { colors, fontSize, margins } from "style";
import { TAG_COLORS, TAG_SIZE } from "components/Tags/Tag";
import { formatDate } from "utils";
import { CardList } from "components/Card";
import { ListNote } from "components/Lists";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";

/**
 * NetworkList
 *
 * @param {Array}     data
 * @param {Array}     actions
 * @param {Array}     columnData
 * @param {Array}     profileModalActions
 * @param {String}    resultsPerPage
 * @param {Function}  handlePage
 * @param {Function}  handlePageChange
 * @param {Number}    totalCount
 * @param {Number}    currentPage
 * @param {Boolean}   hasNextPage
 * @param {Boolean}   hasPreviousPage
 * @param {Boolean}   loadingMore
 * @param {Boolean}   isCardView
 * @param {String}    marginSize
 * @param {Function}  onItemClick
 * @param {Object}    error
 * @param {Boolean}   loading
 * @param {String}    paginationType
 * @param {Boolean}   forceDisplayRate
 */
const NetworkList = ({
  data,
  actions,
  columnData,
  profileModalActions,
  resultsPerPage,
  handleNextPage,
  handlePreviousPage,
  handlePageChange,
  totalCount,
  currentPage,
  hasNextPage,
  hasPreviousPage,
  loadingMore,
  isCardView,
  marginSize,
  onItemClick,
  error,
  loading,
  paginationType,
  forceDisplayRate,
  ...props
}) => {
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [selectedProfileData, setSelectedProfileData] = useState({});
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showProfileModalTab, setShowProfileModalTab] = useState(0);
  const { isPartner, isAdmin, isShowcase, isClient } = useAuth();

  const handleRowClick = (data, showProfileDetails) => {
    if (onItemClick) {
      return onItemClick(data);
    } else {
      setSelectedProfileId(data?.uuid);
      setSelectedProfileData(data);
      setShowProfileModal(true);
      setShowProfileModalTab(showProfileDetails ? 0 : 1);
    }
  };

  const handleOnNameClick = (data) => {
    setSelectedProfileId(data?.slug);
    setSelectedProfileData(data);
    setShowProfileModal(true);
  };

  const paginationProps = {
    type: paginationType ? paginationType : PAGINATION_TYPE.classic,
    onNextPage: handleNextPage,
    onPreviousPage: handlePreviousPage,
    hasNextPage: !loading && hasNextPage,
    hasPreviousPage: !loading && hasPreviousPage,
    countMessage: "Global.Profiles.Count",
    loading,
    resultsPerPage,
    handlePageChange,
    totalCount,
    currentPage,
    rightContainer: (isShowcase || isClient) && `2,683 members` /* Static - for display purposes for now */,
  };

  return (
    <>
      {isCardView && (
        <CardList
          loading={loading || loadingMore}
          data={data}
          paginate={true}
          resultsPerPage={resultsPerPage}
          paginationProps={paginationProps}
          renderer={ProfileCard}
          rendererProps={{
            loading: false,
            onClick: handleRowClick,
            hideRate: forceDisplayRate ? false : !isShowcase,
            actions,
          }}
        />
      )}
      {!isCardView && (
        <Card>
          <Grid>
            <Grid.col start={1} end={13}>
              <DataTableProvider
                data={data}
                error={error}
                loading={loading || loadingMore}
                resultsPerPage={resultsPerPage}
                columns={columnData(handleOnNameClick, isAdmin, isPartner)}
                marginSize={marginSize}
                onClick={(row) => handleRowClick(row, true)}
                selectable={false}
                paginationProps={paginationProps}
                hideHeader
                {...props}
              />
            </Grid.col>
          </Grid>
        </Card>
      )}
      {selectedProfileId && (
        <ProfileModal
          id={selectedProfileId}
          show={showProfileModal}
          handleClose={() => setShowProfileModal(false)}
          info={profileModalActions?.map((Component, index) => (
            <Component key={index} {...selectedProfileData} />
          ))}
          initialTab={showProfileModalTab}
          handlePrimary={() => {
            if (isAdmin || isPartner) {
              window.open(LINKS.private_profile(selectedProfileData?.slug), "_blank");
            } else {
              setShowProfileModal(false);
              window.open(LINKS.public_profile(selectedProfileId), "_blank");
            }
          }}
        />
      )}
    </>
  );
};

const COLUMN_DATA = (onNameClick, isAdmin, isPartner) => {
  const defaultColumns = [
    {
      width: "65%",
      cell: (row) => (
        <>
          <StyledRow>
            <StyledCol gap="0.5rem">
              <UserPhoto width="5rem" height="5rem" borderRadius="100%" data={row} showStatus showInterviews />
            </StyledCol>
            <ContentCol gap="0.5rem">
              <Row>
                <Title>
                  <UserFullName data={row} disableLink={true} onClick={onNameClick} />
                </Title>
                <UserBadges badges={row?.badges} size="1.75rem" limit={6} />
                <Status enabled={row?.capacityStatus === CAPACITY_STATUS.available}>
                  <UserCapacity data={row} />
                </Status>
              </Row>
              <Archetype>
                <UserArchetype data={row} />
              </Archetype>
              <UserLocation data={row} />
              <TagContainer gap="0.25rem">
                <Tags data={row?.disciplines} limit={6} unwrap {...TAG_COLORS.purple} size={TAG_SIZE.small} />
                <Tags data={row?.skills} limit={6} unwrap {...TAG_COLORS.pink} size={TAG_SIZE.small} />
              </TagContainer>
              <InfoCol>
                {row?.createdAt && (
                  <Row gap="0.5rem">
                    <Icon type={ICON_TYPE.calendar} size={ICON_SIZE.large} color={colors.grayAnatomyBase} />
                    <FormattedMessage
                      id="Network.NetworkList.CreationDate"
                      values={{
                        date: formatDate(row.createdAt),
                      }}
                    />
                  </Row>
                )}
                <ListNote note={row?.profileList?.note} />
              </InfoCol>
            </ContentCol>
          </StyledRow>
        </>
      ),
    },
    {
      width: "22%",
      cell: (row) => {
        return (
          <>
            {isPartner ? (
              <PreferredRateTag rate={row?.rate} color={TAG_COLORS.purple} size={TAG_SIZE.small} />
            ) : (
              <ProfileRateRange
                includesCommission={isAdmin && row.partner}
                low={row?.sellingPriceEstimate?.hourly?.low}
                high={row?.sellingPriceEstimate?.hourly?.high}
                currency={row?.sellingPriceEstimate?.currency}
                negotiatedRate={row?.profileList?.negotiatedRate}
                negotiatedRateCurrency={row?.profileList?.negotiatedRateCurrency}
                assignedSellingRate={isAdmin && row?.assignedSellingRate}
              />
            )}
          </>
        );
      },
    },
  ];

  return defaultColumns;
};

const InfoCol = styled(Col)`
  gap: 0.5rem;
  color: ${colors.grayAnatomyBase};
  padding-top: 0.5rem;
  flex-wrap: nowrap;
  align-items: flex-start;
  font-size: ${fontSize.xsmall};
`;

const StyledCol = styled(Col)`
  margin-bottom: auto;
  align-items: center;
  margin-right: 1rem;
  width: 5rem;
`;

const ContentCol = styled(Col)`
  width: 100%;
`;

const StyledRow = styled(Row)`
  padding: 1rem 0 1rem 0;
  flex-wrap: nowrap;
`;

const Title = styled.span`
  font-weight: 600;
  font-size: ${fontSize.small};
  color: ${colors.purpleRainBase};
  margin-bottom: -0.25rem;
`;

const Archetype = styled.span`
  font-weight: 500;
  margin-top: -0.5rem;
`;

const TagContainer = styled(Row)`
  margin-left: -0.75rem;
  margin-top: 0.25rem;
`;

NetworkList.defaultProps = {
  marginSize: margins.normal,
  columnData: COLUMN_DATA,
  forceDisplayRate: false,
};

NetworkList.propTypes = {
  data: PropTypes.array,
  actions: PropTypes.array,
  columnData: PropTypes.func,
  profileModalActions: PropTypes.array,
  resultsPerPage: PropTypes.number,
  totalCount: PropTypes.number,
  currentPage: PropTypes.number,
  handlePageChange: PropTypes.func,
  handleNextPage: PropTypes.func,
  handlePreviousPage: PropTypes.func,
  hasNextPage: PropTypes.bool,
  hasPreviousPage: PropTypes.bool,
  loadingMore: PropTypes.bool,
  isCardView: PropTypes.bool,
  marginSize: PropTypes.string,
  error: PropTypes.object,
  loading: PropTypes.bool,
  handlePage: PropTypes.func,
  onItemClick: PropTypes.func,
  paginationType: PropTypes.string,
  forceDisplayRate: PropTypes.bool,
};

export default NetworkList;
