import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { LINKS, SQUAD_METRIC_TYPES } from "constants/index";
import { H3 } from "components/Text";
import { Header } from "components/DisplayBox";
import { MetricsCard } from "components/Dashboard";
import { useTeamMetricsScores } from "hooks";
import Tooltip from "components/Tooltip";
import { Row } from "components/Containers";
import { margins } from "style";

/**
 * SquadMetrics
 *
 * @param {String}   teamId
 * @param {String}   teamSlug
 * @param {Boolean}  loading
 * @param {Boolean}  hideHeader
 * @param {Boolean}  showAll
 */
const SquadMetrics = ({ teamId, teamSlug, loading, hideHeader, showAll, ...props }) => {
  const { data: teamMetricsScores, loading: teamMetricsScoresLoading } = useTeamMetricsScores({ teamId });
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(loading || teamMetricsScoresLoading);
  }, [loading, teamMetricsScoresLoading]);

  const navigateToTelemetry = (category) => {
    navigate(`${LINKS.squad_engineering_code_telemetry(teamSlug)}#${category}`);
  };

  return (
    <>
      {!hideHeader && (
        <Header marginSize={margins.normal} marginBottom={margins.none}>
          <H3>
            <Tooltip tooltipId={"Squads.Squad.Profile.ListTable.Details.MetricsTooltip"}>
              <FormattedMessage id="Squads.Squad.Profile.ListTable.Details.Metrics" />
            </Tooltip>
          </H3>
        </Header>
      )}
      <Row gap={`0rem`} align="flex-start" {...props}>
        {isLoading && (
          <>
            <MetricsCard loading={isLoading} />
            <MetricsCard loading={isLoading} />
            <MetricsCard loading={isLoading} />
          </>
        )}
        {!isLoading && (
          <MetricsCard
            key={SQUAD_METRIC_TYPES.activity}
            category={SQUAD_METRIC_TYPES.activity}
            decimalsUpto={0}
            onClick={() => navigateToTelemetry("activity")}
            {...teamMetricsScores?.performanceActivity}
          />
        )}
        {!isLoading && (
          <MetricsCard
            key={SQUAD_METRIC_TYPES.volume}
            category={SQUAD_METRIC_TYPES.volume}
            decimalsUpto={0}
            onClick={() => navigateToTelemetry("volume")}
            {...teamMetricsScores?.performanceVolume}
          />
        )}
        {!isLoading && (
          <MetricsCard
            key={SQUAD_METRIC_TYPES.efficiency}
            category={SQUAD_METRIC_TYPES.efficiency}
            decimalsUpto={0}
            onClick={() => navigateToTelemetry("efficiency")}
            {...teamMetricsScores?.performanceEfficiency}
          />
        )}
      </Row>
      {!loading && showAll && (
        <Row gap={`0rem`} align="flex-start" {...props}>
          {isLoading && (
            <>
              <MetricsCard loading={isLoading} />
              <MetricsCard loading={isLoading} />
              <MetricsCard loading={isLoading} />
            </>
          )}
          {!isLoading && (
            <MetricsCard
              key={SQUAD_METRIC_TYPES.pulse}
              category={SQUAD_METRIC_TYPES.pulse}
              decimalsUpto={0}
              onClick={() => navigate(LINKS.squad_pulse(teamSlug))}
              {...teamMetricsScores?.pulse}
            />
          )}
          {!isLoading && (
            <MetricsCard
              key={SQUAD_METRIC_TYPES.teamDynamics}
              category={SQUAD_METRIC_TYPES.teamDynamics}
              decimalsUpto={0}
              {...teamMetricsScores?.dynamics}
            />
          )}
          {!isLoading && (
            <MetricsCard
              key={SQUAD_METRIC_TYPES.clientSatisfaction}
              category={SQUAD_METRIC_TYPES.clientSatisfaction}
              decimalsUpto={0}
              {...teamMetricsScores?.clientSatisfaction}
            />
          )}
        </Row>
      )}
    </>
  );
};

SquadMetrics.defaultProps = {
  hideHeader: false,
};

SquadMetrics.propTypes = {
  teamId: PropTypes.string.isRequired,
  teamSlug: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  hideHeader: PropTypes.bool,
  showAll: PropTypes.bool,
};

export default SquadMetrics;
