/**
 * convertToPercent
 *
 * @param {String}    val
 *
 * @returns {String} The formatted string
 */
export default function convertToPercent(val) {
  return val ? `${(Number(val) * 100).toFixed()}%` : "—";
}
