import { gql } from "@apollo/client";
import { APPLICANT_RATE_FIELDS, MINIMAL_PROFILE_FIELDS, MINIMAL_MISSION_ROLE_FIELDS } from "graphql/fragments";

const GET_MISSION_ROLE_APPLICANTS = gql`
  ${MINIMAL_PROFILE_FIELDS}
  ${APPLICANT_RATE_FIELDS}
  ${MINIMAL_MISSION_ROLE_FIELDS}
  query missionRoleApplicants(
    $filters: MissionRoleApplicantFilter
    $first: Int
    $last: Int
    $after: String
    $before: String
    $orderBy: MissionRoleApplicantOrderBy
    $paginationInfo: PaginationInfo
    $includeSellingPriceEstimates: Boolean = false
  ) {
    missionRoleApplicants(
      filters: $filters
      first: $first
      last: $last
      after: $after
      before: $before
      orderBy: $orderBy
      paginationInfo: $paginationInfo
    ) {
      totalCount
      shortlistedCount
      appliedCount
      interviewingCount
      selectedCount
      invitedCount
      currentPage
      perPage
      nodes {
        id
        state
        rateScore
        matchScore
        subScores
        appliedAt
        noticePeriod
        profile {
          ...ProfileFields
          sellingPriceEstimate @include(if: $includeSellingPriceEstimates)
        }
        missionRole {
          ...MissionRoleFields
        }
        ...ApplicantRateFields
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

const GET_MISSION_ROLE_INVITATIONS = gql`
  ${MINIMAL_PROFILE_FIELDS}
  ${MINIMAL_MISSION_ROLE_FIELDS}
  query missionRoleInvitations(
    $filters: MissionRoleInvitationFilter
    $first: Int
    $last: Int
    $after: String
    $before: String
    $paginationInfo: PaginationInfo
  ) {
    missionRoleInvitations(
      filters: $filters
      first: $first
      last: $last
      after: $after
      before: $before
      paginationInfo: $paginationInfo
    ) {
      totalCount
      shortlistedCount
      appliedCount
      interviewingCount
      selectedCount
      invitedCount
      currentPage
      perPage
      nodes {
        id
        rateScore
        matchScore
        subScores
        invitedAt
        acceptedAt
        invitedBy {
          name
        }
        profile {
          ...ProfileFields
        }
        missionRole {
          ...MissionRoleFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

const GET_MISSION_ROLE_ELIGIBILITY = gql`
  query missionApplicantEligibilityCheck($profileId: ID!, $missionRoleId: ID!) {
    missionApplicantEligibilityCheck(profileId: $profileId, missionRoleId: $missionRoleId) {
      eligible
      unfulfilledPolicies
    }
  }
`;

const GET_MISSION_ROLE_RECOMMENDATION = gql`
  query missionRoleApplicantRecommendation($profileId: ID, $missionRoleId: ID!) {
    missionRoleApplicantRecommendation(profileId: $profileId, missionRoleId: $missionRoleId) {
      recommendedRate
      recommendedRateCurrency
      averageExperience
      applicantsCount
    }
  }
`;

const GET_MISSION_ROLE_RECOMMENDATIONS = gql`
  ${MINIMAL_PROFILE_FIELDS}
  ${MINIMAL_MISSION_ROLE_FIELDS}
  query missionRoleRecommendations(
    $filters: MissionRoleRecommendationFilter!
    $first: Int
    $last: Int
    $after: String
    $before: String
    $paginationInfo: PaginationInfo
  ) {
    missionRoleRecommendations(
      filters: $filters
      first: $first
      last: $last
      after: $after
      before: $before
      paginationInfo: $paginationInfo
    ) {
      totalCount
      currentPage
      perPage
      nodes {
        rateScore
        matchScore
        subScores
        profile {
          ...ProfileFields
        }
        missionRole {
          ...MissionRoleFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          profile {
            id
          }
        }
      }
    }
  }
`;

export {
  GET_MISSION_ROLE_APPLICANTS,
  GET_MISSION_ROLE_INVITATIONS,
  GET_MISSION_ROLE_RECOMMENDATIONS,
  GET_MISSION_ROLE_ELIGIBILITY,
  GET_MISSION_ROLE_RECOMMENDATION,
};
