import React from "react";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { PAGINATION_TYPE } from "components/Pagination";
import { GET_COMMENTS } from "graphql/queries";
import { ADD_COMMENT, REMOVE_COMMENT, UPDATE_COMMENT } from "graphql/mutations";
import { useQueryData, useToast } from "hooks";
import { DIRECTION } from "constants/index";
import { getPlainText } from "components/Form/Editor";

/**
 * useComments
 *
 * @param {String}  commentableId
 * @param {String}  commentableType
 * @param {Number}  resultsPerPage
 * @param {String}  createdAt
 */
export default function useComments({
  commentableId,
  commentableType,
  resultsPerPage = 10,
  createdAt = DIRECTION.descending,
}) {
  const { toast } = useToast();
  const [addNote, { loading: adding }] = useMutation(ADD_COMMENT);
  const [updateNote, { loading: updating }] = useMutation(UPDATE_COMMENT);
  const [removeComment, { loading: deleting }] = useMutation(REMOVE_COMMENT);

  const defaults = {
    orderBy: { createdAt },
    first: resultsPerPage,
    last: null,
    after: null,
  };

  const { hasNextPage, handleNextPage, loading, loadingMore, data, error, refetch } = useQueryData({
    queryName: GET_COMMENTS,
    keyName: "comments",
    resultsPerPage,
    paginationType: PAGINATION_TYPE.append,
    skip: !commentableId || !commentableType,
    variables: {
      ...defaults,
      commentableId,
      commentableType,
    },
  });

  /**
   * Add a note
   *
   * @param {String}    commentableId
   * @param {String}    commentableType
   * @param {Object}    messageJson
   * @param {Function}  callback
   */
  const handleAddComment = (commentableId, commentableType, messageJson, callback) => {
    addNote({
      variables: {
        input: {
          params: {
            message: getPlainText(messageJson),
            messageJson,
            commentableId,
            commentableType,
          },
        },
      },
      update: (
        cache,
        {
          data: {
            addComment: { comment },
          },
        }
      ) => {
        const cachedData = cache.readQuery({
          query: GET_COMMENTS,
          variables: {
            ...defaults,
            commentableId,
            commentableType,
          },
        });
        cache.writeQuery({
          query: GET_COMMENTS,
          data: {
            comments: {
              ...cachedData.comments,
              nodes:
                createdAt === DIRECTION.descending
                  ? [comment, ...cachedData.comments.nodes]
                  : [...cachedData.comments.nodes, comment],
            },
          },
          variables: {
            ...defaults,
            commentableId,
            commentableType,
          },
        });
      },
      onCompleted: () => {
        if (typeof callback === "function") {
          callback();
        }
        toast.success(<FormattedMessage id={`${commentableType}.useComments.AddSuccess`} />);
      },
      onError: () => {
        toast.error(<FormattedMessage id={`${commentableType}.useComments.AddError`} />);
      },
    });
  };

  /**
   * Delete comment
   *
   * @param {String}    id
   * @param {Boolean}   commentableType
   */
  const handleDeleteComment = (id, commentableType) => {
    removeComment({
      variables: {
        input: { id },
      },
      update: (cache) => {
        const normalizedId = cache.identify({ id, __typename: "Comment" });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
      onCompleted: () => toast.success(<FormattedMessage id={`${commentableType}.useComments.DeleteSuccess`} />),
      onError: () => toast.error(<FormattedMessage id={`${commentableType}.useComments.DeleteError`} />),
    });
  };

  /**
   *
   * @param {Object}    value
   * @param {String}    id
   * @param {Function}  callback
   * @param {Boolean}   commentableType
   */
  const handleUpdateComment = (value, id, callback, commentableType) => {
    updateNote({
      variables: {
        input: {
          id,
          params: {
            message: getPlainText(value),
            messageJson: value,
          },
        },
      },
      onCompleted: () => {
        if (typeof callback === "function") {
          callback();
        }
        toast.success(<FormattedMessage id={`${commentableType}.useComments.EditSuccess`} />);
      },
      onError: () => {
        toast.error(<FormattedMessage id={`${commentableType}.useComments.EditError`} />);
      },
    });
  };

  /**
   *
   * @param {Object}    value
   * @param {String}    id
   * @param {Function}  callback
   */
  const handleUpdateTags = (topics, id, callback) => {
    updateNote({
      variables: {
        input: {
          id,
          params: {
            topics,
          },
        },
      },
      onCompleted: () => {
        if (typeof callback === "function") {
          callback();
        }
        toast.success(<FormattedMessage id={"Profile.useComments.EditSuccess"} />);
      },
      onError: () => toast.error(<FormattedMessage id={"Profile.useComments.EditError"} />),
    });
  };

  return {
    hasNextPage,
    handleNextPage,
    loading,
    loadingMore,
    adding,
    updating,
    deleting,
    refetch,
    handleAddComment,
    handleDeleteComment,
    handleUpdateComment,
    handleUpdateTags,
    data: data?.comments?.nodes,
    error,
  };
}
