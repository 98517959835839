/**
 * Extracts variables from a stored graphql cache key
 * Format must be: 'teams({"filters": {"search": "test"}})'
 *
 * @param {String} storedCacheKey
 *
 * @returns {Object} The extracted variables
 */
export default function extractVariablesFromCacheKey(storedCacheKey) {
  const variables = storedCacheKey.match(/\((.*)\)$/)[1];
  return JSON.parse(variables);
}
