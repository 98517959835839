import React from "react";
import { DECORATORS, SIZE, TYPE } from "components/Form";
import { TOGGLE_SIZE } from "components/Form/Toggle";
import { OperatorToggle } from "components/Search";
import { ICON_POSITION, ICON_SIZE, ICON_TYPE } from "components/Icon";

const GLOBAL_VALUE_TYPES = {
  categoricalValues: "categoricalValues",
  flagValues: "flagValues",
  numericValues: "numericValues",
  dateValues: "dateValues",
};

const OPERATORS = {
  or: "or",
  and: "and",
};

/**
 * Exhaustive list of all form options that can be used to filter network results
 * `options` is updated dynamically based on data in FilterMenu.js
 *
 * @param {Function}  operatorOnChange    Callback for operator onChange
 * @param {Object}    operators           Object of operator values
 */
const GLOBAL_FORM_FILTERS = (operatorOnChange, operators) => ({
  status: {
    id: "status",
    label: "Status",
    filterMenuLabel: "Status",
    type: TYPE.select,
    size: SIZE.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.categoricalValues,
    transformValues: (value) => [value],
    metaDataKey: "status",
    order: 2,
    properties: {
      name: "status",
      minLength: 0,
      placeholder: "Select Status",
      valueKey: "value",
      labelKey: "value",
      defaultValue: "Any",
    },
  },
  is_open_to_employment: {
    id: "is_open_to_employment",
    label: "Job Preference",
    filterMenuLabel: "Job Preference",
    type: TYPE.select,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.flagValues,
    transformValues: (value) => {
      switch (value) {
        case 1:
          return null;
        case 2:
          return [true];
        case 3:
          return [false];
      }
    },
    metaDataKey: "is_open_to_employment",
    order: 5,
    properties: {
      name: "is_open_to_employment",
      defaultValue: 1,
      options: [
        { label: "Show all", value: 1 },
        { label: "Interested in full-time employment", value: 2 },
        { label: "Not interested in full-time employment", value: 3 },
      ],
      valueKey: "value",
      labelKey: "label",
    },
  },
  selling_price_hourly_high_usd: {
    id: "selling_price_hourly_high_usd",
    label: "Max Selling Rate (USD)",
    filterMenuLabel: "Max Selling Rate (USD)",
    type: TYPE?.input,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.numericValues,
    transformValues: (value) => [0.0, parseFloat(value)],
    metaDataKey: "selling_price_hourly_high_usd",
    order: 6,
    properties: {
      name: "selling_price_hourly_high_usd",
      type: "number",
      isClearable: true,
      placeholder: "0.00",
      withButton: true,
    },
    decorator: {
      type: DECORATORS.icon,
      props: {
        type: ICON_TYPE.chevronLeft,
        size: ICON_SIZE.large,
        position: ICON_POSITION.overlay,
      },
    },
  },
  skills: {
    id: "skills",
    label: "Skills",
    filterMenuLabel: "Skills",
    type: TYPE?.tags,
    size: SIZE?.xlarge,
    hint: (
      <OperatorToggle value={operators?.skills} name="skills" size={TOGGLE_SIZE.small} onChange={operatorOnChange} />
    ),
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.categoricalValues,
    transformValues: (values) => values?.map(({ value }) => value),
    metaDataKey: "skills",
    order: 8,
    properties: {
      name: "skills",
      minLength: 0,
      valueKey: "value",
      labelKey: "value",
      defaultValue: [],
    },
  },
  disciplines: {
    id: "disciplines",
    label: "Disciplines",
    filterMenuLabel: "Disciplines",
    type: TYPE?.checkBoxGroup,
    size: SIZE?.xlarge,
    hint: (
      <OperatorToggle
        value={operators?.disciplines}
        name="disciplines"
        size={TOGGLE_SIZE.small}
        onChange={operatorOnChange}
      />
    ),
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.categoricalValues,
    transformValues: (values) => values?.map(({ value }) => value),
    metaDataKey: "disciplines",
    order: 9,
    properties: {
      name: "disciplines",
      minLength: 1,
      valueKey: "value",
      labelKey: "value",
      defaultValue: [],
      options: [],
    },
  },
  archetype: {
    id: "archetype",
    label: "Archetype",
    filterMenuLabel: "Archetype",
    type: TYPE?.checkBoxGroup,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.categoricalValues,
    transformValues: (values) => values?.map(({ value }) => value),
    metaDataKey: "archetype",
    order: 10,
    properties: {
      name: "archetype",
      minLength: 0,
      valueKey: "value",
      labelKey: "value",
      defaultValue: [],
      options: [],
    },
  },
  country: {
    id: "country",
    label: "Country",
    filterMenuLabel: "Country",
    type: TYPE?.tags,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.categoricalValues,
    transformValues: (values) => values?.map(({ value }) => value),
    metaDataKey: "country",
    order: 11,
    properties: {
      name: "country",
      minLength: 0,
      valueKey: "value",
      labelKey: "value",
      defaultValue: [],
    },
  },
  world_region: {
    id: "world_region",
    label: "Region",
    filterMenuLabel: "Region",
    type: TYPE?.checkBoxGroup,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.categoricalValues,
    transformValues: (values) => values?.map(({ value }) => value),
    metaDataKey: "world_region",
    order: 12,
    properties: {
      name: "world_region",
      minLength: 0,
      valueKey: "value",
      labelKey: "value",
      defaultValue: [],
      options: [],
    },
  },
  industries: {
    id: "industries",
    label: "Industries",
    filterMenuLabel: "Industries",
    type: TYPE?.tags,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.categoricalValues,
    transformValues: (values) => values?.map(({ value }) => value),
    metaDataKey: "industries",
    order: 13,
    properties: {
      name: "industries",
      minLength: 0,
      valueKey: "value",
      labelKey: "value",
      defaultValue: [],
    },
  },
  experience_years: {
    id: "experience_years",
    label: "Years of Experience",
    filterMenuLabel: "Years of Experience",
    type: TYPE?.minMaxInput,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.numericValues,
    transformValues: (value) => [parseInt(value.min), parseInt(value.max)],
    metaDataKey: "experience_years",
    order: 14,
    properties: {
      name: "experience_years",
      type: "number",
      // valueKey: "value",
      // labelKey: "value",
      isClearable: true,
      defaultValue: {
        min: "",
        max: "",
      },
    },
  },
  time_zone: {
    id: "time_zone",
    label: "Time Zone",
    filterMenuLabel: "Time Zone",
    type: TYPE?.select,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.categoricalValues,
    transformValues: (value) => [value],
    metaDataKey: "time_zone",
    order: 15,
    properties: {
      name: "time_zone",
      minLength: 0,
      valueKey: "value",
      labelKey: "value",
      isClearable: true,
      defaultValue: "",
    },
  },
  last_offboard_at: {
    id: "last_offboard_at",
    label: "Offboard Date",
    filterMenuLabel: "Offboard Date",
    type: TYPE?.datePicker,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.numericValues,
    transformValues: (values) => values.map((value) => Date.parse(value)),
    metaDataKey: "last_offboard_at",
    order: 16,
    properties: {
      name: "last_offboard_at",
      minLength: 0,
      valueKey: "value",
      labelKey: "value",
      enableDateRange: true,
      isClearable: true,
      defaultValue: [],
    },
  },
  last_onboard_at: {
    id: "last_onboard_at",
    label: "Onboard Date",
    filterMenuLabel: "Onboard Date",
    type: TYPE?.datePicker,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.numericValues,
    transformValues: (values) => values.map((value) => Date.parse(value)),
    metaDataKey: "last_onboard_at",
    order: 17,
    properties: {
      name: "last_onboard_at",
      minLength: 0,
      valueKey: "value",
      labelKey: "value",
      enableDateRange: true,
      isClearable: true,
      defaultValue: [],
    },
  },
  rejected_candidate_date: {
    id: "rejected_candidate_date",
    label: "Rejected Candidate Date",
    filterMenuLabel: "Rejected Candidate Date",
    type: TYPE?.datePicker,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.numericValues,
    transformValues: (values) => values.map((value) => Date.parse(value)),
    metaDataKey: "rejected_candidate_date",
    order: 18,
    properties: {
      name: "rejected_candidate_date",
      minLength: 0,
      valueKey: "value",
      labelKey: "value",
      enableDateRange: true,
      isClearable: true,
      defaultValue: [],
    },
  },
  member_date: {
    id: "member_date",
    label: "Member Date",
    filterMenuLabel: "Member Date",
    type: TYPE?.datePicker,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.numericValues,
    transformValues: (values) => values.map((value) => Date.parse(value)),
    metaDataKey: "member_date",
    order: 19,
    properties: {
      name: "member_date",
      minLength: 0,
      valueKey: "value",
      labelKey: "value",
      enableDateRange: true,
      isClearable: true,
      defaultValue: [],
    },
  },
  skill_assessments_passed: {
    id: "skill_assessments_passed",
    label: "Skills Assessment",
    filterMenuLabel: "Skills Assessment",
    type: TYPE?.select,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.categoricalValues,
    transformValues: (value) => [value],
    metaDataKey: "skill_assessments_passed",
    order: 20,
    properties: {
      name: "skill_assessments_passed",
      minLength: 1,
      valueKey: "value",
      labelKey: "value",
      isClearable: true,
      defaultValue: "",
    },
  },
  continent: {
    id: "continent",
    label: "Continent",
    filterMenuLabel: "Continent",
    type: TYPE?.tags,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.categoricalValues,
    transformValues: (values) => values?.map(({ value }) => value),
    metaDataKey: "continent",
    order: 21,
    properties: {
      name: "continent",
      minLength: 0,
      valueKey: "value",
      labelKey: "value",
      defaultValue: [],
    },
  },
  recent_skills: {
    id: "recent_skills",
    label: "Recent Skills",
    filterMenuLabel: "Recent Skills",
    type: TYPE?.tags,
    size: SIZE?.xlarge,
    hint: (
      <OperatorToggle
        value={operators?.recent_skills}
        name="recent_skills"
        size={TOGGLE_SIZE.small}
        onChange={operatorOnChange}
      />
    ),
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.categoricalValues,
    transformValues: (values) => values?.map(({ value }) => value),
    metaDataKey: "recent_skills",
    order: 22,
    properties: {
      name: "recent_skills",
      minLength: 0,
      valueKey: "value",
      labelKey: "value",
      defaultValue: [],
    },
  },
  lists: {
    id: "lists",
    label: "List",
    filterMenuLabel: "List",
    type: TYPE?.select,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.categoricalValues,
    transformValues: (value) => [value],
    metaDataKey: "lists",
    order: 23,
    properties: {
      name: "lists",
      minLength: 1,
      valueKey: "value",
      labelKey: "value",
      isClearable: true,
      defaultValue: "",
    },
  },
  partner: {
    id: "partner",
    label: "Partner",
    filterMenuLabel: "Partner",
    type: TYPE?.select,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.categoricalValues,
    transformValues: (value) => [value],
    metaDataKey: "partner",
    order: 27,
    properties: {
      name: "partner",
      minLength: 1,
      valueKey: "value",
      labelKey: "value",
      isClearable: true,
      defaultValue: "",
    },
  },
  partner_type: {
    id: "partner_type",
    label: "Partner Type",
    filterMenuLabel: "Partner Type",
    type: TYPE?.checkBoxGroup,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.categoricalValues,
    transformValues: (values) => values?.map(({ value }) => value),
    metaDataKey: "partner_type",
    order: 28,
    properties: {
      name: "partner_type",
      minLength: 0,
      valueKey: "value",
      labelKey: "value",
      defaultValue: [],
      options: [],
    },
  },
  role: {
    id: "role",
    label: "Role",
    filterMenuLabel: "Role",
    type: TYPE?.tags,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.categoricalValues,
    transformValues: (values) => values?.map(({ value }) => value),
    metaDataKey: "role",
    order: 29,
    properties: {
      name: "role",
      minLength: 0,
      valueKey: "value",
      labelKey: "value",
      defaultValue: [],
    },
  },
  acquisition_channel: {
    id: "acquisition_channel",
    label: "Acquisition Channel",
    filterMenuLabel: "Acquisition Channel",
    type: TYPE?.select,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.categoricalValues,
    transformValues: (value) => [value],
    metaDataKey: "acquisition_channel",
    order: 30,
    properties: {
      name: "acquisition_channel",
      valueKey: "value",
      labelKey: "value",
      isClearable: true,
      defaultValue: "",
    },
  },
  acquisition_source: {
    id: "acquisition_source",
    label: "Acquisition Source",
    filterMenuLabel: "Acquisition Source",
    type: TYPE?.select,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.categoricalValues,
    transformValues: (value) => [value],
    metaDataKey: "acquisition_source",
    order: 31,
    properties: {
      name: "acquisition_source",
      valueKey: "value",
      labelKey: "value",
      isClearable: true,
      defaultValue: "",
    },
  },
  active_mission_count: {
    id: "active_mission_count",
    label: "Active Missions",
    filterMenuLabel: "Active Missions",
    type: TYPE?.slider,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.numericValues,
    transformValues: (value) => [0, parseInt(value)],
    metaDataKey: "active_mission_count",
    order: 32,
    properties: {
      name: "active_mission_count",
      min: 0,
      max: 6,
      step: 1,
      value: 1,
      defaultValue: [],
    },
  },
  mission_board_application_count: {
    id: "mission_board_application_count",
    label: "Application Count",
    filterMenuLabel: "Application Count",
    type: TYPE?.slider,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.numericValues,
    transformValues: (value) => [0, parseInt(value)],
    metaDataKey: "mission_board_application_count",
    order: 33,
    properties: {
      name: "mission_board_application_count",
      min: 0,
      max: 50,
      step: 1,
      value: 1,
      defaultValue: [],
    },
  },
  mission_board_invitation_count: {
    id: "mission_board_invitation_count",
    label: "Invitation Count",
    filterMenuLabel: "Invitation Count",
    type: TYPE?.slider,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.numericValues,
    transformValues: (value) => [0, parseInt(value)],
    metaDataKey: "mission_board_invitation_count",
    order: 34,
    properties: {
      name: "mission_board_invitation_count",
      min: 0,
      max: 50,
      step: 1,
      value: 1,
      defaultValue: [],
    },
  },
  experience_job_count: {
    id: "experience_job_count",
    label: "Job Experience",
    filterMenuLabel: "Job Experience",
    type: TYPE?.slider,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.numericValues,
    transformValues: (value) => [0, parseInt(value)],
    metaDataKey: "experience_job_count",
    order: 35,
    properties: {
      name: "experience_job_count",
      min: 0,
      max: 20,
      step: 1,
      value: 1,
      defaultValue: [],
    },
  },
  active_teams: {
    id: "active_teams",
    label: "Active Teams",
    filterMenuLabel: "Active Teams",
    type: TYPE?.slider,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.numericValues,
    transformValues: (value) => [0, parseInt(value)],
    metaDataKey: "active_teams",
    order: 36,
    properties: {
      name: "active_teams",
      min: 0,
      max: 6,
      step: 1,
      value: 1,
      defaultValue: [],
    },
  },
  archive_reason: {
    id: "archive_reason",
    label: "Archive Reason",
    filterMenuLabel: "Archive Reason",
    type: TYPE?.checkBoxGroup,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.categoricalValues,
    transformValues: (values) => values?.map(({ value }) => value),
    metaDataKey: "archive_reason",
    order: 37,
    properties: {
      name: "archive_reason",
      minLength: 1,
      valueKey: "value",
      labelKey: "value",
      defaultValue: [],
      options: [],
    },
  },
  badges: {
    id: "badges",
    label: "Badges",
    filterMenuLabel: "Badges",
    type: TYPE?.tags,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.categoricalValues,
    transformValues: (values) => values?.map(({ value }) => value),
    metaDataKey: "badges",
    order: 38,
    properties: {
      name: "badges",
      minLength: 0,
      valueKey: "value",
      labelKey: "value",
      defaultValue: [],
    },
  },
  hourly_rate_cad: {
    id: "hourly_rate_cad",
    label: "Max Hourly Rate (CAD)",
    filterMenuLabel: "Max Hourly Rate (CAD)",
    type: TYPE?.slider,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.numericValues,
    transformValues: (value) => [0, parseInt(value)],
    metaDataKey: "hourly_rate_cad",
    order: 39,
    properties: {
      name: "hourly_rate_cad",
      min: 1,
      max: 400,
      step: 5,
      value: 25,
      defaultValue: [],
    },
  },
  hourly_rate_eur: {
    id: "hourly_rate_eur",
    label: "Max Hourly Rate (EUR)",
    filterMenuLabel: "Max Hourly Rate (EUR)",
    type: TYPE?.slider,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.numericValues,
    transformValues: (value) => [0, parseInt(value)],
    metaDataKey: "hourly_rate_eur",
    order: 40,
    properties: {
      name: "hourly_rate_eur",
      min: 1,
      max: 400,
      step: 5,
      value: 25,
      defaultValue: [],
    },
  },
  available_hours: {
    id: "available_hours",
    label: "Min Available Hours",
    filterMenuLabel: "Min Available Hours",
    type: TYPE?.slider,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.numericValues,
    transformValues: (value) => [parseInt(value)],
    metaDataKey: "available_hours",
    order: 41,
    properties: {
      name: "available_hours",
      min: 1,
      max: 40,
      step: 1,
      value: 25,
      defaultValue: [],
    },
  },
  languages: {
    id: "languages",
    label: "Languages",
    filterMenuLabel: "Languages",
    type: TYPE?.tags,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.categoricalValues,
    transformValues: (values) => values?.map(({ value }) => value),
    metaDataKey: "languages",
    order: 42,
    properties: {
      name: "languages",
      minLength: 0,
      valueKey: "value",
      labelKey: "value",
      defaultValue: [],
    },
  },
  interests: {
    id: "interests",
    label: "Work Interests",
    filterMenuLabel: "Work Interests",
    type: TYPE?.checkBoxGroup,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.categoricalValues,
    transformValues: (values) => values?.map(({ value }) => value),
    metaDataKey: "interests",
    order: 43,
    properties: {
      name: "interests",
      minLength: 0,
      valueKey: "value",
      labelKey: "value",
      defaultValue: [],
      options: [],
    },
  },
  profile_status: {
    id: "profile_status",
    label: "Profile Status",
    filterMenuLabel: "Profile Status",
    type: TYPE?.select,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.flagValues,
    transformValues: (value) => (value ? [true] : []),
    metaDataKey: "profile_status",
    order: 44,
    properties: {
      name: "profile_status",
      position: "horizontal",
      options: [
        { label: "Members", value: "is_member" },
        { label: "Candidates", value: "is_candidate" },
        { label: "Rejected Candidates", value: "is_rejected_candidate" },
        { label: "Kicked Out Members", value: "is_kicked_out_member" },
      ],
      valueKey: "value",
      labelKey: "label",
      isClearable: true,
      defaultValue: "",
    },
  },
  engineering_levels: {
    id: "engineering_levels",
    label: "Engineering Levels",
    filterMenuLabel: "Engineering Levels",
    type: TYPE?.checkBoxGroup,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.categoricalValues,
    transformValues: (values) => values?.map(({ value }) => value),
    metaDataKey: "engineering_levels",
    order: 46,
    properties: {
      name: "engineering_levels",
      minLength: 0,
      valueKey: "value",
      labelKey: "value",
      defaultValue: [],
      options: [],
    },
  },
  is_available: {
    id: "is_available",
    label: "Availability",
    filterMenuLabel: "Availability",
    type: TYPE.select,
    size: SIZE?.xlarge,
    operator: "or",
    valuesType: GLOBAL_VALUE_TYPES.flagValues,
    transformValues: (value) => {
      switch (value) {
        case 1:
          return null;
        case 2:
          return [true];
        case 3:
          return [false];
      }
    },
    metaDataKey: "is_available",
    order: 49,
    properties: {
      name: "is_available",
      defaultValue: 1,
      options: [
        { label: "Show all", value: 1 },
        { label: "Available only", value: 2 },
        { label: "Unavailable only", value: 3 },
      ],
      valueKey: "value",
      labelKey: "label",
    },
  },
  is_assigned: {
    id: "is_assigned",
    filterMenuLabel: "Is assigned",
    type: TYPE.checkbox,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.flagValues,
    transformValues: (value) => [value],
    metaDataKey: "is_assigned",
    order: 50,
    properties: {
      name: "is_assigned",
      label: "Is assigned",
      defaultValue: false,
    },
  },
  is_from_partner: {
    id: "is_from_partner",
    filterMenuLabel: "Is from partner",
    type: TYPE.checkbox,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.flagValues,
    transformValues: (value) => [value],
    metaDataKey: "is_from_partner",
    order: 51,
    properties: {
      name: "is_from_partner",
      label: "Is from partner",
      defaultValue: "",
    },
  },
  has_accepted_privacy_policy: {
    id: "has_accepted_privacy_policy",
    filterMenuLabel: "Has accepted privacy policy",
    type: TYPE.checkbox,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.flagValues,
    transformValues: (value) => [value],
    metaDataKey: "has_accepted_privacy_policy",
    order: 52,
    properties: {
      name: "has_accepted_privacy_policy",
      label: "Has accepted privacy",
      defaultValue: "",
    },
  },
  has_been_assigned: {
    id: "has_been_assigned",
    filterMenuLabel: "Has been assigned",
    type: TYPE.checkbox,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.flagValues,
    transformValues: (value) => [value],
    metaDataKey: "has_been_assigned",
    order: 53,
    properties: {
      name: "has_been_assigned",
      label: "Has been assigned",
      defaultValue: "",
    },
  },
  has_been_candidate: {
    id: "has_been_candidate",
    filterMenuLabel: "Has been candidate",
    type: TYPE.checkbox,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.flagValues,
    transformValues: (value) => [value],
    metaDataKey: "has_been_candidate",
    order: 54,
    properties: {
      name: "has_been_candidate",
      label: "Has been candidate",
      defaultValue: "",
    },
  },
  vetting: {
    id: "vetting",
    label: "Vetting",
    filterMenuLabel: "Vetting",
    type: TYPE.label,
    size: SIZE.xlarge,
    order: 55,
  },
  is_candidate_ready_for_review: {
    id: "is_candidate_ready_for_review",
    filterMenuLabel: "Completed candidate checklist",
    parentKey: "vetting",
    type: TYPE.checkbox,
    size: SIZE.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.flagValues,
    transformValues: (value) => [value],
    metaDataKey: "is_candidate_ready_for_review",
    order: 57,
    properties: {
      name: "is_candidate_ready_for_review",
      label: "Completed candidate checklist",
      defaultValue: "",
    },
  },
  has_passed_skill_assessment: {
    id: "has_passed_skill_assessment",
    filterMenuLabel: "Passed skill assessment",
    parentKey: "vetting",
    type: TYPE.checkbox,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.flagValues,
    transformValues: (value) => [value],
    metaDataKey: "has_passed_skill_assessment",
    order: 58,
    properties: {
      name: "has_passed_skill_assessment",
      label: "Passed skill assessment",
      defaultValue: "",
    },
  },
  is_profile_complete: {
    id: "is_profile_complete",
    filterMenuLabel: "Completed profile",
    parentKey: "vetting",
    type: TYPE.checkbox,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.flagValues,
    transformValues: (value) => [value],
    metaDataKey: "is_profile_complete",
    order: 59,
    properties: {
      name: "is_profile_complete",
      label: "Completed profile",
      defaultValue: "",
    },
  },
  has_video_interviews: {
    id: "has_video_interviews",
    filterMenuLabel: "Video Interviews",
    parentKey: "vetting",
    type: TYPE.select,
    size: SIZE?.xlarge,
    operator: OPERATORS.or,
    valuesType: GLOBAL_VALUE_TYPES.flagValues,
    transformValues: (value) => {
      switch (value) {
        case 1:
          return null;
        case 2:
          return [true];
        case 3:
          return [false];
      }
    },
    metaDataKey: "has_video_interviews",
    order: 56,
    includeHiddenFilters: [
      {
        key: "allows_sharing_video_interviews",
        operator: OPERATORS.or,
        flagValues: [true],
        shouldApplyFilter: ({ isShowcase, isClient, filters }) =>
          (isShowcase || isClient) && filters.has_video_interviews === true,
      },
    ],
    properties: {
      name: "has_video_interviews",
      defaultValue: 1,
      label: "Video Interview",
      options: [
        { label: "Show all", value: 1 },
        { label: "Has video interviews", value: 2 },
        { label: "Does not have video interviews", value: 3 },
      ],
      valueKey: "value",
      labelKey: "label",
    },
  },
});

/**
 * Allowed filters by user type
 */
const ALLOWED_FILTERS_BY_USER_TYPE = ({ isAdmin, isClient, isShowcase, isPartner, isRecruiter, isSignalClient }) => {
  if (isAdmin || isRecruiter) {
    return [
      "acquisition_channel",
      "acquisition_source",
      "active_mission_count",
      "active_teams",
      "archetype",
      "archive_reason",
      "badges",
      "country",
      "continent",
      "disciplines",
      "experience_job_count",
      "hourly_rate_usd",
      "industries",
      "languages",
      "last_offboard_at",
      "last_onboard_at",
      "lifetime_hourly_rate_average_usd",
      "lists",
      "member_date",
      "mission_board_application_count",
      "mission_board_application_customers",
      "mission_board_invitation_count",
      "mission_board_invitation_customers",
      "mission_board_invitation_inviters",
      "partner",
      "partner_type",
      "recent_skills",
      "rejected_candidate_date",
      "role",
      "engineering_levels",
      "skills",
      "skill_assessments_passed",
      "status",
      "time_zone",
      "interests",
      "world_region",
      "has_accepted_privacy_policy",
      "has_been_assigned",
      "has_been_candidate",
      "has_passed_skill_assessment",
      "is_assigned",
      "is_from_partner",
      "is_open_to_employment",
      "experience_years",
      "has_video_interviews",
      "is_available",
      "is_profile_complete",
      "is_candidate_ready_for_review",
      "vetting",
      ...[isAdmin && "selling_price_hourly_high_usd"],
    ];
  } else if (isClient || isShowcase || isSignalClient) {
    return [
      "archetype",
      "disciplines",
      "skills",
      "country",
      "world_region",
      "industries",
      "experience_years",
      "time_zone",
      "is_open_to_employment",
      "has_video_interviews",
      ...[isShowcase && "selling_price_hourly_high_usd"],
    ];
  } else if (isPartner) {
    return ["archetype", "disciplines", "skills", "country", "world_region", "has_video_interviews"];
  }
};

/**
 * List of all form options that can be used to filter network results behind the scenes in the ready to bind state
 */
const STATIC_FILTERS = {
  featured: {
    key: "badges",
    operator: OPERATORS.or,
    categoricalValues: ["Featured"],
  },
};

const GLOBAL_SEARCH = {
  id: "keyword",
  label: "Keyword",
  type: TYPE?.input,
  size: SIZE?.xlarge,
  order: 1,
  hint: (
    <span>
      <b>enter</b> to submit
    </span>
  ),
  properties: {
    name: "search",
    type: "search",
    maxLength: 100,
    required: false,
    autoFocus: true,
    placeholder: "Type keywords...",
  },
};

const MEMBER_SORT = {
  sort: {
    id: "sort",
    label: "Sort By",
    type: TYPE?.select,
    size: SIZE?.xlarge,
    order: 3,
    properties: {
      name: "sort",
      required: false,
      defaultValue: "showcase_score",
      placeholder: "Sort by",
      options: [
        { label: "Created at", value: "candidate_at" },
        { label: "Member since", value: "member_at" },
        { label: "Last name", value: "last_name" },
        { label: "Rate", value: "hourly_rate" },
        { label: "Showcase score", value: "showcase_score" },
      ],
    },
  },
  sortDirection: {
    id: "sortDirection",
    label: "Sort Direction",
    type: TYPE?.select,
    size: SIZE?.xlarge,
    order: 4,
    properties: {
      name: "sortDirection",
      defaultValue: "desc",
      required: false,
      placeholder: "Sort direction",
      options: [
        { label: "Ascending", value: "asc" },
        { label: "Descending", value: "desc" },
      ],
    },
  },
};

export { GLOBAL_SEARCH, MEMBER_SORT, GLOBAL_VALUE_TYPES, STATIC_FILTERS, ALLOWED_FILTERS_BY_USER_TYPE, OPERATORS };

export default GLOBAL_FORM_FILTERS;
