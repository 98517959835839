import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { Paragraph } from "components/Text";
import { Header } from "components/DisplayBox";
import { H3 } from "components/Text";
import { Editor } from "components/Form";

/**
 * MissionAbout
 *
 * @param {Object}   mission
 * @param {Boolean}  isLoading
 */
const MissionAbout = ({ mission, isLoading }) => (
  <>
    <Header>
      <H3>
        <FormattedMessage id="Missions.AboutMission" />
      </H3>
    </Header>
    <div css={styles.description}>
      {mission?.descriptionJson ? (
        <Editor value={mission?.descriptionJson} css={styles.body} readOnly={true} />
      ) : (
        <Paragraph data={mission?.description} isLoading={isLoading} />
      )}
    </div>
  </>
);

const styles = {
  description: css`
    line-height: normal;
  `,
  body: css`
    margin: 0;
    padding: 0;
    border: 0;
    box-shadow: none;
  `,
};

MissionAbout.propTypes = {
  mission: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default MissionAbout;
