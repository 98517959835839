import React from "react";
import PropTypes from "prop-types";
import { useProfileCodeChallenge } from "hooks";
import { Grid } from "components/Containers";
import { Spinner } from "components/Loader";
import { MessagesError, MessagesNoResults } from "components/Messages";
import Card from "components/Card";
import { CodeChallengeDetails } from "components/CodeChallenges";
import { Header } from "components/DisplayBox";
import { H3 } from "components/Text";

/**
 * ProfileCodeChallengeList
 *
 * @param {String}   profileId
 */
const ProfileCodeChallengeList = ({ profileId }) => {
  const { data, loading, error } = useProfileCodeChallenge({
    profileId,
    resultsPerPage: null,
    variables: { disablePagination: true },
  });

  return (
    <>
      <Grid>
        {(loading || error) && (
          <Grid.col start={1} end={13}>
            {loading && <Spinner />}
            {error && <MessagesError />}
          </Grid.col>
        )}
        {!data ||
          (data.length === 0 && !loading && !error && (
            <Grid.col start={1} end={13}>
              <Card>
                <MessagesNoResults />
              </Card>
            </Grid.col>
          ))}
        {data?.map((challengeResponse, index) => (
          <Grid.col key={challengeResponse.id || index} start={1} end={13}>
            <Card>
              <Header>
                <H3>{challengeResponse.challenge?.name}</H3>
                <span>{challengeResponse.type}</span>
              </Header>
              <CodeChallengeDetails challengeResponse={challengeResponse} />
            </Card>
          </Grid.col>
        ))}
      </Grid>
    </>
  );
};

ProfileCodeChallengeList.propTypes = {
  profileId: PropTypes.string,
};

export default ProfileCodeChallengeList;
