import React from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_MISSION_ROLE } from "graphql/mutations";
import { GET_MISSION_ROLE, GET_PUBLIC_MISSION_ROLE } from "graphql/queries";
import { useQueryData, useToast } from "hooks";
import { FormattedMessage } from "react-intl";

export default function useMissionRole({ id, isPublicRole, skip }) {
  const { toast } = useToast();
  const keyName = isPublicRole ? "publicMissionRole" : "missionRole";
  const {
    loading,
    data: { [keyName]: missionRole } = {},
    error,
  } = useQueryData({
    queryName: isPublicRole ? GET_PUBLIC_MISSION_ROLE : GET_MISSION_ROLE,
    keyName: keyName,
    skip: !id || skip,
    variables: { slug: id },
  });

  const [updateMissionRole, { loading: updating }] = useMutation(UPDATE_MISSION_ROLE);

  const handleUpdateMissionRole = (input) => {
    updateMissionRole({
      variables: {
        input,
      },
      onCompleted: () => toast.success(<FormattedMessage id="useMissionRole.UpdateSuccess" />),
      onError: () => toast.error(<FormattedMessage id="useMissionRole.UpdateError" />),
    });
  };

  return {
    loading,
    data: missionRole,
    updating,
    handleUpdateMissionRole,
    error,
  };
}
