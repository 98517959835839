import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Grid } from "components/Containers";
import { DataTableProvider } from "components/DataTable";
import { SquadInsightItem } from "components/Squads/Insights";
import { GET_TEAM_INSIGHTS } from "graphql/queries";

/**
 * SquadInsightsList
 *
 * @param {String}  teamId
 * @param {Object}  formValues
 * @param {Number}  resultsPerPage
 */
const SquadInsightsList = ({ teamId, formValues: { types, metrics, profileIds }, resultsPerPage, ...props }) => (
  <Grid>
    <Grid.col start={1} end={13}>
      <DataTableProvider
        queryName={GET_TEAM_INSIGHTS}
        keyName="teamInsights"
        resultsPerPage={resultsPerPage}
        columns={COLUMN_DATA}
        variables={{
          teamId,
          filters: {
            ...(types?.length > 0 && { types: types.map((type) => type.value) }),
            ...(metrics?.length > 0 && { metrics: metrics.map((metric) => metric.value) }),
            ...(profileIds?.length > 0 && { profileIds: profileIds.map((profile) => profile.id) }),
          },
        }}
        paginationProps={{
          countMessage: "Global.Squads.Insights.Count",
        }}
        {...props}
      />
    </Grid.col>
  </Grid>
);

const COLUMN_DATA = [
  {
    label: <FormattedMessage id="Squads.Insights.TableColumn.Insight" />,
    cell: (row) => <SquadInsightItem insight={row} index={Number(new Date())} />,
  },
];

SquadInsightsList.defaultProps = {
  resultsPerPage: 20,
};

SquadInsightsList.propTypes = {
  teamId: PropTypes.string.isRequired,
  formValues: PropTypes.object,
  resultsPerPage: PropTypes.number,
};

export default SquadInsightsList;
