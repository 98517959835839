import React from "react";
import { Editor, Transforms, Text } from "slate";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { colors } from "style";
import { Row } from "components/Containers";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";

/**
 * TextEditor
 *
 * @param {Object}    editor
 */
const FormattingBar = ({ editor, ...props }) => {
  const listTypes = ["numbered-list", "bulleted-list"];

  const formattingOptions = {
    bold: {
      value: "bold",
      size: ICON_SIZE.large,
      type: ICON_TYPE.bold,
      callback: () => changeMark("bold"),
    },
    italic: {
      value: "italic",
      size: ICON_SIZE.small,
      type: ICON_TYPE.italic,
      callback: () => changeMark("italic"),
    },
    underline: {
      value: "underline",
      size: ICON_SIZE.normal,
      type: ICON_TYPE.underline,
      callback: () => changeMark("underline"),
    },
    bulletedList: {
      value: "bulleted-list",
      size: ICON_SIZE.medium,
      type: ICON_TYPE.listBullet,
      callback: () => toggleBlock("bulleted-list"),
    },
    numericList: {
      value: "numbered-list",
      size: ICON_SIZE.medium,
      type: ICON_TYPE.listNumeric,
      callback: () => toggleBlock("numbered-list"),
    },
  };

  /**
   * Applies mark formatting to the selection
   *
   * @param {String} format
   */
  const changeMark = (mark) => {
    const [match] = Editor.nodes(editor, { match: (n) => n[mark] });
    Transforms.setNodes(editor, { [mark]: !match }, { match: (n) => Text.isText(n), split: true });
  };

  /**
   * Applies block formatting to the selection
   *
   * @param {String} format
   */
  const toggleBlock = (format) => {
    const isActive = isBlockActive(format);
    const isList = listTypes.includes(format);

    Transforms.unwrapNodes(editor, {
      match: (n) => listTypes.includes(n.type),
      split: true,
    });

    Transforms.setNodes(editor, {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    });

    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  };

  /**
   * Determines if the selection has the corresponding block
   *
   * @param {String} format
   * @returns {Boolean}
   */
  const isBlockActive = (format) => {
    const [match] = Editor.nodes(editor, { match: (n) => n.type === format });

    return !!match;
  };

  /**
   * Determines if the selection has the corresponding mark
   *
   * @param {String} format
   * @returns {Boolean}
   */
  const isMarkActive = (format) => {
    const marks = Editor.marks(editor);

    return marks ? marks[format] === true : false;
  };

  return (
    <StyledRow {...props}>
      {Object.keys(formattingOptions).map((mark) => (
        <ButtonIcon
          key={mark}
          onMouseDown={formattingOptions[mark]?.callback}
          className={
            isMarkActive(formattingOptions[mark]?.value) || isBlockActive(formattingOptions[mark]?.value)
              ? `active`
              : ``
          }
        >
          <Icon type={formattingOptions[mark]?.type} size={formattingOptions[mark]?.size} color="inherit" />
        </ButtonIcon>
      ))}
    </StyledRow>
  );
};

const ButtonIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.6rem;
  height: 2.6rem;
  color: ${colors.grayAnatomyBase};
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 0.6rem;

  &.active {
    background: ${colors.purpleRainBase} !important;
    color: #fff !important;
  }

  &:hover {
    background: ${colors.grayAnatomyLight3};
    color: ${colors.grayAnatomyDark};
  }
`;

const StyledRow = styled(Row)`
  border-bottom: 1px solid ${colors.grayAnatomyLight4};
  padding-bottom: 1rem;
  margin-bottom: 1rem;
`;

FormattingBar.propTypes = {
  editor: PropTypes.object,
};

FormattingBar.defaultProps = {
  editor: {},
};

export default FormattingBar;
