import { useEffect, useState } from "react";
import { useQueryData } from "hooks";
import { GET_MISSION_ROLE_RECOMMENDATION } from "graphql/queries";

/**
 * useMissionRoleRecommendation
 *
 * @param {String}  profileId
 * @param {String}  missionRoleId
 */
export default function useMissionRoleRecommendation({ profileId, missionRoleId, skip }) {
  const keyName = "missionRoleApplicantRecommendation";
  const [rate, setRate] = useState(0);
  const [currency, setCurrency] = useState();
  const [averageExperience, setAverageExperience] = useState(0);
  const [applicantsCount, setApplicantsCount] = useState(0);
  const { loading, data, error } = useQueryData({
    queryName: GET_MISSION_ROLE_RECOMMENDATION,
    keyName,
    skip: skip || !missionRoleId,
    variables: {
      profileId,
      missionRoleId,
    },
  });

  useEffect(() => {
    if (!loading && data && data[keyName]) {
      setRate(data[keyName]?.recommendedRate);
      setCurrency(data[keyName]?.recommendedRateCurrency);
      setAverageExperience(data[keyName]?.averageExperience);
      setApplicantsCount(data[keyName]?.applicantsCount);
    }
  }, [data, loading]);

  return { loading, rate, currency, averageExperience, applicantsCount, error };
}
