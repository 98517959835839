import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { useMutation } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import {
  useQueryData,
  useProfileReview,
  useProfileVetting,
  MUTATION_TYPE,
  useMutateData,
  useToast,
  useModalConfirmation,
  useAuth,
} from "hooks";
import { GET_PROFILE_COMPLETION, GET_PROFILE, GET_PUBLIC_PROFILE } from "graphql/queries";
import {
  APPROVE_PROFILE,
  RESTORE_ARCHIVED_PROFILE,
  UPDATE_PROFILE,
  SET_PREFERENCES,
  RESEND_CONFIRMATION,
} from "graphql/mutations";
import { updateProfileCompletion } from "graphql/variables";
import { isSimplifiedVetting } from "utils";

/**
 * useProfile
 *
 * @param {String}   profileId
 * @param {String}   isPublicProfile
 * @param {Boolean}  skip
 */
export default function useProfile({ profileId, isPublicProfile, skip } = {}) {
  const { toast } = useToast();
  const [isCodeChallengeFailed, setCodeChallengeFailed] = useState();
  const [isCodeChallengeComplete, setCodeChallengeComplete] = useState();
  const [vettingConfiguration, setVettingConfiguration] = useState();
  const [isInterviewComplete, setInterviewComplete] = useState();
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [simplifiedVetting, setSimplifiedVetting] = useState(false);
  const [isCodeChallengeRequired, setIsCodeChallengeRequired] = useState(false);
  const { handleSubmitForReview } = useProfileReview({ profileId });
  const { handleMoveToVetting } = useProfileVetting({ profileId });
  const { data: profileCompletionData } = useQueryData({ queryName: GET_PROFILE_COMPLETION });
  const profileCompletion = profileCompletionData?.profileCompletion;
  const { show } = useModalConfirmation();
  const { canViewEditRate } = useAuth();
  const [handleUpdateProfile] = useMutation(UPDATE_PROFILE);
  const [handleSetPreferences] = useMutation(SET_PREFERENCES);
  const [handleResendConfirmation] = useMutation(RESEND_CONFIRMATION);

  const { data, loading, error } = useQueryData({
    queryName: isPublicProfile ? GET_PUBLIC_PROFILE : GET_PROFILE,
    keyName: isPublicProfile ? "publicProfile" : "profile",
    variables: {
      id: profileId,
    },
    skip: !profileId || skip,
  });

  const { handleAdd, loading: approving } = useMutateData(
    {
      mutationName: {
        add: APPROVE_PROFILE,
      },
      unWrapParams: true,
    },
    MUTATION_TYPE.add
  );

  const { handleAdd: handleRestoreProfile } = useMutateData(
    {
      mutationName: {
        add: RESTORE_ARCHIVED_PROFILE,
      },
      refetchAfterMutate: true,
      refetchQueries: [GET_PROFILE],
      unWrapParams: true,
    },
    MUTATION_TYPE.add
  );

  const handleApprove = () =>
    handleAdd({
      params: {
        input: {
          id: profileId,
        },
      },
      onCompleted: () => toast.success(<FormattedMessage id="useProfile.ApproveSuccess" />),
      onError: (error) => toast.error(error.message),
    });

  const handleRestore = async () => {
    const response = await show(
      <FormattedMessage id={"Profile.CandidateRestore.Title"} />,
      <>
        <FormattedMessage id={"Profile.CandidateRestore.Label"} values={{ name: data?.profile?.name }} />
        <div css={styles.restoreDescription}>
          <FormattedMessage id={"Profile.CandidateRestore.Description"} />
        </div>
      </>,
      {
        primaryButtonMessageId: "Global.Confirm",
      }
    );

    if (response) {
      handleRestoreProfile({
        params: {
          input: { profileId },
        },
        onCompleted: () => {
          toast.success(<FormattedMessage id={"Profile.CandidateRestore.Success"} />);
        },
        onError: () => {
          toast.error(<FormattedMessage id={"Profile.CandidateRestore.Error"} />);
        },
      });
    }
  };

  const updateProfile = (id, params) => {
    handleUpdateProfile({
      variables: {
        input: {
          id,
          params,
        },
      },
      onCompleted: () => {
        toast.success(<FormattedMessage id="useProfile.UpdateSuccess" />);
      },
      onError: () => {
        toast.error(<FormattedMessage id="useProfile.UpdateError" />);
      },
    });
  };

  const updatePreferences = async (keyName, keyValue) => {
    await handleSetPreferences({
      variables: {
        input: {
          id: data?.profile?.user?.id,
          params: {
            preferences: {
              ...data?.profile?.user?.preferences,
              profile: {
                ...data?.profile?.user?.preferences.profile,
                [keyName]: keyValue,
              },
            },
          },
        },
      },
      onCompleted: () => toast.success(<FormattedMessage id="useProfile.UpdateSuccess" />),
      onError: () => toast.error(<FormattedMessage id="useProfile.UpdateError" />),
    });
  };

  const resendConfirmation = async (id) => {
    await handleResendConfirmation({
      variables: {
        input: {
          id,
        },
      },
      onCompleted: () => toast.success(<FormattedMessage id="useProfile.UpdateSuccess" />),
      onError: () => toast.error(<FormattedMessage id="useProfile.UpdateError" />),
    });
  };

  useEffect(() => {
    if (profileCompletion && !isPublicProfile) {
      setSimplifiedVetting(isSimplifiedVetting(data?.profile, data?.profile?.partner?.trusted));
    }
  }, [data?.profile, isPublicProfile]);

  useEffect(() => {
    if (profileCompletion && !isPublicProfile) {
      updateProfileCompletion(data?.profile, profileCompletion, { canViewEditRate });
    }
  }, [data, isPublicProfile]);

  useEffect(() => {
    if (profileCompletion && !isPublicProfile) {
      const inCompleteItems = Object.entries(profileCompletion).filter((item) => !item[1].isComplete);
      setIsProfileComplete(inCompleteItems.length === 0);
    }
  }, [profileCompletion, isPublicProfile]);

  useEffect(() => {
    if (data?.profile && !isPublicProfile) {
      const {
        challengesCount,
        passedChallengesCount,
        completedInterviewsCount,
        codeChallengeRequired,
        vettingConfiguration,
      } = data?.profile;
      setCodeChallengeFailed(passedChallengesCount === 0 && challengesCount > 0);
      setCodeChallengeComplete(passedChallengesCount > 0);
      setInterviewComplete(completedInterviewsCount > 0);
      setIsCodeChallengeRequired(codeChallengeRequired);
      setVettingConfiguration(vettingConfiguration);
    }
  }, [data?.profile, isPublicProfile]);

  const styles = {
    restoreDescription: css`
      margin-top: 2rem;
      font-weight: bold;
    `,
  };

  return {
    profileCompletion,
    loading,
    data,
    error,
    simplifiedVetting,
    isProfileComplete,
    isCodeChallengeFailed,
    isCodeChallengeComplete,
    isInterviewComplete,
    isCodeChallengeRequired,
    vettingConfiguration,
    handleMoveToVetting,
    handleSubmitForReview,
    handleApprove,
    handleRestore,
    updateProfile,
    updatePreferences,
    resendConfirmation,
    approving,
  };
}
