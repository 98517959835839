import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useOutletContext } from "react-router-dom";
import { css } from "@emotion/react";
import Card from "components/Card";
import PageTitle from "components/PageTitle";
import { colors } from "style";
import { PrimaryLink } from "components/Links";
import { TRACKING_PAGE_VIEWS, LINKS } from "constants/index";
import { MissionAdvancedOptions, MissionPublishButton, MissionAbout, MissionAboutClient } from "components/Missions";
import { RoleAddButton, RolesList } from "components/Missions/Roles";
import { ViewProposalButton, ProposalButton, ProposalDraftButton } from "components/Missions/Proposal";
import { Grid, PageWrapper } from "components/Containers";
import { TextLoader } from "components/Loader";
import { useTracking } from "hooks";
import { SectionTitle } from "components/Layouts";

/**
 * Missions Edit Page
 */
const MissionEdit = () => {
  const [data, loading] = useOutletContext();
  const [selection, setSelection] = useState([]);
  const { trackPage } = useTracking();
  const intl = useIntl();

  useEffect(() => {
    trackPage(TRACKING_PAGE_VIEWS["missionDetails"].page_name, TRACKING_PAGE_VIEWS["missionDetails"]);
  }, []);

  return (
    <PageWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Missions.Edit.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle
            backLink={
              <PrimaryLink to={LINKS.missions} routerEnabled>
                <FormattedMessage id="Mission.BackLink" />
              </PrimaryLink>
            }
            title={data?.mission?.name}
            actionBar={
              <>
                <MissionPublishButton data={data?.mission} loading={loading} />
                <MissionAdvancedOptions data={data?.mission} loading={loading} />
              </>
            }
          />
        </Grid.col>
        <Grid.col start={1} end={9}>
          <Card fixedHeight>
            <MissionAbout mission={data?.mission} isLoading={loading} />
          </Card>
        </Grid.col>
        <Grid.col start={9} end={13}>
          <Card fixedHeight>
            <MissionAboutClient mission={data?.mission} isLoading={loading} />
          </Card>
        </Grid.col>
        <Grid.col start={1} end={13}>
          <Grid>
            <Grid.col start={1} end={13}>
              <SectionTitle
                title={<FormattedMessage id="Missions.Edit.Requirements" />}
                actionBar={
                  <>
                    <ProposalDraftButton data={data?.mission} loading={loading} />
                    <ViewProposalButton data={data?.mission} loading={loading} />
                  </>
                }
              />
            </Grid.col>
            <Grid.col start={1} end={13}>
              <Card>
                {loading ? (
                  <Grid cols={4}>
                    {[0, 1, 2, 3].map((item) => (
                      <Grid.col key={item}>
                        <TextLoader css={styles.text_loader} />
                      </Grid.col>
                    ))}
                  </Grid>
                ) : (
                  <Grid cols={data?.mission?.assessments.length}>
                    {data?.mission?.assessments.map((assessment) => (
                      <Grid.col key={assessment.uuid}>
                        <ProposalButton type={assessment.type} updateId={assessment.uuid} isEditing={false} />
                      </Grid.col>
                    ))}
                  </Grid>
                )}
              </Card>
            </Grid.col>
            <Grid.col start={1} end={13}>
              <SectionTitle
                title={
                  <FormattedMessage
                    id="Mission.Edit.RoleTitle"
                    values={{
                      rolesCount: <span css={styles.count}>({data?.mission?.rolesCount})</span>,
                    }}
                  />
                }
                actionBar={
                  <>
                    <RoleAddButton missionId={data?.mission?.uuid} isEditing={false} />
                  </>
                }
              />
            </Grid.col>
            <Grid.col start={1} end={13}>
              <RolesList
                isCardView={false}
                missionId={data?.mission?.uuid}
                onSelect={setSelection}
                selectionInitialState={selection}
              />
            </Grid.col>
          </Grid>
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

const styles = {
  count: css`
    font-weight: 300;
    color: ${colors.grayAnatomyLight1};
  `,
  text_loader: css`
    width: 15rem;
  `,
};

export default MissionEdit;
