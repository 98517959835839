import React from "react";
import PropTypes from "prop-types";

const Div = (props) => <div {...props}>{props.children}</div>;

Div.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
};

export default Div;
