import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Row } from "components/Containers";
import { nonUtcFormatDate } from "utils";
import { Banner, BANNER_SIZE } from "components/Banner";
import { BANNER_TYPE } from "constants";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { colors } from "style";

/**
 * TimeSheetStatus
 *
 * @param {Object} selectedMonth
 * @param {Object} allSelected
 */
const TimeSheetStatus = ({ selectedMonth, allSelected }) => {
  const isSubmitted = selectedMonth?.submitted === true;

  if (!allSelected && !isSubmitted) {
    return (
      <Row css={styles.row}>
        <Banner type={BANNER_TYPE.warning} isClosable={false} size={BANNER_SIZE.small}>
          <Row>
            <Icon type={ICON_TYPE.info} color={colors.yellow} />
            <FormattedMessage id="TimeSheet.TimeSheetStatus.Disabled" />
          </Row>
        </Banner>
      </Row>
    );
  } else if (!isSubmitted) {
    return null;
  }

  return (
    <Row css={styles.row}>
      <Banner type={BANNER_TYPE.info} isClosable={false} size={BANNER_SIZE.small}>
        <Row>
          <Icon type={ICON_TYPE.checkDotted} size={ICON_SIZE.xlarge} color={colors.green} />
          <span>
            <FormattedMessage
              id="TimeSheet.TimeSheetStatus.Submitted"
              values={{
                date: <span css={styles.date}>{nonUtcFormatDate(selectedMonth?.submittedAt, "MMMM Do, h:mm a")}</span>,
              }}
            />
          </span>
        </Row>
      </Banner>
    </Row>
  );
};

const styles = {
  row: css`
    justify-content: flex-end;
  `,
  date: css`
    font-weight: 600;
  `,
};

TimeSheetStatus.propTypes = {
  selectedMonth: PropTypes.object,
  allSelected: PropTypes.bool,
};

export default TimeSheetStatus;
