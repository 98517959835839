import { gql } from "@apollo/client";

const publicMissionFields = `
  name
  description
  descriptionJson
  missionRequirements {
    id
    description
  }
`;

const basicMissionFields = `
  ${publicMissionFields}
  uuid
  startDate
  companyDescription
  companyDescriptionJson
  nameWithOrganization
`;

const coreMissionFields = `
  id
  ${basicMissionFields}
  rolesCount
  published
  assessmentDrafted
  isOrganizationConfidential
  mission {
    name
    email
  }
  timezone {
    name
    value
  }
  client {
    email
    name
  }
  organization {
    id
    name
    slug
  }
`;

const extendedMissionFields = `
  ${coreMissionFields}
`;

const CORE_MISSION_FIELDS = gql`
  fragment MissionFields on Mission {
    ${coreMissionFields}
  }
`;

const BASIC_MISSION_FIELDS = gql`
  fragment BasicMissionFields on Mission {
    ${basicMissionFields}
  }
`;

const PUBLIC_MISSION_FIELDS = gql`
  fragment PublicMissionFields on PublicMission {
    ${publicMissionFields}
  }
`;

const EXTENDED_MISSION_FIELDS = gql`
  fragment ExtendedMissionFields on Mission {
    ${extendedMissionFields}
  }
`;

export { CORE_MISSION_FIELDS, BASIC_MISSION_FIELDS, EXTENDED_MISSION_FIELDS, PUBLIC_MISSION_FIELDS };
