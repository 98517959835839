import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { colors } from "style";
import { calculatePercentage, createMarkup, formatDate, formatHours, formatNumber } from "utils";
import { CODE_CHALLENGE_STATUS } from "constants/index";
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from "components/Table";
import { Banner } from "components/Banner";
import { BANNER_TYPE, CHEATING_TYPES } from "constants";
import { Grid, Row } from "components/Containers";
import { PrimaryButton } from "components/Buttons";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { H4 } from "components/Text";
import Status from "components/Status";
import Card from "components/Card";

/**
 * CodeChallengeDetails
 *
 * @param {Object} challengeResponse
 */
const CodeChallengeDetails = ({ challengeResponse }) => (
  <Grid>
    {challengeResponse?.status === CODE_CHALLENGE_STATUS.invited ? (
      <Grid.col start={1} end={13}>
        <Banner type={BANNER_TYPE.warning} isClosable={false}>
          <Row>
            <Icon type={ICON_TYPE.info} size={ICON_SIZE.medium} color="inherit" />
            <FormattedMessage id="CodeChallenges.CodeChallengeDetailsModal.Invited" />
          </Row>
        </Banner>
      </Grid.col>
    ) : (
      <>
        {challengeResponse.cheatingFlag && (
          <Grid.col start={1} end={13}>
            <Card isAdmin>
              <Banner
                type={
                  challengeResponse.cheatingFlag === CHEATING_TYPES.none
                    ? BANNER_TYPE.info
                    : challengeResponse.cheatingFlag === CHEATING_TYPES.likely
                    ? BANNER_TYPE.warning
                    : BANNER_TYPE.error
                }
                isClosable={false}
              >
                <Row>
                  <Icon type={ICON_TYPE.info} size={ICON_SIZE.medium} color="inherit" />
                  <FormattedMessage id={getCheatingMessageId(challengeResponse.cheatingFlag)} />
                </Row>
              </Banner>
            </Card>
          </Grid.col>
        )}
        <Grid.col start={1} end={13}>
          {challengeResponse?.structuredTasks?.challenge_details?.length > 0 && (
            <>
              <H4 themeStyle={styles.challenges_heading}>
                <FormattedMessage id="CodeChallenges.CodeChallengeDetailsModal.Challenges.Header" />
              </H4>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell />
                    <TableHeaderCell>
                      <FormattedMessage id="CodeChallenges.CodeChallengeDetailsModal.LabelTimeTaken" />
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <FormattedMessage id="CodeChallenges.CodeChallengeDetailsModal.LabelLanguage" />
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <FormattedMessage id="CodeChallenges.CodeChallengeDetailsModal.LabelScore" />
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <FormattedMessage id="CodeChallenges.CodeChallengeDetailsModal.LabelDifficulty" />
                    </TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {challengeResponse.structuredTasks.challenge_details.map((task, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <span css={styles.task_title}>{task.title}</span>
                      </TableCell>
                      <TableCell>{task.time_taken ? formatHours(task.time_taken, false, true) : "—"}</TableCell>
                      <TableCell>{task.language}</TableCell>
                      <TableCell>
                        {formatNumber(task.score)} / {formatNumber(task.max_score || 10)}
                      </TableCell>
                      <TableCell>{task.meta?.difficulty ?? "—"}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
          {challengeResponse?.structuredTasks?.mc_details?.length > 0 && (
            <>
              <H4 themeStyle={styles.mcq_heading}>
                <FormattedMessage id="CodeChallenges.CodeChallengeDetailsModal.MCQS.Header" />
              </H4>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell width={95}>
                      <FormattedMessage id="CodeChallenges.CodeChallengeDetailsModal.LabelQuestion" />
                    </TableHeaderCell>
                    <TableHeaderCell width={5}>
                      <FormattedMessage id="CodeChallenges.CodeChallengeDetailsModal.CorrectLabel" />
                    </TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {challengeResponse.structuredTasks.mc_details.map((task) => (
                    <TableRow key={task.id}>
                      <TableCell width={95}>
                        <div dangerouslySetInnerHTML={createMarkup(task.question)} />
                      </TableCell>
                      <TableCell width={5} themeStyles={styles.mcq_correct_column}>
                        <Status enabled={task.correct} themeStyle={styles.status}>
                          <FormattedMessage id={`Global.${task.correct ? "Yes" : "No"}`} />
                        </Status>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
        </Grid.col>
        <Grid.col start={1} end={13}>
          <Row css={styles.button_container}>
            <span>
              <FormattedMessage
                id="CodeChallenges.CodeChallengeDetailsModal.Summary"
                values={{
                  status: <b>{challengeResponse.status}</b>,
                  score: <b>{calculatePercentage(challengeResponse.score, challengeResponse.maxScore)}%</b>,
                  date: formatDate(challengeResponse.completedAt, "MMMM Do, YYYY"),
                }}
              />
            </span>
            <PrimaryButton onClick={() => window.open(challengeResponse.reportUrl, "_blank")}>
              <FormattedMessage id="CodeChallenges.CodeChallengeDetailsModal.ViewReport" />
            </PrimaryButton>
          </Row>
        </Grid.col>
      </>
    )}
  </Grid>
);

const getCheatingMessageId = (cheatingFlag) => {
  switch (cheatingFlag) {
    case CHEATING_TYPES.none:
      return "CodeChallenges.CodeChallengeDetailsModal.CheatingNone";
    case CHEATING_TYPES.likely:
      return "CodeChallenges.CodeChallengeDetailsModal.CheatingLikely";
    case CHEATING_TYPES.detected:
      return "CodeChallenges.CodeChallengeDetailsModal.CheatingDetected";
  }
};

const styles = {
  button_container: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem 1rem 2rem;
    border-radius: 0.6rem;
    background: ${colors.grayAnatomyLight5};
  `,
  mcq_heading: css`
    margin-top: 3rem;
  `,
  challenges_heading: css`
    margin-top: 0rem;
  `,
  mcq_correct_column: css`
    > span {
      display: inline-block;
    }
  `,
  task_title: css`
    font-weight: 500;
  `,
};

CodeChallengeDetails.propTypes = {
  challengeResponse: PropTypes.object,
};

export default CodeChallengeDetails;
