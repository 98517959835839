import { gql } from "@apollo/client";

const GET_INTERVIEW_RESPONSES = gql`
  query interviewResponses(
    $filters: InterviewResponseFilter
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    interviewResponses(filters: $filters, first: $first, last: $last, after: $after, before: $before) {
      nodes {
        id
        uuid
        slug
        type
        status
        completed
        publicUrl
        privateUrl
        answers
        deletedAt
        createdAt
        interview {
          id
          name
        }
      }
    }
  }
`;

export { GET_INTERVIEW_RESPONSES };
