import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { isSameISOWeek } from "date-fns";
import enGb from "date-fns/locale/en-GB";
import moment from "moment";
import { css } from "@emotion/react";
import { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { colors, gradients, fontSize } from "style";
import Icon, { ICON_TYPE } from "components/Icon";
import "react-datepicker/dist/react-datepicker.css";
import { convertDateToUTC, formatDate, isDateSame } from "utils";
import { Row } from "components/Containers";
import { PrimaryButton } from "components/Buttons";

registerLocale("en-gb", enGb);
moment.locale("en-gb");

/**
 * DatePicker
 * https://github.com/Hacker0x01/react-datepicker
 *
 * @param {String}   value
 * @param {String}   format
 * @param {String}   name
 * @param {Boolean}  required
 * @param {Function} onChange
 * @param {Boolean}  transparent
 * @param {Boolean}  isClearable
 * @param {Boolean}  enableWeekPicker
 * @param {Boolean}  enableMonthPicker
 * @param {Boolean}  enableWeekRangePicker
 * @param {Boolean}  enableMonthRangePicker     - allow date picker to be a month range selector
 * @param {Number}   monthRangeInterval         - to control the number of months can be selected by the end date
 * @param {Boolean}  enableDateRange
 * @param {Boolean}  enableDateCycle
 * @param {Boolean}  disabled
 * @param {Date}     maxDate                    - set the max the date picker selection is allowed
 */
const MyDatePicker = ({
  value,
  format,
  name,
  required,
  onChange,
  transparent,
  isClearable,
  enableWeekPicker,
  enableMonthPicker,
  enableWeekRangePicker,
  enableMonthRangePicker,
  monthRangeInterval,
  enableDateRange,
  enableDateCycle,
  disabled,
  maxDate,
  ...props
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focus, setFocus] = useState(false);
  const datepickerRef = useRef(null);
  const getStartOf = (date, period = "week") => date.startOf(period);
  const getEndOf = (date, period = "week") => date.endOf(period);
  const isValidDate = enableDateRange || enableWeekRangePicker ? Array.isArray(value) && value.length > 0 : true;
  const [canCycleForward, setCanCycleForward] = useState(false);

  useEffect(() => {
    if (!enableDateCycle) return;

    const [period, periodCount] = getPeriodData();
    const today = moment().unix();
    const tempEndDate = moment(startDate).add(periodCount, period).unix();

    setCanCycleForward(today >= tempEndDate);
  }, [startDate, enableDateCycle, enableWeekPicker, enableMonthRangePicker]);

  useEffect(() => {
    if (value && typeof value === "object" && isValidDate) {
      const date = moment(value[0]);
      setStartDate(
        convertDateToUTC(
          enableMonthRangePicker ? getStartOf(date, "month") : enableWeekPicker ? getStartOf(date) : date
        )
      );
      checkAndSetEndDate();
    } else if (value && isValidDate) {
      const date = moment(value);
      setStartDate(
        convertDateToUTC(
          enableMonthRangePicker ? getStartOf(date, "month") : enableWeekPicker ? getStartOf(date) : date
        )
      );
      checkAndSetEndDate();
    } else {
      setStartDate(null);
    }
  }, [value]);

  const getPeriodData = () =>
    enableMonthRangePicker
      ? ["month", moment(endDate).diff(moment(startDate), "months") + 1]
      : [enableWeekPicker ? "week" : enableMonthPicker ? "month" : "day", 1];

  const checkAndSetEndDate = () => {
    if ((enableDateRange || enableWeekRangePicker || enableMonthRangePicker) && isValidDate) {
      setEndDate(convertDateToUTC(moment(value[1])));
    }
  };

  const handleChange = (val) => {
    if (!val) {
      onChange(name, null);
      return;
    }

    const date = enableWeekPicker ? getStartOf(moment(val)) : moment(val);
    const dateStr = date.format("YYYY-MM-DD");

    onChange(name, dateStr);
    setStartDate(convertDateToUTC(dateStr));
  };

  const handleRangeChange = (dates) => {
    let startDateStr = null;
    let endDateStr = null;

    if (dates?.[0] === null && dates?.[1] === null) {
      setStartDate(null);
      setEndDate(null);
      onChange(name, []);
      return;
    }

    if (dates[0]) {
      const date = moment(dates[0]);
      startDateStr = (
        enableMonthRangePicker ? getStartOf(date, "month") : enableWeekRangePicker ? getStartOf(date) : date
      ).format("YYYY-MM-DD");
      setStartDate(convertDateToUTC(startDateStr));
      setEndDate(null);
    }
    if (dates[1]) {
      const date = moment(dates[1]);
      endDateStr = (
        enableMonthRangePicker ? getEndOf(date, "month") : enableWeekRangePicker ? getEndOf(date) : date
      ).format("YYYY-MM-DD");
      setEndDate(convertDateToUTC(endDateStr));
    }

    if (startDateStr && endDateStr) {
      onChange(name, [startDateStr, endDateStr]); // Calling onchange when both the dates are present
    }
  };

  /**
   * @description Removes the class that highlights date from elements that don't match the value's month and year
   */
  const removeDateHighlight = () => {
    const dayElements = document.querySelectorAll(".react-datepicker__day--keyboard-selected");

    dayElements.forEach((element) => {
      const ariaLabel = element.getAttribute("aria-label"); // Get the date from the element's aria-label attribute
      const dayElementDate = ariaLabel ? moment(ariaLabel.replace("Choose ", ""), "dddd, MMMM Do, YYYY") : null;

      if (dayElementDate && !isDateSame(dayElementDate, moment(value))) {
        element.classList.remove("react-datepicker__day--keyboard-selected");
      }
    });
  };

  const handleDatepickerRendered = () => {
    // Find all the day elements of the selected week
    if (enableWeekPicker || enableWeekRangePicker) {
      const selectedElement = document.querySelectorAll(".react-datepicker__day--selected");

      if (selectedElement?.length > 0) {
        const dayElements = selectedElement[0].parentElement?.childNodes;
        // Loop through the week day elements and add the class to the days
        dayElements?.forEach((dayElement) => dayElement?.classList?.add("react-datepicker__day--selected"));
      }
    }

    // Call the function to remove the keyboard-selected class from dates not matching the month and year
    setTimeout(removeDateHighlight, 0);
  };

  /**
   * @description Allows user to cycle back/forward through dates
   *
   * @param {Boolean} isSubtract
   */
  const handleDateCycle = (isSubtract) => {
    const [period, periodCount] = getPeriodData();
    const handlePeriodChange = (date) =>
      isSubtract ? moment(date).subtract(periodCount, period) : moment(date).add(periodCount, period);
    const localFormat = "YYYY-MM-DD";
    const newStartDate = enableMonthRangePicker
      ? getStartOf(handlePeriodChange(startDate), "month")
      : handlePeriodChange(startDate);

    setStartDate(convertDateToUTC(newStartDate));

    if (enableDateRange || enableMonthRangePicker) {
      const newEndDate = enableMonthRangePicker
        ? getEndOf(handlePeriodChange(endDate), "month")
        : handlePeriodChange(endDate);

      setEndDate(convertDateToUTC(newEndDate));
      onChange(name, [newStartDate.format(localFormat), newEndDate.format(localFormat)]);
    } else {
      onChange(name, newStartDate.format(localFormat));
    }
  };

  return (
    <Row css={styles.outer_container(enableDateCycle)}>
      {enableDateCycle && (
        <PrimaryButton css={styles.cycle_button} onClick={() => handleDateCycle(true)}>
          <Icon type={ICON_TYPE.arrowLeft} color="inherit" />
        </PrimaryButton>
      )}
      <div css={styles.container(focus, enableWeekPicker || enableWeekRangePicker, transparent, isClearable)}>
        <DatePicker
          selected={startDate}
          maxDate={maxDate}
          onChange={(update) =>
            enableDateRange || enableWeekRangePicker || enableMonthRangePicker
              ? handleRangeChange(update)
              : handleChange(update)
          }
          dateFormat={format}
          name={name}
          required={required}
          scrollableYearDropdown={true}
          showYearDropdown={true}
          isClearable={isClearable}
          dayClassName={(date) => {
            isSameISOWeek(date, startDate) ? "react-datepicker__day--selected" : "";
          }}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          popperContainer={(popperProps) => {
            const { ref, ...otherProps } = popperProps;
            datepickerRef.current = ref; // Store a reference to the date-picker element
            handleDatepickerRendered(); // Call the function to add the class to the days incase of week picker
            return <div ref={ref} {...otherProps} />;
          }}
          {...(enableDateRange && {
            selectsRange: true,
            startDate: startDate,
            endDate: endDate,
          })}
          {...(enableWeekPicker &&
            startDate && {
              value: `Week of ${formatDate(startDate, "DD MMM YYYY")}`,
            })}
          {...(enableWeekRangePicker && {
            selectsRange: true,
            startDate: startDate,
            endDate: endDate,
            locale: "en-gb",
            value: `${startDate ? formatDate(startDate, "DD MMM YY") : ""} - ${
              endDate ? formatDate(endDate, "DD MMM YY") : ""
            }`,
          })}
          {...(enableMonthRangePicker && {
            selectsRange: true,
            startDate: startDate,
            endDate: endDate,
            showMonthYearPicker: true,
            value: `${startDate ? formatDate(startDate, "MMM YYYY") : ""} - ${
              endDate ? formatDate(endDate, "MMM YYYY") : ""
            }`,
            ...(startDate &&
              !endDate && {
                // To keep the endDate in case of Month Year Picker in the range of the given interval
                maxDate: new Date(moment(startDate).add(monthRangeInterval, "months").format("YYYY-MM-DD")),
              }),
          })}
          {...(enableMonthPicker &&
            value && {
              value: formatDate(value, "MMM YYYY"),
            })}
          disabled={disabled}
          calendarStartDay={1}
          {...props}
        />
        <div css={styles.iconContainer}>
          <Icon type={ICON_TYPE.calendar} color={disabled ? colors.grayAnatomyLight3 : colors.grayAnatomyLight1} />
        </div>
      </div>
      {enableDateCycle && (
        <PrimaryButton css={styles.cycle_button} onClick={() => handleDateCycle(false)} disabled={!canCycleForward}>
          <Icon type={ICON_TYPE.arrowRight} color="inherit" />
        </PrimaryButton>
      )}
    </Row>
  );
};

const styles = {
  outer_container: (enableDateCycle) => css`
    display: ${enableDateCycle ? "flex" : "block"};
    position: relative;
    order: 1;
    gap: 0.5rem;
  `,
  cycle_button: css`
    width: 2.5rem;
    height: 2.5rem;
    min-height: auto;
    min-width: auto;
    padding: 0;
  `,
  container: (focus, weekPickerEnabled, isTransparent, isClearable) => css`
    position: relative;

    ${
      focus === true &&
      `
      & + label {
        color: ${colors.purpleRainBase};
      }
    `
    }
    
    .react-datepicker {
      font-family: inherit !important;
      color: inherit !important;
    }

    .react-datepicker__close-icon::after {
      background: ${colors.purpleRainBase};
    }

    .react-datepicker__input-container {
      input {
        display: block;
        font-size: ${fontSize.xsmall};
        background: none;
        font-weight: normal;
        height: 4rem;
        padding-left: 2.8rem;
        width: 100%;
        color: ${colors.purpleRainDark2};
        order: 1;
        resize: none;
        appearance: none;
        background-clip: padding-box;
        outline: none;
        transition: all 0.3s ease;
        text-align: right;
        padding-right: ${isClearable ? "3.5rem" : "1.4rem"};

        ${
          !isTransparent &&
          `
          border-radius: 0.6rem !important;   
          border: 1px solid ${colors.grayAnatomyLight3};

          background: #fff;
        `
        }
      
        &:focus {
          border-color: ${colors.purpleRainBase};
          box-shadow: 0px 0px 0px 3px ${colors.purpleRainLight3};
      
          & + label {
            color: ${colors.purpleRainBase};
          }
        }

        &:hover {
          border-color: ${colors.purpleRainBase};

          & + label {
            color: ${colors.purpleRainBase};
          }
        }

        &:disabled {
          border: 1px solid ${colors.grayAnatomyLight3} !important;
          color: ${colors.grayAnatomyLight2} !important;
          background-color: ${colors.grayAnatomyLight5};
        }
      }

      .react-datepicker__close-icon {
        padding: 0 12px 0 12px;

        &:after {
          font-size: ${fontSize.large};
          color: ${colors.grayAnatomyLight2};
          cursor: pointer;
          background: none;

          &:hover {
            color: ${colors.grayAnatomyLight1};
          }
        }
      }
    }

    .react-datepicker {
      border-radius: 0.6rem;
      background-color: #fff;
      box-shadow: 0 0 14px 0 rgb(0 0 0 / 50%);
      border: 0;
      padding-bottom: 1rem;
    }
    .react-datepicker-popper {
      z-index: 9999 !important;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      font-size: ${fontSize.xsmall};
      font-weight: 500;
      color: ${colors.greyBase};
      border-radius: 100% !important;
      width: 2.7rem;
      line-height: 2.7rem;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      color: #fff;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected,
    .react-datepicker__day--selected {
      color: #fff !important;
      background: ${colors.purpleRainBase} !important;
    }

    ${
      weekPickerEnabled &&
      `
      .react-datepicker__week:hover {
        .react-datepicker__day {
          color: #fff;
          background: ${colors.purpleRainLight1} !important;
        }
      }
    `
    }

    .react-datepicker__header {
      background: #fff;
      height: 8rem;
      border-bottom: 1px solid rgba(192, 207, 223, 0.5);
    }

    .react-datepicker__day-names {
      margin-top: 0.8rem;
      padding: 0px 2.5rem;
    }

    .react-datepicker__day-name {
      font-size: 1.1rem;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      color: ${colors.purpleRainDark2};
      font-size: ${fontSize.normal};
      font-weight: 500;
      line-height: 2.2rem;
      left: 3.5rem;
      position: relative;
      text-align: left;
      padding-top: 1rem;
    }

    .react-datepicker__header__dropdown--scroll {
      position: absolute;
      top: 2.2rem;
      right: 0;
      width: 14rem;
      color: ${colors.purpleRainDark2};
      font-size: ${fontSize.xsmall};

      .react-datepicker__year-read-view {
        visibility: visible !important;
      }
    }

    .react-datepicker__year-option--selected,
    .react-datepicker__month-option--selected,
    .react-datepicker__month-year-option--selected {
      left: 0.5rem;
      display: none;
    }

    .react-datepicker__year-dropdown,
    .react-datepicker__month-dropdown,
    .react-datepicker__month-year-dropdown {
      top: 100%;
      background-color: #fff;
      border-radius: 0.4rem;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
      box-sizing: border-box;
      border: 1px solid ${colors.grayAnatomyLight3};
    }

    .react-datepicker__navigation-icon::before {
      border-color: ${colors.grayAnatomyLight1};
    }

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow {
      transform: rotate(135deg);
      right: --1.1rem;
      top: 0.1rem;
      font-size: 7px;
      border-width: 2px 2px 0 0;
      height: 0.6rem;
      width: 0.6rem;
      border-color: ${colors.grayAnatomyLight1};
    }

    .react-datepicker__year-option,
    .react-datepicker__month-option,
    .react-datepicker__month-year-option {
      background-color: transparent;
      color: ${colors.purpleRainDark2};
      font-size: ${fontSize.xsmall};
      width: 100%;
      padding: 0.5rem 0;

      &:hover {
        background: ${colors.grayAnatomyLight5};
      }

      .react-datepicker__navigation {
        height: 1.9rem;
        width: 100%;
        margin: -0.5rem 0;

        &.react-datepicker__navigation--years-upcoming,
        &.react-datepicker__navigation--years-previous {
          content: " ";

          &:before {
            transform: rotate(45deg);
            border-color: ${colors.grayAnatomyLight1};
            border-style: solid;
            border-width: 2px 0px 0px 2px;
            content: "";
            display: block;
            height: 0.7rem;
            position: absolute;
            top: 0.6rem;
            left: 3rem;
            width: 0.7rem;
          }
        }

        &.react-datepicker__navigation--years-previous {
          &:before {
            border-width: 0px 2px 2px 0px;
          }
        }
      }
    }

    .react-datepicker__year-option--selected_year {
      background: ${colors.purpleRainBase} !important;
      color: #fff;
    }

    .react-datepicker__navigation {
      height: 7.9rem;
      width: 2rem;
      background-color: ${colors.grayAnatomyLight4};
      top: 0;
    }

    .react-datepicker__navigation--previous {
      left: 0;
      border-radius: 5px 0 0 0;
    }

    .react-datepicker__navigation--next {
      right: 0;
      border-radius: 0 5px 0 0;
    }

    .react-datepicker__navigation-icon--previous {
      right: 0px;
    }

    .react-datepicker__navigation-icon--next {
      left: 0px;
    }

    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after,
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
      display: none;
    }
    .react-datepicker__header.react-datepicker-year-header {
      left: 0;
      padding-left: 4rem;
      padding-right: 4rem;
      text-align: center;
      height: 8rem;
      padding-top: 2.7rem;
      font-size: ${fontSize.large};
      font-weight:600;
      background: ${colors.grayAnatomyLight4};
    }

    .react-datepicker__year-text {
      font-size: 1.2rem;
    }

    .react-datepicker__month-container {
      
      .react-datepicker__monthPicker {
        .react-datepicker__month-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          .react-datepicker__month-text {
            text-align: center;
            line-height: 2.5rem;
            min-width: 10rem;
            min-height: 2.5rem;
            font-size: ${fontSize.xsmall};
            outline: none;
            &:focus {
              box-shadow: none;
              border-image-source: ${gradients.missionCircularGradient};
              border-width: 2px;
              border-image-slice: 1;
              background-image: linear-gradient(to bottom, #fff, #fff), ${gradients.missionCircularGradient};
              background-origin: border-box;
              background-clip: padding-box, border-box;
              border-color: transparent !important;
              outline: none;

              & + label {
                color: ${colors.purpleRainBase};
              }
            }

            &:hover {
              border-color: ${colors.purpleRainBase};
              outline: none;
              & + label {
                color: ${colors.purpleRainBase};
              }
            }
          }
        }
      }
      .react-datepicker__month--selected {
        background-color: ${colors.purpleRainBase};
      }

      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range {
        color: ${colors.purpleRainBase} !important;
        background-color: ${colors.purpleRainLight3} !important;
      }
  `,
  iconContainer: css`
    position: absolute;
    left: 0rem;
    top: 0rem;
    width: 4rem;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
  `,
};

MyDatePicker.defaultProps = {
  format: "dd-MM-yyyy",
  transparent: false,
  enableDateRange: false,
  isClearable: false,
  enableDateCycle: false,
  enableMonthRangePicker: false,
  enableMonthPicker: false,
  monthRangeInterval: 11,
};

MyDatePicker.propTypes = {
  value: PropTypes.string,
  format: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  yearDropdownItemNumber: PropTypes.number,
  enableMonthPicker: PropTypes.bool,
  enableWeekPicker: PropTypes.bool,
  enableWeekRangePicker: PropTypes.bool,
  transparent: PropTypes.bool,
  enableDateRange: PropTypes.bool,
  isClearable: PropTypes.bool,
  enableDateCycle: PropTypes.bool,
  disabled: PropTypes.bool,
  enableMonthRangePicker: PropTypes.bool,
  monthRangeInterval: PropTypes.number,
  maxDate: PropTypes.date,
};

export default MyDatePicker;
