import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { DataTableProvider } from "components/DataTable";
import { formatCurrency, formatDate, convertToPercent } from "utils";
import { LINKS, AVAILABILITIES } from "constants/index";
import { UserCapacity, UserLocation, UserPhoto } from "components/User";
import { APPLICANT_TYPES, CAPACITY_STATUS } from "constants/index";
import { ScoreDisplay } from "components/Missions/Applicants";
import Status from "components/Status";
import { Col, Row } from "components/Containers";
import Score from "components/Score";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import Tooltip from "components/Tooltip";
import { ProfileLink, ProfileModal, ProfileRateRange } from "components/Profile";
import { useApplicants } from "hooks";
import { RoleLink } from "components/Missions/Roles";

/**
 * Candidate List
 *
 * @params {Array}    data
 * @params {String}   applicantType
 * @params {Boolean}  loading
 * @params {Object}   error
 * @params {Array}    onSelect
 * @params {Number}   resultsPerPage
 * @params {Number}   handleNextPage,
 * @params {Number}   handlePreviousPage,
 * @params {Number}   hasNextPage,
 * @params {Number}   hasPreviousPage
 * @params {Boolean}  showAdvancedOptions
 * @params {Boolean}  showRateColumn
 * @params {Boolean}  openModalOnRowClick
 * @params {Function} handlePageChange
 * @params {Object}   pageInfo
 * @params {Number}   currentPage
 * @params {Number}   totalCount
 * @params {String}   paginationType
 */
const ApplicantList = ({
  data,
  applicantType,
  loading,
  error,
  onSelect,
  resultsPerPage,
  handleNextPage,
  handlePageChange,
  handlePreviousPage,
  hasNextPage,
  hasPreviousPage,
  selectionInitialState,
  showAdvancedOptions,
  showRateColumn,
  openModalOnRowClick,
  pageInfo,
  currentPage,
  totalCount,
  paginationType,
}) => {
  const { getAdvancedOptions } = useApplicants({ applicantState: applicantType, skipQuery: true });
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);

  const handleRowClick = (data) => {
    setSelectedProfileId(data?.uuid);
    setShowProfileModal(true);
  };

  return (
    <>
      <DataTableProvider
        onSelect={onSelect}
        data={data}
        error={error}
        loading={loading}
        columns={getColumns(applicantType, showRateColumn, openModalOnRowClick ? handleRowClick : null)}
        selectable={showAdvancedOptions && !TYPES_WITHOUT_ACTIONS.includes(applicantType)}
        resultsPerPage={resultsPerPage}
        selectionInitialState={selectionInitialState}
        clearable={false}
        clearCacheValue={applicantType}
        paginationType={paginationType}
        rowOptions={showAdvancedOptions ? (data) => getAdvancedOptions(data) : null}
        headerOptions={showAdvancedOptions ? (selection) => getAdvancedOptions({}, selection) : null}
        paginationProps={{
          countMessage: "Global.Users.Count",
          resultsPerPage,
          onNextPage: handleNextPage,
          onPreviousPage: handlePreviousPage,
          hasNextPage: !loading && hasNextPage,
          hasPreviousPage: !loading && hasPreviousPage,
          currentPage,
          handlePageChange,
          totalCount,
          pageInfo,
          loading,
        }}
      />
      {selectedProfileId && (
        <ProfileModal
          id={selectedProfileId}
          show={showProfileModal}
          handleClose={() => setShowProfileModal(false)}
          handlePrimary={() => {
            setShowProfileModal(false);
            window.open(LINKS.private_profile(selectedProfileId), "_blank");
          }}
        />
      )}
    </>
  );
};

const TYPES_WITHOUT_ACTIONS = [APPLICANT_TYPES.selected, null];

function getColumns(applicantType, showRateColumn, handleRowClick) {
  switch (applicantType) {
    case null:
      return [
        ...COLUMN_DEFAULTS(handleRowClick),
        ...(showRateColumn ? COLUMN_RATE : COLUMN_HOURLY_RATE),
        ...COLUMN_AVAILABILITY(applicantType),
        ...COLUMN_INVITED,
        ...(showRateColumn ? COLUMN_MATCH_RATE : []),
      ];
    default:
      return [
        ...COLUMN_DEFAULTS(handleRowClick),
        ...(showRateColumn ? COLUMN_RATE : COLUMN_HOURLY_RATE),
        ...COLUMN_AVAILABILITY(applicantType),
        ...COLUMN_APPLIED,
        ...(showRateColumn ? COLUMN_MATCH_RATE : []),
      ];
  }
}

const COLUMN_APPLIED = [
  {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnAppliedAt" />,
    width: "10%",
    cell: (row) => formatDate(row?.appliedAt),
  },
  {
    label: <FormattedMessage id="Missions.MissionCandidateList.MatchScore" />,
    width: "7.5%",
    cell: (row) => (
      <Tooltip tooltipId="Global.Text" values={{ text: <ScoreDisplay subScores={row?.subScores} /> }}>
        <Score score={Number(row?.matchScore) * 100}>{convertToPercent(row?.matchScore)}</Score>
      </Tooltip>
    ),
  },
];

const COLUMN_INVITED = [
  {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnInvitedBy" />,
    width: "10%",
    cell: (row) => (
      <Col>
        <span>{row?.invitedBy?.name}</span>
        <span>{formatDate(row?.invitedAt)}</span>
      </Col>
    ),
  },
  {
    label: <FormattedMessage id="Missions.MissionCandidateList.MatchScore" />,
    width: "7.5%",
    cell: (row) => <Score score={Number(row?.matchScore) * 100}>{convertToPercent(row?.matchScore)}</Score>,
  },
];

const COLUMN_MATCH_RATE = [
  {
    label: <FormattedMessage id="Missions.MissionCandidateList.RateScore" />,
    width: "7.5%",
    cell: (row) => <Score score={Number(row?.rateScore) * 100}>{convertToPercent(row?.rateScore)}</Score>,
  },
];

const COLUMN_HOURLY_RATE = [
  {
    label: <FormattedMessage id="Missions.MissionCandidateList.HourlyRate" />,
    width: "20%",
    cell: ({ profile: { sellingPriceEstimate } }) => (
      <ProfileRateRange
        includesCommission={false}
        low={sellingPriceEstimate?.hourly?.low}
        high={sellingPriceEstimate?.hourly?.high}
        currency={sellingPriceEstimate?.currency}
        assignedSellingRate={false}
      />
    ),
  },
];

const COLUMN_RATE = [
  {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnApplicationRate" />,
    width: "20%",
    cell: (row) => (
      <Col>
        {row?.rate?.amount ? (
          <Row gap="0.25rem" noWrap>
            <Icon type={ICON_TYPE.checkDotted} size={ICON_SIZE.medium} color="inherit" />
            <Tooltip tooltipId={"Missions.Applicants.ApplicantList.ApplicantRate"}>
              <FormattedMessage
                id={row?.rate?.amount ? "Profile.ProfileRateAndAvailability.DataRate" : "Global.NotProvided"}
                values={{
                  amount: formatCurrency(row?.rate?.amount, row?.rate?.currency),
                  type: <FormattedMessage id={`Profile.ProfileRateAndAvailability.Frequency${row?.rate?.frequency}`} />,
                }}
              />
            </Tooltip>
          </Row>
        ) : (
          <Row gap="0.25rem" noWrap>
            <Icon type={ICON_TYPE.coin} size={ICON_SIZE.medium} color="inherit" />
            <Tooltip tooltipId={"Missions.Applicants.ApplicantList.ProfileRate"}>
              <FormattedMessage
                id={row?.profile?.rate?.amount ? "Profile.ProfileRateAndAvailability.DataRate" : "Global.NotProvided"}
                values={{
                  amount: formatCurrency(row?.profile?.rate?.amount, row?.profile?.rate?.currency),
                  type: (
                    <FormattedMessage
                      id={`Profile.ProfileRateAndAvailability.Frequency${row?.profile?.rate?.frequency}`}
                    />
                  ),
                }}
              />
            </Tooltip>
          </Row>
        )}
      </Col>
    ),
  },
];

const COLUMN_AVAILABILITY = (applicantType) => [
  {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnAvailability" />,
    width: "10%",
    cell: (row) =>
      applicantType == APPLICANT_TYPES.recommended || row?.invitedAt
        ? COLUMN_PROFILE_AVAILABILITY(row)
        : COLUMN_APPLICATION_AVAILABILITY(row),
  },
];

const COLUMN_DEFAULTS = (handleRowClick) => [
  {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnMember" />,
    width: "25%",
    cell: (row) => (
      <Row noWrap>
        <UserPhoto data={row?.profile} onClick={handleRowClick} width="3rem" height="3rem" />
        <Col>
          <ProfileLink profile={row?.profile} withOverlay />
          <UserLocation data={row?.profile} />
        </Col>
      </Row>
    ),
  },
  {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnRoleMissionOrg" />,
    width: "15%",
    cell: (row) => <RoleLink mission={row?.missionRole?.mission} missionRole={row?.missionRole} withOverlay />,
  },
];

const COLUMN_PROFILE_AVAILABILITY = (row) => (
  <Status enabled={row?.profile?.capacityStatus === CAPACITY_STATUS.available}>
    <UserCapacity data={row?.profile} />
  </Status>
);

const COLUMN_APPLICATION_AVAILABILITY = (row) => {
  return !row.noticePeriod ? (
    <FormattedMessage id="Global.NotProvided" />
  ) : (
    AVAILABILITIES.find((e) => e.value === row.noticePeriod).label
  );
};

ApplicantList.defaultProps = {
  showAdvancedOptions: true,
  showRateColumn: false,
  openModalOnRowClick: false,
};

ApplicantList.propTypes = {
  data: PropTypes.array,
  applicantType: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.object,
  onSelect: PropTypes.func,
  resultsPerPage: PropTypes.number,
  handlePageChange: PropTypes.func,
  handleNextPage: PropTypes.func,
  handlePreviousPage: PropTypes.func,
  hasNextPage: PropTypes.bool,
  hasPreviousPage: PropTypes.bool,
  selectionInitialState: PropTypes.array,
  showAdvancedOptions: PropTypes.bool,
  showRateColumn: PropTypes.bool,
  openModalOnRowClick: PropTypes.bool,
  currentPage: PropTypes.number,
  totalCount: PropTypes.number,
  pageInfo: PropTypes.object,
  paginationType: PropTypes.string,
};

export default ApplicantList;
