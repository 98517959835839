import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useToast } from "hooks";
import Form, { DECORATORS, SIZE, TYPE } from "components/Form";
import { ICON_TYPE, ICON_SIZE, ICON_POSITION } from "components/Icon";
import { ADD_MISSION, UPDATE_MISSION } from "graphql/mutations";
import { MUTATION_TYPE, useMissions } from "hooks";
import { GET_TIME_ZONES, GET_ORGANIZATIONS } from "graphql/queries";
import { LINKS, PAGES } from "constants/index";
import { ModalForm, MODAL_SIZE } from "components/Modal";
import { convertFromPlainText, getPlainText } from "components/Form/Editor";

/**
 * MissionForm
 *
 * @param {Boolean}   show
 * @param {Function}  onClose
 * @param {Object}    data
 * @param {Boolean}   loading
 * @param {Boolean}   isModal
 * @param {Boolean}   isEditing
 */
const MissionForm = ({ show, onClose, data, loading, isModal, isEditing }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { refetchQueries } = useMissions({ skip: true });

  if (isEditing && loading) return null;

  const initialValues = {
    name: data?.name,
    description: data?.description,
    descriptionJson: data?.descriptionJson ?? convertFromPlainText(data?.description),
    companyDescription: data?.companyDescription,
    companyDescriptionJson: data?.companyDescriptionJson ?? convertFromPlainText(data?.companyDescription),
    startDate: data?.startDate,
    timezone: data?.timezone?.value,
    organizationId: data?.organization?.id,
    publish: data?.published,
    isOrganizationConfidential: isEditing ? data?.isOrganizationConfidential : true,
  };

  const formProps = {
    initialValues,
    updateId: isEditing ? data?.uuid : null,
    mutationType: isEditing ? MUTATION_TYPE.update : MUTATION_TYPE.add,
    mutationData: {
      mutationName: {
        add: ADD_MISSION,
        update: UPDATE_MISSION,
      },
      refetchAfterMutate: true,
      refetchQueries,
    },
  };

  const handleComplete = (payload) => {
    if (!isEditing) {
      const missionUuid = payload?.addMission?.mission?.uuid;

      navigate(LINKS.mission_edit(missionUuid));
    }

    if (onClose) {
      onClose();
    }

    toast.success(<FormattedMessage id="Missions.MissionForm.Success" />);
  };

  if (isModal) {
    return (
      <ModalForm
        data={[...FORM_DATA_LEFT_COL, ...FORM_DATA_RIGHT_COL]}
        show={show}
        size={MODAL_SIZE.large}
        onClose={onClose}
        title={<FormattedMessage id="Mission.MissionForm.FormTitle" />}
        description={<FormattedMessage id="Mission.MissionForm.FormDescription" />}
        onCompleted={handleComplete}
        {...formProps}
        page={isEditing ? PAGES.editMissionDetails : PAGES.newMission}
      />
    );
  }

  return <Form data={[...FORM_DATA_LEFT_COL, ...FORM_DATA_RIGHT_COL]} onCompleted={handleComplete} {...formProps} />;
};

const FORM_DATA_LEFT_COL = [
  {
    style: css`
      width: 70%;
      padding-right: 4rem !important;
    `,
    items: [
      {
        label: "Mission name",
        type: TYPE.input,
        size: SIZE.xlarge,
        properties: {
          name: "name",
          type: "text",
          maxLength: 100,
          required: true,
          placeholder: "Name your Project",
        },
      },
      {
        label: "Mission description",
        type: TYPE.editor,
        size: SIZE.xlarge,
        onChange: (name, value, handleOnChange) => {
          handleOnChange("description", getPlainText(value));
          handleOnChange(name, value);
        },
        properties: {
          name: "descriptionJson",
          maxLength: 3000,
          required: true,
          placeholder: "Describe this mission for prospective squad members",
          showFormattingOptions: true,
        },
      },
      {
        label: "Company description",
        type: TYPE.editor,
        size: SIZE.xlarge,
        onChange: (name, value, handleOnChange) => {
          handleOnChange("companyDescription", getPlainText(value));
          handleOnChange(name, value);
        },
        properties: {
          name: "companyDescriptionJson",
          maxLength: 3000,
          required: true,
          placeholder: "Describe the company for prospective squad members",
          showFormattingOptions: true,
        },
      },
    ],
  },
];

const FORM_DATA_RIGHT_COL = [
  {
    style: css`
      width: 30%;
      padding-left: 4rem !important;
    `,
    items: [
      {
        label: "Start date",
        type: TYPE.datePicker,
        size: SIZE.xlarge,
        properties: {
          name: "startDate",
          required: true,
        },
      },
      {
        label: "Primary time zone",
        type: TYPE.select,
        size: SIZE.xlarge,
        properties: {
          name: "timezone",
          queryName: GET_TIME_ZONES,
          queryKeyName: "timezones",
          labelKey: "name",
          required: true,
        },
        decorator: {
          type: DECORATORS.icon,
          props: {
            type: ICON_TYPE.pin,
            size: ICON_SIZE.medium,
            position: ICON_POSITION.overlay,
          },
        },
      },
      {
        label: "Organization",
        type: TYPE.select,
        size: SIZE.xlarge,
        properties: {
          name: "organizationId",
          queryName: GET_ORGANIZATIONS,
          queryKeyName: "organizations",
          labelKey: "name",
          valueKey: "id",
          isSearchable: true,
          querySearchFieldName: "search",
          required: true,
          noOptionsMessage: "No organizations found",
        },
        decorator: {
          type: DECORATORS.icon,
          props: {
            type: ICON_TYPE.organization,
            size: ICON_SIZE.medium,
            position: ICON_POSITION.overlay,
          },
        },
      },
      {
        type: TYPE.checkbox,
        size: SIZE.xlarge,
        properties: {
          type: "checkbox",
          name: "isOrganizationConfidential",
          label: "Keep organization name confidential",
        },
      },
    ],
  },
];

MissionForm.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object,
  loading: PropTypes.bool,
  isModal: PropTypes.bool,
  isEditing: PropTypes.bool,
};

export default MissionForm;
