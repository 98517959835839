import { gql } from "@apollo/client";

const SET_TERMS_SERVICE = gql`
  mutation SetTermsService($input: SetTermsServiceInput!) {
    setTermsService(input: $input) {
      user {
        id
        termsServiceAt
        needsToSignTerms
      }
    }
  }
`;

const SET_PRIVACY_POLICY = gql`
  mutation SetPrivacyPolicy($input: SetPrivacyPolicyInput!) {
    setPrivacyPolicy(input: $input) {
      user {
        id
        privacyPolicyAt
        needsToSignPrivacyPolicy
      }
    }
  }
`;

const SET_PREFERENCES = gql`
  mutation SetUserPreferences($input: SetUserPreferencesInput!) {
    setUserPreferences(input: $input) {
      user {
        id
        preferences
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        deactivated
        role
      }
    }
  }
`;

const ADD_USER = gql`
  mutation AddUser($input: AddUserInput!) {
    addUser(input: $input) {
      user {
        id
        uuid
      }
    }
  }
`;

// FIXME: WE HAVE TWO MUTATIONS DOING THE SAME THING - ADD_USER AND ADD_USER_PROFILE
const ADD_USER_PROFILE = gql`
  mutation addUser($input: AddUserInput!) {
    addUser(input: $input) {
      user {
        id
        firstName
        lastName
        email
        profile {
          firstName
          lastName
          archetype
        }
      }
    }
  }
`;

const RESEND_CONFIRMATION = gql`
  mutation ResendUserConfirmation($input: ResendUserConfirmationInput!) {
    resendUserConfirmation(input: $input) {
      user {
        id
        confirmedAt
        confirmationLink
      }
    }
  }
`;

export {
  SET_TERMS_SERVICE,
  SET_PRIVACY_POLICY,
  SET_PREFERENCES,
  ADD_USER_PROFILE,
  UPDATE_USER,
  ADD_USER,
  RESEND_CONFIRMATION,
};
