import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { SENTIMENTS } from "constants";
import { toUcFirst } from "utils";
import { getEntityName } from "components/Squads/Insights";

/**
 * LabelForOutlierMetric
 *
 * @param {Object}  insight
 * @param {String}  word
 */
const LabelForOutlierMetric = ({ insight, word }) => {
  const {
    sentiment,
    insightType,
    insightMetric,
    observationPeriod,
    observationPeriodComparative,
    valueDelta,
    valueDeltaPercent,
  } = insight;
  const isSentimentPositive = sentiment === SENTIMENTS.positive;

  return (
    <FormattedMessage
      id={`Squads.SquadInsights.Metric.${sentiment === SENTIMENTS.neutral ? `Steady` : `Directional`}`}
      values={{
        value: insight.value,
        entityName: getEntityName(insight),
        insightType: toUcFirst(insightType),
        direction: isSentimentPositive ? "UP" : "DOWN",
        valueDelta: isSentimentPositive ? Math.abs(valueDelta) : valueDelta,
        valueDeltaPercent: isSentimentPositive ? valueDeltaPercent : Math.abs(valueDeltaPercent),
        observationPeriodComparative,
        insightMetric,
        observationPeriod,
        word,
      }}
    />
  );
};

LabelForOutlierMetric.propTypes = {
  insight: PropTypes.object,
  word: PropTypes.string,
};

export default LabelForOutlierMetric;
