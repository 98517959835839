import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { Grid, PageWrapper } from "components/Containers";
import PageTitle from "components/PageTitle";
import { TimeCardFilterMenu, TimeCardList } from "components/TimeCard";
import Card from "components/Card";
import { formatDate } from "utils";
import { TIME_CARD_RESULTS_PER_PAGE } from "constants/index";

/* defaults */
const DATE = formatDate(new Date(), "YYYY-MM-DD");

/**
 * TimeCard Page
 */
const TimeCard = () => {
  const intl = useIntl();
  const [totalCount, setTotalCount] = useState(0);
  const [submittedCount, setSubmittedCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(null);
  const [values, setValues] = useState({
    year: formatDate(DATE, "YYYY"),
    month: formatDate(DATE, "MM"),
    period: DATE,
    teamId: null,
    overAllocatedHours: false,
    withOnboardedOffboardedDates: false,
    status: null,
  });

  const handleChange = (val) => {
    if (val.search !== values.search) {
      setSearch(val.search);
    } else {
      setValues({
        ...val,
        year: formatDate(val.period, "YYYY"),
        month: formatDate(val.period, "MM"),
        period: formatDate(val.period, "YYYY-MM-DD"),
      });
    }
  };

  const handleSubmit = () => {
    setValues({
      ...values,
      search: search,
    });
  };

  return (
    <PageWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.TimeCard.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle
            title={<FormattedMessage id={`TimeCard.H1`} />}
            loading={loading}
            subtitle={
              <FormattedMessage
                id="TimeCard.Title"
                values={{
                  submittedCount: <b>{submittedCount}</b>,
                  members: (
                    <b>
                      <FormattedMessage id="TimeCard.Count" values={{ totalCount }} />
                    </b>
                  ),
                }}
              />
            }
          />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <Card>
            <TimeCardFilterMenu
              initialValues={values}
              onChange={handleChange}
              onSubmit={handleSubmit}
              disabled={loading}
              hideOptionalLabel
            />
          </Card>
        </Grid.col>
        <Grid.col start={1} end={13}>
          <Card>
            <TimeCardList
              filters={values}
              selectedDate={values.period}
              resultsPerPage={TIME_CARD_RESULTS_PER_PAGE}
              setTotalCount={setTotalCount}
              setSubmittedCount={setSubmittedCount}
              onLoading={setLoading}
            />
          </Card>
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default TimeCard;
