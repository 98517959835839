import React from "react";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { ProfileCodeChallengeList, ProfileNav } from "components/Profile";
import { Grid, PageWrapper } from "components/Containers";
import PageTitle from "components/PageTitle";

/**
 * ProfileCodeChallenges
 */
const ProfileCodeChallenges = () => {
  const { id } = useParams();
  const intl = useIntl();

  return (
    <>
      <ProfileNav id={id} />
      <PageWrapper>
        <Helmet>
          <title>{intl.formatMessage({ id: "Meta.Profiles.CodeChallenges.Title" })}</title>
        </Helmet>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle subtitle={<FormattedMessage id="Profile.CodeChallenges.Title" />} />
          </Grid.col>
          <Grid.col start={1} end={13}>
            <ProfileCodeChallengeList profileId={id} />
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};

export default ProfileCodeChallenges;
