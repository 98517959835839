import React from "react";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { CLIENT } from "constants/index";
import { useModalConfirmation, useQueryData, useToast } from "hooks";
import { GET_INTERVIEW_RESPONSES } from "graphql/queries";
import { REMOVE_INTERVIEW_RESPONSE, SET_AS_CURRENT_INTERVIEW_RESPONSE } from "graphql/mutations";

/**
 * useInterviewResponses
 *
 * @param {Object}   variables
 * @param {Boolean}  skip
 */
export default function useInterviewResponses(variables, skip) {
  const { toast } = useToast();
  const { show } = useModalConfirmation();
  const [removeInterview, { loading: removing }] = useMutation(REMOVE_INTERVIEW_RESPONSE);
  const [setAsCurrentInterview, { loading: settingAsCurrent }] = useMutation(SET_AS_CURRENT_INTERVIEW_RESPONSE, {
    refetchQueries: [GET_INTERVIEW_RESPONSES],
  });

  const { data, loading } = useQueryData({
    queryName: GET_INTERVIEW_RESPONSES,
    keyName: "interviewResponses",
    variables,
    skip,
  });

  const handleRestoreInterviewResponse = async (id) => {
    const response = await show(
      <FormattedMessage id="Profile.ProfileInterviews.RestoreInterviewResponse" />,
      <>
        <FormattedMessage id="Profile.ProfileInterviews.RestoreInterviewResponseMessage" />
        <div css={styles.interview_text2}>
          <FormattedMessage id="Profile.ProfileInterviews.RestoreInterviewResponseMessage2" />
        </div>
        <div css={styles.interview_text}>
          <FormattedMessage id="Profile.ProfileInterviews.RestoreInterviewResponseDesc" />
        </div>
      </>,
      {
        primaryButtonMessageId: "Profile.ProfileInterviews.RestoreInterviewResponse.Confirm.Title",
        showSave: false,
        showSaveAsDestructable: true,
      }
    );

    if (response) {
      setAsCurrentInterview({
        variables: {
          input: {
            id,
          },
        },
        onCompleted: () =>
          toast.success(<FormattedMessage id="Profile.ProfileInterviews.RestoreInterviewResponseSuccess" />),
        onError: () => toast.error(<FormattedMessage id="Profile.ProfileInterviews.RestoreInterviewResponseError" />),
      });
    }
  };

  const handleDeleteInterviewResponse = async (id) => {
    const response = await show(
      <FormattedMessage id="Profile.ProfileInterviews.DeleteInterviewResponse" />,
      <>
        <FormattedMessage id="Profile.ProfileInterviews.DeleteInterviewResponseMessage" />
        <div css={styles.interview_text2}>
          <FormattedMessage id="Profile.ProfileInterviews.DeleteInterviewResponseMessage2" />
        </div>
        <div css={styles.interview_text}>
          <FormattedMessage id="Profile.ProfileInterviews.DeleteInterviewResponseDesc" />
        </div>
      </>,
      {
        primaryButtonMessageId: "Profile.ProfileInterviews.DeleteInterviewResponse.Confirm.Title",
        showSave: false,
        showSaveAsDestructable: true,
      }
    );

    if (response) {
      removeInterview({
        variables: {
          input: {
            id,
          },
        },
        onCompleted: () =>
          toast.success(<FormattedMessage id="Profile.ProfileInterviews.DeleteInterviewResponseSuccess" />),
        onError: () => toast.error(<FormattedMessage id="Profile.ProfileInterviews.DeleteInterviewResponseError" />),
        update: (cache) => {
          const query = GET_INTERVIEW_RESPONSES;
          const cacheVariables = { ...variables, after: null, first: null, last: null, orderBy: {} };
          const result = cache.readQuery({ query, variables: cacheVariables });

          if (result) {
            const {
              interviewResponses: { nodes },
            } = result;

            cache.writeQuery({
              query,
              variables: cacheVariables,
              data: { interviewResponses: { nodes: nodes.filter((item) => item.id !== id) } },
            });
          } else {
            CLIENT.refetchQueries({ include: [query] });
          }
        },
      });
    }
  };

  const styles = {
    interview_text: css`
      font-weight: bold;
      margin-top: 2rem;
    `,
    interview_text2: css`
      margin-top: 1rem;
    `,
  };

  return {
    loading,
    data: data?.interviewResponses?.nodes,
    handleDeleteInterviewResponse,
    handleRestoreInterviewResponse,
    settingAsCurrent,
    removing,
  };
}
