import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { SIZE, TYPE } from "components/Form";
import { ModalForm, MODAL_SIZE } from "components/Modal";

/**
 * ManageFilters
 *
 * @param {Boolean}   show
 * @param {Object}    initialValues
 * @param {Function}  onClose
 * @param {Function}  onSubmit
 * @param {array}     allowedFilters
 */
const ManageFilters = ({ allowedFilters, show, initialValues, onClose, onSubmit, ...props }) => (
  <ModalForm
    data={FORM_DATA(allowedFilters)}
    show={show}
    size={MODAL_SIZE.small}
    onClose={onClose}
    title={<FormattedMessage id="Search.ManageFilters.Title" />}
    description={<FormattedMessage id="Search.ManageFilters.Description" />}
    onSubmit={onSubmit}
    initialValues={initialValues}
    {...props}
  />
);

const FORM_DATA = (allowedFilters) => [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        type: TYPE.checkBoxGroup,
        size: SIZE.xlarge,
        properties: {
          name: "filters",
          options: Object.keys(allowedFilters)
            /* Filter out items with a parentKey as they are automatically included with the parent */
            .filter((key) => !allowedFilters[key].parentKey)
            .map((key) => allowedFilters[key])
            .sort((a, b) => a.order - b.order),
          valueKey: "id",
          labelKey: "filterMenuLabel",
          descriptionKey: "description",
        },
      },
    ],
  },
];

ManageFilters.propTypes = {
  show: PropTypes.bool,
  initialValues: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  allowedFilters: PropTypes.object,
};

export default ManageFilters;
