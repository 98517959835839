import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LINKS, OPERATORS } from "constants/index";
import { useAuth, useProfile, useToast } from "hooks";
import { ICON_TYPE } from "components/Icon";
import { FormattedMessage } from "react-intl";

/**
 * useNetworkSearch
 */
export default function useNetworkSearch() {
  const [showAddToListsForm, setShowAddToListsForm] = useState(false);
  const [showInviteMissionForm, setShowInviteMissionForm] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState({});
  const navigate = useNavigate();
  const { partner, isPartner, isAdmin } = useAuth();
  const { toast } = useToast();
  const { resendConfirmation } = useProfile();

  const advancedOptions = (row) => [
    {
      label: <FormattedMessage id="Global.Edit" />,
      icon: ICON_TYPE.edit,
      onClick: () => navigate(LINKS.private_profile(row.slug)),
    },
    ...(isPartner
      ? []
      : [
          {
            label: <FormattedMessage id="Lists.ListFavoriteButton.Add" values={{ icon: null }} />,
            icon: ICON_TYPE.plus,
            onClick: () => {
              setSelectedProfile(row);
              setShowAddToListsForm(true);
            },
          },
        ]),
    {
      label: <FormattedMessage id="ProfileAdminBar.InviteMember.Tooltip" />,
      icon: ICON_TYPE.email,
      onClick: () => {
        setSelectedProfile(row);
        setShowInviteMissionForm(true);
      },
    },
    ...(isAdmin && row.user && !row.user.confirmedAt
      ? [
          row.user.confirmationLink && {
            label: <FormattedMessage id="Network.NetworkList.AdvancedOptions.CopyConfirmationLink" />,
            icon: ICON_TYPE.link,
            onClick: async () => {
              // it copies the given text to the clipboard
              await window.navigator.clipboard.writeText(row.user?.confirmationLink);
              toast.success(<FormattedMessage id="Missions.MissionPublicLink.LinkCopied" />);
            },
          },
          {
            label: <FormattedMessage id="Network.NetworkList.AdvancedOptions.ResendConfirmationLink" />,
            icon: ICON_TYPE.email,
            onClick: () => resendConfirmation(row.user?.id),
          },
        ].filter(Boolean)
      : []),
  ];

  const staticFilters = () => {
    if (isPartner) {
      return [
        {
          key: "profile_id.keyword",
          operator: OPERATORS.or,
          categoricalValues: partner?.profiles.map((profile) => `${profile?.id}`),
        },
      ];
    }
  };

  return {
    advancedOptions,
    selectedProfile,
    showAddToListsForm,
    setShowAddToListsForm,
    showInviteMissionForm,
    setShowInviteMissionForm,
    staticFilters,
  };
}
