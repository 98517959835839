import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { colors, fontSize, margins } from "style";
import { ProfileDisciplines } from "components/Profile";
import { Editor } from "components/Form";
import {
  RolePrimarySkills,
  RoleSecondarySkills,
  RoleLocations,
  RoleCodeChallenges,
  RoleMatchingArcheTypes,
} from "components/Missions/Roles";
import { TextBlock } from "components/Layouts";

/**
 * RoleDescription
 *
 * @param {Boolean}  isAdmin
 * @param {Object}   role
 * @param {String}   marginSize
 * @param {Boolean}  isLoading
 * @param {Boolean}  showArcheTypes
 */
const RoleDescription = ({ isAdmin, role, marginSize, isLoading, showArcheTypes }) => (
  <div css={styles.container}>
    {role?.descriptionJson ? (
      <Editor value={role?.descriptionJson} css={styles.body} readOnly={true} />
    ) : (
      <TextBlock body={role?.description} isLoading={isLoading} />
    )}
    {showArcheTypes && (
      <RoleMatchingArcheTypes
        data={role}
        marginSize={marginSize}
        loading={isLoading}
        title={<FormattedMessage id="Missions.MissionRole.MatchingArcheTypes" />}
      />
    )}
    <ProfileDisciplines
      data={role}
      marginSize={marginSize}
      loading={isLoading}
      title={<FormattedMessage id="Missions.MissionRole.RequiredDisciplines" />}
    />
    <RolePrimarySkills
      data={role}
      marginSize={marginSize}
      loading={isLoading}
      title={<FormattedMessage id="Missions.MissionRole.RequiredSkills" />}
    />
    <RoleSecondarySkills
      data={role}
      marginSize={marginSize}
      loading={isLoading}
      title={<FormattedMessage id="Global.NiceToHaveSkills" />}
    />
    <RoleLocations
      data={role}
      marginSize={marginSize}
      loading={isLoading}
      title={<FormattedMessage id="Missions.MissionRole.Locations" />}
    />
    <RoleCodeChallenges
      data={role}
      marginSize={marginSize}
      loading={isLoading}
      title={<FormattedMessage id="Missions.MissionRole.CodeChallenges" />}
      isAdmin={isAdmin}
    />
  </div>
);

const styles = {
  container: css`
    font-size: ${fontSize.normal};

    ol,
    ul {
      margin: 1rem 1rem;
    }
  `,
  description: (marginSize) => css`
    font-size: ${fontSize.normal};
    line-height: 1.75;
    color: ${colors.purpleRainDark2};
    position: relative;
    margin: -1rem ${marginSize} 4rem ${marginSize}; // -1 offsets paragraph
  `,
  body: css`
    margin: 0;
    padding: 0;
    border: 0;
    box-shadow: none;
  `,
};

RoleDescription.defaultProps = {
  marginSize: margins.normal,
  showArcheTypes: false,
};

RoleDescription.propTypes = {
  role: PropTypes.object,
  marginSize: PropTypes.string,
  isLoading: PropTypes.bool,
  showArcheTypes: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

export default RoleDescription;
