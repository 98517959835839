/* eslint-disable react/display-name */
import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { forwardRef } from "react";
import { useToken } from "react-bootstrap-typeahead";
import { colors, fontSize } from "style";
import styled from "@emotion/styled";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";

const TAG_COLORS = {
  purple: {
    color: colors.purpleRainLight5,
    background: colors.purpleRainBase,
  },
  pink: {
    color: colors.pinkPantherLight1,
    background: colors.pinkPantherBase,
  },
  gray: {
    color: colors.grayAnatomyBase,
    background: colors.grayAnatomyLight5,
  },
  green: {
    color: colors.lightGreen,
    background: colors.green,
  },
};

/**
 * Tag
 */
const StyledTag = styled.div`
  background: ${(props) => props.color || colors.grayAnatomyBase};
  padding: ${(props) => (props.size === TAG_SIZE.small ? `0.2rem 0.8rem` : `0.4rem 0.8rem`)};
  border-radius: 2.4rem;
  font-size: ${(props) => (props.size === TAG_SIZE.small ? fontSize.xxsmall : fontSize.xsmall)};
  font-weight: 500;
  line-height: normal;
  color: ${(props) => props.background || colors.white};
  display: inline-flex;
  align-items: center;
`;

/**
 * Count
 */
const Count = styled.div`
  background: ${colors.purpleRainBase};
  padding: ${(props) => (props.size === TAG_SIZE.small ? `0.2rem 0.8rem` : `0.4rem 0.8rem`)};
  border-radius: 2.4rem;
  font-size: ${(props) => (props.size === TAG_SIZE.small ? fontSize.xxxsmall : fontSize.xxsmall)};
  font-weight: 500;
  line-height: normal;
  color: ${colors.purpleRainLight5};
  display: inline-flex;
  align-items: center;
`;

const TAG_SIZE = {
  small: "small",
  medium: "medium",
  large: "large",
};

/**
 * Editable Tag (for use in TagsInput)
 */
const EditableStyledTag = forwardRef(({ onRemove, disabled, children, ...props }, ref) => {
  return (
    <StyledTag {...props} ref={ref} css={styles.tag_container(disabled)} onClick={!disabled && onRemove}>
      {children}
      {!disabled && (
        <div css={styles.remove_container}>
          <Icon
            type={ICON_TYPE.close}
            size={ICON_SIZE.medium}
            css={css`
              color: #fff;
            `}
          />
        </div>
      )}
    </StyledTag>
  );
});

const EditableTag = ({ disabled, children, ...props }) => (
  <EditableStyledTag {...useToken(props)} disabled={disabled}>
    {children}
  </EditableStyledTag>
);

const styles = {
  tag_container: (disabled) => css`
    background: ${disabled ? colors.grayAnatomyLight2 : colors.purpleRainBase};
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      background: ${disabled ? colors.grayAnatomyLight2 : colors.purpleRainDark1};
    }
  `,
  remove_container: css`
    margin-left: 0.5rem;
    margin-right: -0.25rem;
    cursor: pointer;
    display: flex;
    align-items: center;

    > * {
      top: 0px !important;
    }
  `,
};

EditableStyledTag.propTypes = {
  onRemove: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

EditableTag.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

export { EditableTag, StyledTag, Count, TAG_SIZE, TAG_COLORS };

export default StyledTag;
